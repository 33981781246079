import React, { useCallback } from 'react';
import { compose, isEmpty } from 'ramda';
import '../../config/i18n';
import { useTranslation } from 'react-i18next';
import { Markdown, Sidebar } from '@pure-escapes/webapp-ui-components';

import NotFound from 'pages/NotFound';
import { useLoading } from 'effects';

import connect from './PageContent.state';
import {
  StyledPageContent,
  Columns,
  ColumnLeft,
  ColumnRight,
  PageContentData,
  PageContainer,
  PageHero,
} from './PageContent.styles';

import { useSelector } from 'react-redux';
import * as InvHeaderSelectors from 'store/modules/inventoryHeader/selectors';
import { Link } from 'ui/Link';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { noop } from 'utils';
import { Loader } from 'components/Loader/Loader';

const renderNotFound = () => <NotFound />;

export const PageContent = ({ pageId, data, page, links, title, getPage = noop, className, hero }) => {
  const { t } = useTranslation();

  const loadPage = useCallback(() => getPage(pageId), [getPage, pageId]);
  const loading = useLoading(loadPage, [pageId], true);

  const isLoading = !data && loading;

  const invHeaderUrl = useSelector(InvHeaderSelectors.getLastSetURLSelector);

  if (!data && !loading) {
    return renderNotFound();
  }

  return (
    <Loader isLoading={isLoading}>
      <StyledPageContent className={className}>
        {hero && <PageHero {...hero} />}
        <PageContainer>
          <Link
            type="internal"
            to={invHeaderUrl}
            styledAsButton={true}
            className="mb-20px inline-block mt-20px md:-mt-20px"
            internalClassName="rounded-none uppercase space-x-2 font-hurmegeometric-sans"
          >
            <span>{`< Go Back`}</span>
          </Link>
          <Columns>
            <ColumnLeft>
              <Sidebar title={title}>{!isEmpty(links) && <Markdown>{links}</Markdown>}</Sidebar>
            </ColumnLeft>
            <ColumnRight>
              <PageContentData>
                <Markdown className={`page-data ${pageId}`}>{data}</Markdown>
              </PageContentData>
            </ColumnRight>
          </Columns>
        </PageContainer>
      </StyledPageContent>
    </Loader>
  );
};

export default compose(connect)(PageContent);
