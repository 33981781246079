import { IRateBreakDomain } from './types';
import { createSelector } from 'reselect';

import { getBootstrapHotelsSelector, getBootstrapCountriesSelector } from 'store/modules/bootstrap/selectors';

export const rateBreakDomainSelector = (state: any): IRateBreakDomain => state.rateBreak as IRateBreakDomain;

export const rateBreakNameSelector = createSelector(rateBreakDomainSelector, domain => domain.rateBreakName);
export const rateBreakPercentageSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.rateBreakPercentage
);
export const selectedHotelUuidSelector = createSelector(rateBreakDomainSelector, domain => domain.selectedHotelUuid);
export const selectedRateApplicationSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.selectedRateApplication
);

export const availableHotelAccommodationsSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.availableHotelAccommodations
);

export const stayBetweenDateRangesSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.stayBetweenDateRanges
);

export const isPreDiscountSelector = createSelector(rateBreakDomainSelector, domain => domain.isPreDiscount);

export const rateAppliesToSelector = createSelector(rateBreakDomainSelector, domain => domain.rateAppliesTo);

export const discountExtraPersonSupplementSelector = createSelector(rateBreakDomainSelector, domain => domain.discountExtraPersonSupplement);

export const excludeServiceAndValueTaxesSelector = createSelector(rateBreakDomainSelector, domain => domain.excludeServiceAndValueTaxes);

export const selectedHotelAccommodationUuidsSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.selectedHotelAccommodationUuids
);

export const selectedTravelAgentCountryCodesSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.selectedTravelAgentCountryCodes
);

export const stayBetweenDateRangesFormattedSelector = createSelector(rateBreakDomainSelector, domain => {
  return domain.stayBetweenDateRanges.map(sb => {
    return {
      startDate: sb.selectedDates[0].split('T')[0],
      endDate: sb.selectedDates[sb.selectedDates.length - 1].split('T')[0],
    };
  });
});

export const rateBreakUuidSelector = createSelector(rateBreakDomainSelector, domain => domain.uuid);

export const rateBreakCreatedAtSelector = createSelector(rateBreakDomainSelector, domain => domain.createdAt);
export const rateBreakUpdatedAtSelector = createSelector(rateBreakDomainSelector, domain => domain.updatedAt);

export const networkRequestsSelector = createSelector(rateBreakDomainSelector, domain => domain.networkRequests);

export const rateBreakHasChangesSelector = createSelector(rateBreakDomainSelector, domain => domain.rateBreakHasChanges);

export const selectedCountriesCountPerRegionSelector = createSelector(
  getBootstrapCountriesSelector,
  selectedTravelAgentCountryCodesSelector,
  (bootstrapCountries, selectedCountryCodes) => {
    const regionMap = {};

    bootstrapCountries.forEach(country => {
      if (regionMap[country.region] == null) {
        regionMap[country.region] = 0;
      }

      if (selectedCountryCodes.includes(country.code)) {
        regionMap[country.region] = regionMap[country.region] + 1;
      }
    });

    return regionMap;
  }
);

// TODO for use in edit mode
export const selectedHotelNameSelector = createSelector(
  selectedHotelUuidSelector,
  getBootstrapHotelsSelector,
  (selectedHotelUuid, bootstrapHotels) => {
    const hotel = bootstrapHotels.find(bh => bh.uuid === selectedHotelUuid);

    return hotel?.name;
  }
);
export const selectedHotelCountryCodeSelector = createSelector(
  selectedHotelUuidSelector,
  getBootstrapHotelsSelector,
  (selectedHotelUuid, bootstrapHotels) => {
    const hotel = bootstrapHotels.find(bh => bh.uuid === selectedHotelUuid);

    return hotel?.countryCode;
  }
);

export const rateBreakListSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.rateBreakListingPage.rateBreakList
);

export const rateBreakUuidsForDeletionSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.rateBreakListingPage.uuidsForDeletion
);

export const rateBreakListingPaginationCurrentPageSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.rateBreakListingPage.paginationCurrentPage
);

export const rateBreakListingPaginationItemsPerPageSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.rateBreakListingPage.paginationItemsPerPage
);

export const rateBreakListingFilterHotelUuidsSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.rateBreakListingPage.filterHotelUuids
);

export const rateBreakListingTotalResultsCountSelector = createSelector(
  rateBreakDomainSelector,
  domain => domain.rateBreakListingPage.totalResultsCount
);
