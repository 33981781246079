import React, { Dispatch, SetStateAction } from 'react';
import classnames from 'classnames';
import FluidButton from 'ui/FluidButton';
import * as UiActions from 'store/modules/ui/actions';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/actions';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import { useCurrentWidth } from 'effects';
import { useDispatch, useSelector } from 'react-redux';
import { RIGHT_COLUMN_BREAKPOINT } from './const';
import { BookingBuilder, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from 'store/modules/agents';
import { useInstantBooking } from './hooks/useInstantBooking';
import { LodgingSummary } from 'interfaces';
import { ErrorMessageLine } from './ErrorMessageLine';
import * as ActingOnBehalfOfSelectors from 'store/modules/actingOnBehalfOf/selectors';
import { IBasketBuildL4 } from 'services/BackendApi/types/Basket';

interface IInstantBookButtonProps {
  bookingBuild: BookingBuilder | null;
  lodgingSummaries: LodgingSummary[];
  className?: string;
  setRequestInProgress: Dispatch<SetStateAction<boolean>>;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  buttonsDisabled: boolean;
  cannotGetDepositAccountBalance: boolean;
  taMarginAmountOverride?: string;
  basketBuild: IBasketBuildL4;
  clientCountryCode: string | null;
}

export const InstantBookButton: React.FC<IInstantBookButtonProps> = React.memo(
  ({
    bookingBuild,
    lodgingSummaries,
    cannotGetDepositAccountBalance,
    isLoading,
    buttonsDisabled,
    setRequestInProgress,
    setRedirectLocation,
    setShouldShowLeaveAlert,
    taMarginAmountOverride,
    basketBuild,
    clientCountryCode,
  }) => {
    const { currentWidth } = useCurrentWidth();
    const dispatch = useDispatch();

    const selectedTa = useSelector(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const bookingDomain = useSelector(BookingBuilderSelectors.bookingSelector);
    // const selectedCompanyMembership = useSelector(AgentsSelectors.selectedCompanyMembershipSelector);

    const selectedCompanyMembership = {
      uuid: basketBuild.companyMembershipUuid,
    };

    const bbv1ClearBookingErrors = () => dispatch(BookingBuilderActions.bbv1ClearBookingErrorsAction());
    const bbv1AddBookingError = (error: any) => dispatch(BookingBuilderActions.bbv1AddBookingErrorAction(error));
    const enqueueNotification = (notification: any) => dispatch(UiActions.enqueueNotification(notification));

    const actingOnBehalfOfUser = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfUserSelector);
    const instantBookErrorMessage = (
      <span>
        The Hotel did not receive this booking request. <br />
        Please email them offline to confirm and Save this booking
      </span>
    );

    const {
      handleBookNow,
      showInstantBookButton,
      areAllBasketLodgingInstantBook,
      isAnyBasketLodgingInstantBook,
      isAllRoomsFreeSale,
      isAnyRoomsFreeSale,
    } = useInstantBooking({
      bookingBuild,
      setRequestInProgress,
      backendApi,
      enqueueNotification,
      selectedCompanyMembership,
      bookingDomain,
      bbv1AddBookingError,
      bbv1ClearBookingErrors,
      clientCountryCode,
      instantBookErrorMessage,
      lodgingSummaries,
      setShouldShowLeaveAlert,
      setRedirectLocation,
      taMarginAmountOverride,
      actingOnBehalfOfUser,
      selectedTa,
      basketBuildUuid: basketBuild.uuid,
    });

    const isButtonDisabled =
      !areAllBasketLodgingInstantBook ||
      !isAllRoomsFreeSale ||
      buttonsDisabled ||
      cannotGetDepositAccountBalance ||
      isLoading;

    if (!showInstantBookButton || !isAnyBasketLodgingInstantBook) {
      return null;
    }

    return (
      <div className="instantbook-button-container">
        {isAnyRoomsFreeSale && !areAllBasketLodgingInstantBook && (
          <ErrorMessageLine>
            Some rates added in the basket are not `Instant Book`, so you can`t `Book Now`
          </ErrorMessageLine>
        )}
        <div className="instantbook-button flex flex-col gap-[8px] mt-[14px]">
          <div
            className={classnames('instantbook-button-primary flex gap-[8px]', {
              'flex-col': currentWidth < RIGHT_COLUMN_BREAKPOINT,
            })}
          >
            {showInstantBookButton && isAnyBasketLodgingInstantBook ? (
              <FluidButton
                type="primary"
                className={classnames(
                  'flex-1 w-full instantbook-button font-semibold font-hurmegeometric-sans min-h-[55px] rounded-none uppercase',
                  { 'hover:bg-green-prime hover:border-none': !isButtonDisabled }
                )}
                onClick={handleBookNow}
                disabled={isButtonDisabled}
              >
                Book now
              </FluidButton>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
);
