import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClockIcon from 'ui/Icons/clock.component.svg';
import { useRouteMatch } from 'react-router';
import { formatDateDisplay } from 'utils';
import * as InternalUserActions from 'store/modules/internalUser/actions';
import * as InternalUserSelectors from 'store/modules/internalUser/selectors';

import { BackLink } from 'ui/BackLink';
import { InternalUserForm } from '../InternalUserForm';
import { renderFullName } from 'utils/guestInformation';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus, EUserType } from 'services/BackendApi';
import { theme } from '../../../../tailwind.config';

interface IInternalUserEditPageParams {
  internalUserUuid: string;
}

const userTypeMapping = {
  [EUserType.TA]: 'Travel Agent',
  [EUserType.SR]: 'Sales Representative',
  [EUserType.FINANCE]: 'Finance',
  [EUserType.RL]: 'Rate Loader',
  [EUserType.ADMIN]: 'Admin',
};

export const Edit: React.FC = React.memo(() => {
  const match = useRouteMatch<IInternalUserEditPageParams>();
  const internalUserUuid = match.params.internalUserUuid;
  const dispatch = useDispatch();

  const internalUser = useSelector(InternalUserSelectors.internalUserSelector);
  const travelAgentGetRequest = useSelector(InternalUserSelectors.internalUserGetRequestSelector);
  const isLoading = travelAgentGetRequest === ENetworkRequestStatus.PENDING;
  const isError = travelAgentGetRequest === ENetworkRequestStatus.ERROR;

  useLayoutEffect(() => {
    dispatch(InternalUserActions.getInternalUserRequestAction(internalUserUuid));
    return () => {
      dispatch(InternalUserActions.resetInternalUserAction());
    };
  }, [internalUserUuid, dispatch]);

  return (
    <div className="travel-agent-edit w-1280px mx-auto">
      <BackLink type="internal" href="/users">
        Back to internal users
      </BackLink>

      <div className="flex justify-between items-center">
        <h1 className="title font-noe-display font-normal text-black my-0">
          {internalUser && !isLoading && (
            <span className="text-4xl leading-46px">
              {renderFullName(internalUser.title, internalUser.firstName, internalUser.lastName)}
              <span className="text-26px leading-33px "> ({userTypeMapping[internalUser.type!]})</span>
            </span>
          )}
          {!internalUser && !isLoading && <span className="text-4xl leading-46px">Edit User</span>}
        </h1>
        {!!internalUser && (
          <div className="flex items-center">
            <ClockIcon className="w-14px" fill={theme.colors['gray-80']} />
            <span className="ml-5px font-pt-sans text-15px leading-19px text-gray-100">
              Created {formatDateDisplay(internalUser.createdAt)}
            </span>
          </div>
        )}
      </div>

      {isError && <ErrorBar />}
      {isLoading && <LoadingBar />}

      {travelAgentGetRequest === ENetworkRequestStatus.SUCCESS && !!internalUser && (
        <InternalUserForm internalUser={internalUser} />
      )}
    </div>
  );
});
