import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const PictureIcon: React.FC<IIconProps> = React.memo(({ width = 18, height = 18, fill = theme.colors['brown-100'] }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.25 2.25C15.075 2.25 15.75 2.925 15.75 3.75V14.25C15.75 15.075 15.075 15.75 14.25 15.75H3.75C2.925 15.75 2.25 15.075 2.25 14.25V3.75C2.25 2.925 2.925 2.25 3.75 2.25H14.25ZM8.25 12.3825L6.375 10.125L3.75 13.5H14.25L10.875 9L8.25 12.3825Z"
      fill={fill}
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
  
));

export default PictureIcon;