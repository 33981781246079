import React from 'react';
import { Price } from 'ui/Price';
import { useCurrentWidth } from 'effects';
import { IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { getCurrencySymbol, formatPrice } from 'utils';

interface IBookingRightTotalCostProps {
  basketBuild: IBasketBuildL4;
  commissionAmount?: number;
  className?: string;
}

export const BasketRightTotalCost: React.FC<IBookingRightTotalCostProps> = React.memo(({ basketBuild, commissionAmount,className }) => {
  const priceClassName = 'font-hurmegeometric-sans text-19px leading-20px font-semibold text-right mt-5px m-0';
  const currencySymbol = getCurrencySymbol(basketBuild.latestBookingBuilderResponse!.currency);

  return (
    <React.Fragment>
      <div className="booking-right-total-cost bg-green-25 flex justify-between items-center min-h-[15px] p-[15px] pb-[5px]">
        <div className="flex flex-row items-center justify-end mt-[5px]">
          <p className="total-cost ml-1 font-hurmegeometric-sans text-[19px] leading-[23px] text-flint uppercase font-semibold m-0">
            Cost To You
          </p>
        </div>
        <Price
          total={basketBuild.latestBookingBuilderResponse!.totals.total}
          totalBeforeDiscount={basketBuild.latestBookingBuilderResponse!.totals.totalBeforeDiscount}
          currencyCode={basketBuild.latestBookingBuilderResponse!.currency}
          isOnRequest={basketBuild.latestBookingBuilderResponse!.totals.oneOrMoreItemsOnRequest}
          totalClassName={`${priceClassName} text-black`}
          totalBeforeDiscountClassName={`font-hurmegeometric-sans text-15px leading-18px text-right mt-5px m-0 text-gray-80 line-through`}
          totalAfterDiscountClassName={`${priceClassName} text-red-15`}
          isOnRequestClassName={`${priceClassName} text-black`}
        />
      </div>
      <div className="booking-right-total-cost bg-green-25 flex justify-between items-center min-h-[15px] p-[15px] pt-[0px]">
        <div className="flex flex-row items-center justify-end mt-[0px]">
          <p className="total-cost ml-1 font-hurmegeometric-sans text-[14px] leading-[23px] text-flint uppercase normal m-0">
            Commission
          </p>
        </div>
        <p className={`font-hurmegeometric-sans } text-black text-[14px] font-normal`}>
          {currencySymbol}&nbsp;{formatPrice(parseFloat(String(commissionAmount) || '0'))}
        </p>
      </div>
    </React.Fragment>
  );
});
