import React from 'react';

import i18n from 'config/i18n';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { Loader } from 'components/Loader/Loader';

export const AdminRedirect = () => {
  window.location.pathname === '/' && window && window.location.replace('/hotel-admin');
  return <Loader text={i18n.t('messages.redirecting')} />;
};

export default AdminRedirect;
