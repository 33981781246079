import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';
import { IAccommodationProduct, IHotel, IMealPlanProduct, ISeason, ITransferProduct } from 'services/BackendApi';
import { Room } from '../child-product-forms/Room';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { MealPlan } from '../child-product-forms/MealPlan';
import { Transfer } from '../child-product-forms/Transfer';
import { validateTransfer } from 'containers/HotelAdmin/validation';
import { enqueueNotification } from 'store/modules/ui';
import { useDispatch } from 'react-redux';

export const CreateTransferModal = ({
  onConfirm,
  onClose,
  hotel,
}: {
  onConfirm: (data: any) => void;
  onClose: () => void;
  hotel: IHotel;
}) => {
  const dispatch = useDispatch();
  const [draftTransfer, setDraftTransfer] = React.useState<Partial<ITransferProduct>>({
    meta: {},
    options: {
      isOneWay: false,
      capacity: 0,
    },
  });

  console.log('draftTransfer', draftTransfer);

  return (
    <GeneralModal
      onClose={() => onClose()}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-scroll font-pt-sans"
      shouldCloseByClickingOutside={false}
    >
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">Create new Transfer</h1>
      <Transfer
        transfer={draftTransfer}
        onUpdate={(newData: Partial<ITransferProduct>) => {
          setDraftTransfer({
            ...draftTransfer,
            ...newData,
          });
        }}
        onCta={() => {
          const validation = validateTransfer(draftTransfer);
          if (validation.isValid === false) {
            dispatch(
              enqueueNotification({
                message: `Transfer is not valid: ${validation.message}`,
                options: { variant: 'warning' },
              })
            );
            return;
          }
          onConfirm(draftTransfer);
        }}
        ctaLabel="Create Transfer"
      />
    </GeneralModal>
  );
};
