import React, { useRef, useState } from 'react';
import { map } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

import {
  MainSlider,
  NavSlider,
} from '../Hotel/Hotel.styles';

const renderImage = ({ displayName, url }) => <img key={url} src={url} alt={displayName} />;

export interface IHotelPhotosGalleryProps {
  photos: { displayName: string; url: string }[];
}

export const HotelPhotosGallery = ({
  photos,
}: IHotelPhotosGalleryProps) => {
  const sliderMain = useRef(null);
  const sliderNav = useRef(null);

  const renderedPhotos = map(renderImage, photos);

  if (isNilOrEmpty(photos)) {
    return null;
  }

  return (
    <div className="hotel-gallery">
      <MainSlider asNavFor={sliderNav} centerMode={false} fade={true} ref={sliderMain} slidesToShow={1}>
        {renderedPhotos}
      </MainSlider>
      <NavSlider
        asNavFor={sliderMain}
        centerMode={false}
        focusOnSelect={true}
        infinite={false}
        ref={sliderNav}
        slidesToShow={6}
        arrows={true}
      >
        {renderedPhotos}
      </NavSlider>
    </div>
  );
};
