import React, { useCallback } from "react";
import { ENetworkRequestStatus } from "services/BackendApi";
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { useDispatch } from "react-redux";
import { IStaticRate } from "ui/AddAccommodationModal/MealPlanDropdown/types";
import { MealPlanDropdown } from "ui/AddAccommodationModal/MealPlanDropdown/MealPlanDropdown";
import { Multiselect } from "ui/Multiselect";
import { getCurrencyBySymbol } from "utils";

interface IMealPlanAndOccasionInputsProps {
  selectedMealPlan: IStaticRate | undefined;
  mealPlanOptions: IStaticRate[];
  occasions: string[];
  bookingBuildRequest: ENetworkRequestStatus | undefined;
  bookingCurrency: string;
  disabled?: boolean;
}

const occasionsMultiselectOptions = [
  { value: 'honeymoon', label: 'Honeymoon' },
  { value: 'birthday', label: 'Birthday' },
  { value: 'anniversary', label: 'Anniversary' },
  { value: 'wedding', label: 'Wedding' },
];

export const MealPlanAndOccasionInputs: React.FC<IMealPlanAndOccasionInputsProps> = ({ disabled, bookingBuildRequest, bookingCurrency, mealPlanOptions, occasions, selectedMealPlan }) => {
  const dispatch = useDispatch();

  const handleMealPlanSelect = useCallback((mealPlan: IStaticRate) => {
    dispatch(BreakdownActions.editAccommodationSetSelectedMealPlanAction(mealPlan));
    dispatch(BreakdownActions.editAccommodationBookingBuildRequestAction());
  }, [dispatch]);

  const handleOccasionsSelect = useCallback(selectedValues => {
    dispatch(
      BreakdownActions.editAccommodationSetSelectedOccasionsAction(selectedValues)
    );
    if (selectedValues.length > 0 || occasions.length > 0) {
      dispatch(BreakdownActions.editAccommodationBookingBuildRequestAction());
    }
  }, [dispatch, occasions]);
  
  return (
    <div className="meal-plan-and-occasion-inputs flex gap-5">
      <label className="text-xs block mb-6 text-gray-500 w-full">
        <span className="block mb-2 font-bold">Meal Plan *</span>
        {bookingBuildRequest === ENetworkRequestStatus.PENDING ? (
          <div className="min-h-[35px] mt-[5px]">Loading...</div>
        ) : (
          <MealPlanDropdown
            value={selectedMealPlan}
            options={mealPlanOptions}
            currency={getCurrencyBySymbol(bookingCurrency || '').code}
            onChoose={handleMealPlanSelect}
            maxVisibleItems={5}
            className="mt-[5px]"
            disabled={disabled}
          />
        )}

      </label>
      <label className="text-xs block mb-6 text-gray-500 w-full">
        <span className="block mb-2 font-bold">Occasion</span>
        <Multiselect
          className="mt-5px bg-ivory w-full"
          onUpdate={handleOccasionsSelect}
          itemsClassname="bg-ivory"
          itemCtaClassName="hover:bg-gray-10"
          options={occasionsMultiselectOptions}
          selectedValues={occasions}
          placeholderText="None"
          placeholderClasses="text-black"
          disabled={disabled}
        />
      </label>
    </div>
  )
};
