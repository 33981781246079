import React, { useCallback } from 'react';
import { compose } from 'ramda';
import qs from 'qs';
import { Redirect, useHistory } from 'react-router-dom';

import LatestOffers from 'containers/LatestOffers';
import SearchBar from 'containers/SearchBar';
import { useFetchData } from 'effects';
import { useSelector } from 'react-redux';
import { isFinanceUser } from 'store/modules/auth';

import connect from './Landing.state';
import { noop } from 'utils';
import { LandingHero, LandingCaption } from './Landing.styles';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { Loader } from 'components/Loader/Loader';

export const Landing = ({ fetchLatestOffers = noop, offersStatus, offers = [] }) => {
  const { dynamicParameters } = useDynamicParameters();
  const loaded = useFetchData(offersStatus, fetchLatestOffers, [{ limit: 3 }]);

  const isFinance = useSelector(isFinanceUser);

  const history = useHistory();
  const handleSearchButtonClick = useCallback(
    searchQuery => {
      history.push(`/search/beta/?${qs.stringify(searchQuery)}`);
    },
    [history]
  );

  // Finance redirect
  // OWA-3246
  // if a finance user tries to get to the landing page, send them to the bookings page
  if (isFinance && window) {
    return <Redirect to="/bookings" />;
  }

  // can't remove use of `LandingHero` due to legacy idiocy
  // so having to hardcode styles instead of classes
  return (
    <Loader isLoading={!loaded}>
      <div className="w-full relative">
        <LandingHero mask media={{ image: dynamicParameters.TA_LANDING_BACKGROUND_IMAGE }}>
          <h1
            style={{
              margin: 'auto',
              textTransform: 'uppercase',
              fontSize: '64px',
              fontWeight: '400',
              marginBottom: '80px',
              maxWidth: '900px',
              fontFamily: "'NoeDisplay', serif",
            }}
            className="block p-0"
          >
            {dynamicParameters.TA_LANDING_WELCOME_TEXT}
          </h1>
          <div className="container mx-auto max-w-1280px">
            <div
              className="search-bar-container p-5 max-w-1190px"
              style={{ backgroundColor: 'rgba(248,248,248,0.85)' }}
            >
              <SearchBar onSearch={handleSearchButtonClick} memberships={[]} />
            </div>
          </div>
        </LandingHero>
        <LandingCaption>
          <div className="caption-wrapper">
            {dynamicParameters.TA_LANDING_DESCRIPTION_HEADER && (
              <span className="block mb-20px uppercase">{dynamicParameters.TA_LANDING_DESCRIPTION_HEADER}</span>
            )}
            {dynamicParameters.TA_LANDING_DESCRIPTION_BOLD_HEADER && (
              <span className="block font-bold uppcase">{dynamicParameters.TA_LANDING_DESCRIPTION_BOLD_HEADER}</span>
            )}
            {dynamicParameters.TA_LANDING_DESCRIPTION_BODY && (
              <span className="block">
                {dynamicParameters.TA_LANDING_DESCRIPTION_BODY.replace(/\r/g, '')
                  .split(/\n/)
                  .map((item, key) => (
                    <>
                      {key === 0 ? null : <br />}
                      {item}
                    </>
                  ))}
              </span>
            )}
          </div>
        </LandingCaption>
        <LatestOffers offers={offers} />
      </div>
    </Loader>
  );
};

export default compose(connect)(Landing);
