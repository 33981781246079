import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const LinkIcon: React.FC<IIconProps> = React.memo( ({ fill = theme.colors['brown-100'] }) => (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M12.0834 0.75L9.08337 0.75V2.25H12.0834C13.3209 2.25 14.3334 3.2625 14.3334 4.5C14.3334 5.7375 13.3209 6.75 12.0834 6.75H9.08337V8.25H12.0834C14.1534 8.25 15.8334 6.57 15.8334 4.5C15.8334 2.43 14.1534 0.75 12.0834 0.75Z" 
      fill={fill}
    />
    <path 
      d="M7.58337 6.75H4.58337C3.34587 6.75 2.33337 5.7375 2.33337 4.5C2.33337 3.2625 3.34587 2.25 4.58337 2.25H7.58337V0.75L4.58337 0.75C2.51337 0.75 0.833374 2.43 0.833374 4.5C0.833374 6.57 2.51337 8.25 4.58337 8.25H7.58337V6.75Z" 
      fill={fill}/>
    <path 
      d="M5.33337 3.75L11.3334 3.75V5.25L5.33337 5.25V3.75Z" 
      fill={fill}
    />
  </svg>
));

export default LinkIcon;
