import React from 'react';
import { prop, omit, compose } from 'ramda';

import { StyledLink, Link as PlainLink } from './Link.styles';

const omitProps = omit(['hard', 'onLinkClick']);

// @TODO rewrite using Tailwind
export const Link = ({ children, button, inverse = false, spaced = false, bold = false, ...props }) =>
  prop('to', props) ? (
    // @ts-ignore-line
    <StyledLink data-inverse={inverse} data-button={button} data-spaced={spaced} data-bold={bold} {...omitProps(props)}>
      {children}
    </StyledLink>
  ) : (
    <PlainLink data-inverse={inverse} data-button={button} data-spaced={spaced} data-bold={bold} {...omitProps(props)}>
      {children}
    </PlainLink>
  );
