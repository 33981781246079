import React from 'react';

import { Navigation, BackWrapper, Button } from './BackButton.styles';

interface IBackButtonProps {
  to?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

// @TODO: rewrite using Tailwind
export const BackButton: React.FC<IBackButtonProps> = ({ to = '', children, ...props }) => {
  return (
    <Navigation to={to} {...props}>
      <BackWrapper>
        <Button />
      </BackWrapper>
      {children}
    </Navigation>
  );
};

