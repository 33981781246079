import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const linkStyles = props => css`
  cursor: pointer;
  color: ${props.theme.palette.link.default};

  &:active {
    color: ${props.theme.palette.active};
  }

  padding: ${props.theme.spacing.gutter}px 0;

  ${props.theme.breakpoints.tablet`
    ${({ ['data-spaced']: spaced }) =>
      spaced &&
      css`
        padding: ${props.theme.spacing.gutter / 2}px ${props.theme.spacing.gutter}px;
        margin: ${props.theme.spacing.gutter / 2}px ${props.theme.spacing.gutter}px;
      `}

    ${({ ['data-inverse']: inverse }) =>
      inverse &&
      css`
        color: ${props.theme.colors.white};
        background: ${props.theme.palette.primary};
        border-radius: ${props.theme.sizes.borderRadius}px;
      `}

      ${({ ['data-bold']: bold, ['data-active']: active }) =>
        (bold || active) &&
        css`
          font-weight: ${props.theme.fonts.bold};
        `}
    `}

  ${({ ['data-button']: button, ...props }) =>
    button &&
    css`
      width: 100%;
      font-size: ${props.theme.fonts.sizes.big}px;
      text-transform: uppercase;
      font-weight: ${props.theme.fonts.bold};
      font-family: ${props.theme.fonts.defaultFont};
      color: ${props.theme.colors.white} !important;
      background: ${props.theme.palette.primary};
      outline: none;
      border: 0;
      padding: ${props.theme.spacing.gutter}px;
      cursor: pointer;
      transition: ${props.theme.animations.defaultTransition};
      display: block;
      text-align: center;
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &:hover {
        background: ${props.theme.palette.secondary};
      }

      ${({ ['data-secondary']: secondary }) =>
        secondary &&
        css`
          background: ${props.theme.palette.light};
        `}
    `}
`;
export const Link = styled.a`
  ${linkStyles}
`;

export const StyledLink = styled(RouterLink)`
  ${linkStyles};
`;
