import React from 'react';
import { ModalV1 } from 'ui/ModalV1';
import classnames from 'classnames';
import { StandardModal } from 'pureUi/Modal';
import { CloseBoldIcon } from 'ui/Icons/components/CloseBold.component';
import { theme } from '../../../tailwind.config';

export enum EVideoPlatform {
  VIMEO = 'VIMEO',
};

interface IVideoModalProps {
  type: EVideoPlatform;
  videoId: string;
  title: string;
  className?: string;
  onClose: () => void;
}

export const VideoModal: React.FC<IVideoModalProps> = ({
  type, videoId, title, className, onClose
}) => {
  const videoPlatformMapping = {
    [EVideoPlatform.VIMEO]: 'https://player.vimeo.com/video/',
  };
  const videoUrl = videoPlatformMapping[type];

  if (!videoUrl) {
    return (
      <StandardModal
        frameClassName={classnames(className)}
        className="transparent"
        onClose={onClose}
        focusTrapActive={false}
        showCloseButton={false}
        removePadding
      >
        <div className="flex flex-col">
          <div className="modal-heading-container flex items-center justify-between gap-[20px] px-[20px] h-[67px]">
            <h2 className="modal-heading m-0 font-noe-display text-21px leading-[27px] text-black">
              {title}
            </h2>
            <CloseBoldIcon onClick={onClose} className="cursor-pointer min-w-[12px] min-h-[12px]" fill={theme.colors['brown-100']} />
          </div>
          
          <div className="video flex items-center justify-center w-[960px] h-[540px] bg-dark-black">
            <div className="text-container flex flex-col">
              <p className="font-hurmegeometric-sans text-[36px] leading-[41px] text-white-true font-bold m-0 text-center">Sorry</p>
              <p className="font-hurmegeometric-sans text-[24px] leading-[27px] text-white-true m-0 mt-[10px] text-center">There was a problem loading this video</p>
            </div>
          </div>
        </div>
      </StandardModal>
    );
  }

  return (
    <StandardModal
      frameClassName={classnames(className)}
      className="transparent"
      onClose={onClose}
      focusTrapActive={false}
      showCloseButton={false}
      removePadding
    >
      <div className="flex flex-col">
        <div className="modal-heading-container flex items-center justify-between gap-[20px] px-[20px] h-[67px]">
          <h2 className="modal-heading m-0 font-noe-display text-21px leading-[27px] text-black">
            {title}
          </h2>
          <CloseBoldIcon onClick={onClose} className="cursor-pointer min-w-[12px] min-h-[12px]" fill={theme.colors['brown-100']} />
        </div>
        
        <div className="video flex">
          <iframe src={`${videoUrl}${videoId}`} width="960" height="540" allow="autoplay; fullscreen;"></iframe>
        </div>
      </div>
    </StandardModal>
  )
}