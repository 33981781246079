import { call, takeLatest, select, delay, put } from 'redux-saga/effects';
import { makeBackendApi } from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';

import * as Actions from './actions';
import * as Selectors from './selectors';
import { IGetRateBreakResponse, IPostRateBreakRequest, IRateBreakListItem } from './types';
import { selectedTaSelector } from '../agents';

export function* getHotelAccommodationsSaga(action: any) {
  try {
    const hotelUuid = yield select(Selectors.selectedHotelUuidSelector);

    if (hotelUuid === null) {
      yield put(Actions.resetSelectedAccommodationUuidsAction());
      yield put(Actions.clearAvailableAccommodationsAction());
      return;
    }

    yield put(Actions.getHotelAccommodationsRequestAction());

    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const result = yield call(backendApi.getAccommodationProductsForHotel, hotelUuid);

    yield put(Actions.getHotelAccommodationsSuccessAction(result.response.data.data));
  } catch (e) {
    yield put(Actions.getHotelAccommodationsFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to load accommodations for selected hotel. Please refresh the page and try again.',
        options: { variant: 'error' },
      })
    );
  }
}

/**
 * used for creating and editing rate breaks.
 */
export function* saveRateBreakSaga(action: any) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const rateBreakName = yield select(Selectors.rateBreakNameSelector);
    const hotelUuid = yield select(Selectors.selectedHotelUuidSelector);
    const rateBreakPercentage: number = yield select(Selectors.rateBreakPercentageSelector);
    const isPreDiscount = yield select(Selectors.isPreDiscountSelector);
    const discountExtraPersonSupplement = yield select(Selectors.discountExtraPersonSupplementSelector);
    const excludeServiceAndValueTaxes = yield select(Selectors.excludeServiceAndValueTaxesSelector);
    const countryCodes = yield select(Selectors.selectedTravelAgentCountryCodesSelector);
    const accommodationProducts = yield select(Selectors.selectedHotelAccommodationUuidsSelector);
    const dates = yield select(Selectors.stayBetweenDateRangesFormattedSelector);
    const greenTaxDiscountApproach = yield select(Selectors.selectedRateApplicationSelector);
    const rateAppliesTo = yield select(Selectors.rateAppliesToSelector);

    const uuid = yield select(Selectors.rateBreakUuidSelector);

    let response;

    const postRequest: IPostRateBreakRequest = {
      name: rateBreakName,
      hotelUuid,
      dates,
      discountPercentage: rateBreakPercentage.toString(),
      preDiscount: isPreDiscount,
      greenTaxDiscountApproach,
      countryCodes: countryCodes.length ? countryCodes : null,
      discountExtraPersonSupplement,
      excludeServiceAndValueTaxes,
      accommodationProducts: accommodationProducts.length ? accommodationProducts : null,
      appliesToRateType: rateAppliesTo,
    };

    // if we have a UUID, we're patching - otherwise, we're posting
    if (uuid) {
      response = yield call(backendApi.patchRateBreak, uuid, postRequest);
    } else {
      response = yield call(backendApi.postRateBreak, postRequest);
    }

    yield put(Actions.saveRateBreakSuccessAction(response.data.data));
    yield put(
      enqueueNotification({
        message: 'Rate break saved successfully',
        options: { variant: 'success' },
      })
    );

    yield delay(1500);
    window.location.href = '/rate-breaks';
  } catch (e) {
    yield put(Actions.saveRateBreakFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Rate break failed to save. Please try again.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getRateBreakListSaga(action: any) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    // get the filter fields and information
    const currentPage = yield select(Selectors.rateBreakListingPaginationCurrentPageSelector);
    const itemsPerPage = yield select(Selectors.rateBreakListingPaginationItemsPerPageSelector);
    const hotelUuids = yield select(Selectors.rateBreakListingFilterHotelUuidsSelector);

    const query = {
      fields: {
        rateBreak: ['uuid', 'name', 'discountPercentage', 'createdAt'],
        hotel: ['name'],
      },
      ...(hotelUuids.length >= 1 && {
        filter: {
          rateBreak: {
            'hotelUuid:in': [...hotelUuids],
          },
        },
      }),
      associations: ['hotel'],
      sort: 'rateBreak.createdAt',
      page: {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      },
    };
    const result = yield call(backendApi.getRateBreakList, query);

    const rateBreakListItems: IRateBreakListItem[] = [...result.data.data];
    const totalResultsCount = result.data.meta.total;

    yield put(Actions.getRateBreakListSuccessAction(rateBreakListItems, totalResultsCount));
  } catch (e) {
    yield put(Actions.getRateBreakListFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to get rate breaks. Please refresh the page and try again.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* deleteRateBreakListSaga(action: any) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    // get the filter fields and information
    const uuids: string[] = yield select(Selectors.rateBreakUuidsForDeletionSelector);
    const totalCount: number = yield select(Selectors.rateBreakListingTotalResultsCountSelector);

    if (uuids.length <= 0) {
      return;
    }

    const query = {
      filter: {
        rateBreak: {
          'uuid:in': [...uuids],
        },
      },
    };

    const result = yield call(backendApi.deleteRateBreakList, query);
    const totalDeleted = result.data.meta.total;

    yield put(Actions.setTotalResultsCountAction(totalCount - totalDeleted));
    yield put(Actions.getRateBreakListRequestAction());
    yield put(Actions.deleteRateBreakListSuccessAction());
  } catch (e) {
    yield put(Actions.getRateBreakListFailureAction(e));
    yield put(Actions.deleteRateBreakListFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to delete rate breaks. Please refresh the page and try again.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getRateBreakSaga(action: any) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const uuid = yield select(Selectors.rateBreakUuidSelector);

    const response = yield call(backendApi.getRateBreak, uuid);

    const rateBreak: IGetRateBreakResponse = {
      ...response.data.data,
    };

    // order is important for these actions!
    // call the set hotel specifically so we trigger the load of available hotel accoms
    yield put(Actions.setSelectedHotelUuidAction(rateBreak.hotelUuid));

    // ...and then set the selected
    yield put(Actions.getRateBreakSuccessAction(rateBreak));
  } catch (e) {
    yield put(Actions.getRateBreakFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to load rate break. Please try again.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchRateBreakSaga() {
  yield takeLatest([Actions.SET_SELECTED_HOTEL_UUID], getHotelAccommodationsSaga);
  yield takeLatest([Actions.SAVE_RATE_BREAK_REQUEST], saveRateBreakSaga);
  yield takeLatest(
    [Actions.GET_RATE_BREAK_LIST_REQUEST, Actions.SET_CURRENT_PAGINATION_PAGE, Actions.SET_FILTER_HOTEL_UUIDS],
    getRateBreakListSaga
  );

  yield takeLatest([Actions.DELETE_RATE_BREAK_LIST_REQUEST], deleteRateBreakListSaga);
  yield takeLatest([Actions.GET_RATE_BREAK_REQUEST], getRateBreakSaga);
}
