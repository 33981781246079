import React, { useEffect, useState } from 'react';
import { PageTitle } from 'ui/PageTitle';

export const CrmDirectory = () => {
  return (
    <div className="crm-directory mx-[80px]">
      <PageTitle
        primaryText="Directory"
        secondaryText={`(no results found)`}
        secondaryCondition={false}
      />
    </div>
  )
};
