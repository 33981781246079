import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const WhatsappLinkIcon: React.FC<IIconProps> = React.memo(({ fill = theme.colors['brown-100'] }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M0.666626 18.5L1.93883 13.8802C1.1523 12.5242 0.739692 10.9886 0.74399 9.41873C0.74399 4.49953 4.76691 0.5 9.7053 0.5C12.1036 0.5 14.3557 1.42823 16.0448 3.11359C17.7382 4.79896 18.6709 7.0404 18.6666 9.42301C18.6666 14.3422 14.6437 18.3417 9.701 18.3417H9.69671C8.19671 18.3417 6.7225 17.9653 5.41161 17.2552L0.666626 18.5ZM5.6394 15.6426L5.91018 15.8051C7.05344 16.481 8.36433 16.836 9.701 16.8403H9.7053C13.8099 16.8403 17.1537 13.5166 17.1537 9.42728C17.1537 7.44677 16.3801 5.58603 14.9746 4.18299C13.5692 2.77994 11.6953 2.00998 9.7053 2.00998C5.60072 2.0057 2.25688 5.32937 2.25688 9.41873C2.25688 10.8175 2.648 12.182 3.39585 13.3626L3.57207 13.645L2.81992 16.3783L5.6394 15.6426Z" 
      fill={fill}
    />
    <path 
      d="M7.46601 5.6887C7.29838 5.31655 7.12217 5.30799 6.96314 5.30372C6.8342 5.29944 6.68377 5.29944 6.53334 5.29944C6.38291 5.29944 6.14222 5.35505 5.93592 5.57748C5.72962 5.79991 5.15369 6.33889 5.15369 7.43822C5.15369 8.53328 5.95741 9.59412 6.06916 9.74383C6.18091 9.89355 7.62073 12.2163 9.89437 13.1103C11.7855 13.8546 12.1723 13.7049 12.5806 13.6664C12.9889 13.6279 13.9044 13.1274 14.0935 12.6055C14.2783 12.0837 14.2783 11.6388 14.2224 11.5447C14.1666 11.4506 14.0161 11.395 13.7926 11.2838C13.5692 11.1725 12.4689 10.6336 12.2626 10.5566C12.0563 10.4839 11.9058 10.4454 11.7597 10.6678C11.6093 10.8902 11.1795 11.3907 11.0505 11.5404C10.9216 11.6901 10.7884 11.7072 10.5649 11.596C10.3414 11.4848 9.6193 11.2495 8.764 10.4881C8.09781 9.89782 7.64652 9.16636 7.51758 8.94393C7.38864 8.72149 7.50469 8.60172 7.61644 8.4905C7.71529 8.39212 7.83993 8.22957 7.95168 8.10124C8.06343 7.97292 8.10211 7.87881 8.17517 7.7291C8.24824 7.57938 8.21386 7.45105 8.15798 7.33984C8.10211 7.2329 7.66371 6.12929 7.46601 5.6887Z" 
      fill={fill}
    />
  </svg>
));

export default WhatsappLinkIcon;
