import { ENetworkRequestStatus } from 'services/BackendApi';

export interface ISaveRateBreakRequestDateRange {
  startDate: string;
  endDate: string;
}

export interface IPostRateBreakRequest {
  id?: string;
  name: string;
  hotelUuid: string;
  dates: ISaveRateBreakRequestDateRange[];
  discountPercentage: string;
  preDiscount: boolean;
  discountExtraPersonSupplement: boolean;
  excludeServiceAndValueTaxes: boolean;
  greenTaxDiscountApproach: ERateApplicationTypes;
  countryCodes: string[] | null;
  accommodationProducts: string[] | null;
  appliesToRateType: ERateAppliesToTypes;
}

export interface IPostRateBreakResponse extends IPostRateBreakRequest {
  uuid: string;
  createdAt: string;
  updatedAt: string;
}

export interface IGetRateBreakResponse extends IPostRateBreakResponse {}

export interface IRateBreakListItem {
  uuid: string;
  name: string;
  discountPercentage: string;
  hotel: {
    name: string;
  };
  createdAt: string;
}

export interface IRateBreakListingPage {
  rateBreakList: IRateBreakListItem[];
  uuidsForDeletion: string[];
  paginationCurrentPage: number;
  paginationItemsPerPage: number;
  totalResultsCount: number;
  filterHotelUuids: string[];
}

export interface IRateBreakDomain {
  uuid?: string;
  rateBreakHasChanges: boolean;
  createdAt?: string;
  updatedAt?: string;
  rateBreakName: string;
  selectedHotelUuid: string | null;
  rateBreakPercentage: number;
  selectedRateApplication: ERateApplicationTypes | null;
  isPreDiscount: boolean;
  discountExtraPersonSupplement: boolean;
  excludeServiceAndValueTaxes: boolean;
  availableHotelAccommodations: IHotelAccommodationProduct[];
  selectedHotelAccommodationUuids: string[];
  stayBetweenDateRanges: IStayBetweenDateRange[];
  rateAppliesTo: ERateAppliesToTypes;
  selectedTravelAgentCountryCodes: string[];
  networkRequests: {
    getHotelAccommodations: ENetworkRequestStatus;
    saveRateBreak: ENetworkRequestStatus;
    getRateBreakList: ENetworkRequestStatus;
    deleteRateBreakList: ENetworkRequestStatus;
    getRateBreak: ENetworkRequestStatus;
  };
  rateBreakListingPage: IRateBreakListingPage;
}

export interface IStayBetweenDateRange {
  uuid: string;
  selectedDates: string[];
}

export interface IHotelAccommodationProduct {
  uuid: string;
  name: string;
}

export enum ERateApplicationTypes {
  discountBeforeGreenTax = 'DISCOUNT_BEFORE_GREEN_TAX',
  discountWithGreenTaxAsMinimum = 'DISCOUNT_WITH_GREEN_TAX_AS_MINIMUM',
  discountWithGreenTax = 'DISCOUNT_WITH_GREEN_TAX',
}

export enum ERateAppliesToTypes {
  ALL = 'ALL',
  STATIC = 'STATIC',
  LIVE = 'LIVE',
}
