import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { formatPrice, calculatePercentage } from 'utils';

import { Margin } from './SummaryFormMargin.styles';
import { noop } from 'utils';

export const SummaryFormMargin = ({
  className,
  onApply = noop,
  onChange = noop,
  value,
  type,
  total,
  checked,
  currencyCode,
  textClassname = '',
  marginClassname = '',
}) => {
  const { t } = useTranslation();

  const handleChange = e => {
    if (e.target.name === 'taMarginAmount') {
      onChange(e, type, e.target.value);
    } else if (e.target.name === 'taMarginType') {
      onChange(e, e.target.value, value);
    }
  };

  const isMarginSet = value !== '';

  const marginAmountCalculated = calculatePercentage(total, value);
  return (
    <div>
      <span
        className={classnames(
          'block mb-15px default:uppercase text-xs leading-xs font-hurmegeometric-sans test-bistre',
          textClassname
        )}
      >
        {t('labels.addCommission')}
      </span>
      <div className="flex flex-row items-center space-x-10px">
        <input
          type="number"
          name="taMarginAmount"
          value={value}
          min={0}
          onChange={handleChange}
          placeholder="Percentage"
          className="hover:outline outline-teal-80 hover:outline-2 no-webkit-arrows font-hurmegeometric-sans border border-solid border-gray-17 p-11px w-100px h-39px text-14px leading-16px"
        />
        <span className="font-hurmegeometric-sans text-xs text-flint">%</span>
        <span className="font-hurmegeometric-sans text-xs text-gray-17">|</span>
        <span className="calculated-info text-xs font-hurmegeometric-sans text-bistre">
          Your current margin will be{' '}
          <span className="amount font-bold text-sm">{`${currencyCode}${formatPrice(marginAmountCalculated)}`}</span>
          {isMarginSet && (
            <span className="percentage text-xs font-hurmegeometric-sans text-bistre">
              , {value}% of the total cost shown above.
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default SummaryFormMargin;
