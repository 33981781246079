import React, { useState, useCallback, Fragment, useMemo } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { compose, pipe, path, prop, propOr, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';
import Badge from 'pureUi/Badge';

import getHeaderLinks, { Statements } from 'config/links/header';
import { parseQueryString } from 'utils';

import { useModalState, useEffectBoundary } from 'effects';
import { withAuthentication } from 'hoc';
import { ENVIRONMENT } from 'config';

import connect from './Header.state';
import { HeaderContainer, StyledHeader, HeaderLogo, HeaderMenuArea } from './Header.styles';
import { PrimaryNavigationLinks } from 'ui/PrimaryNavigationLinks';

import { guestToString } from 'utils/guestInformation';
import { LoginModal } from 'ui/LoginModal';
import { SignupModal } from 'ui/SignupModal';
import { useSelector } from 'react-redux';
import { getMainCompanyLogoSelector } from 'store/modules/bootstrap/selectors';

const originRedirect = pipe(parseQueryString, propOr('', 'origin'), decodeURIComponent);

export const contextTypes = Object.freeze({
  SIGN_UP: 'signup',
  LOGIN: 'login',
});

export const Header = ({
  menu,
  className,
  currentPath,
  isAuthenticated,
  loggedIn,
  history,
  location: { search, pathname = '' },
  isSR,
  isTA,
  pendingProposalsCount,
  currentUser,
  isAdmin,
}) => {
  const { t } = useTranslation();

  useEffectBoundary(() => {
    if (loggedIn) {
      const redirect = isSR ? '/' : defaultTo('/', originRedirect(search));
      history.replace(redirect);
    }
  }, [loggedIn, isSR]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [modalContext, setModalContext] = useState('');
  const mainCompanyLogo = useSelector(getMainCompanyLogoSelector);

  const { modalOpen, onModalOpen, onModalClose } = useModalState(false);

  const loggedOutMenuLinks = prop('loggedOut', getHeaderLinks());

  const onClose = useCallback(() => {
    setModalContext('');
    onModalClose();
  }, [onModalClose]);

  const onCreateClick = useCallback(() => {
    setMenuOpen(false);
    setModalContext(contextTypes.SIGN_UP);
    onModalOpen();
  }, [onModalOpen]);

  const onLoginClick = useCallback(() => {
    setMenuOpen(false);
    setModalContext(contextTypes.LOGIN);
    onModalOpen();
  }, [onModalOpen]);

  const onHeaderLinkClick = useCallback(() => {
    onModalClose();
    setMenuOpen(false);
  }, [onModalClose]);

  const onCreateFormComplete = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const onLoginFormComplete = useCallback(() => {
    if (!loggedIn) {
      return;
    }
    onModalClose();
    setMenuOpen(false);
  }, [loggedIn, onModalClose]);

  const loggedOutMenu = loggedOutMenuLinks.map(link => {
    if (link.href === '/login') {
      return {
        ...link,
        onButtonClick: () => {
          onLoginClick();
        },
        buttonStyle: 'primary',
      };
    } else if (link.href === '/sign-up') {
      return {
        ...link,
        onButtonClick: () => {
          onCreateClick();
        },
        buttonStyle: 'link',
      };
    } else {
      return {
        ...link,
      };
    }
  });

  const menuWithPendingProposals = useMemo(() => {
    const menuLinks =
      isTA && !currentUser.companyTaManager ? (menu || []).filter(item => item !== Statements) : menu || [];

    return menuLinks.map(item =>
      ['/proposals', '/proposals-v2'].includes(item.href)
        ? {
            ...item,
            title: (
              <Badge count={pendingProposalsCount} offset={[10, 5]}>
                {item.title}
              </Badge>
            ),
          }
        : item
    );
  }, [menu, currentUser, isTA, pendingProposalsCount]);

  const headerMenuProps = {
    isOpen: menuOpen,
    onLinkClick: onHeaderLinkClick,
    currentPath: currentPath,
    align: 'end',
    links: isAuthenticated ? menuWithPendingProposals : loggedOutMenu,
  };

  const shouldRedirectHome = isAuthenticated && currentPath === path(['createAccount', 'href'], loggedOutMenuLinks);
  if (shouldRedirectHome) return <Redirect to="/" />;
  if (ENVIRONMENT !== 'local' && !isAuthenticated) return null;

  return (
    <StyledHeader className={className}>
      <div className="container mx-auto max-w-1280px">
        <HeaderContainer>
          <HeaderLogo to="/">
            {mainCompanyLogo?.url && <img src={mainCompanyLogo?.url + '?isIcon=true'} alt={t('title')} />}
          </HeaderLogo>
          <HeaderMenuArea>
            <PrimaryNavigationLinks
              isAuthenticated={isAuthenticated}
              isAdmin={isAdmin}
              links={headerMenuProps.links}
              activeHref={currentPath}
              userName={guestToString(currentUser != null ? currentUser : {})}
            />
          </HeaderMenuArea>
        </HeaderContainer>
      </div>
      {!isAuthenticated && (
        <Fragment>
          {modalContext === contextTypes.LOGIN && (
            <LoginModal isOpen={modalOpen} onSuccess={onLoginFormComplete} onClose={onClose} />
          )}
          {modalContext === contextTypes.SIGN_UP && (
            <SignupModal isOpen={modalOpen} onSuccess={onCreateFormComplete} onClose={onClose} />
          )}
        </Fragment>
      )}
    </StyledHeader>
  );
};

export default compose(withAuthentication, withRouter, connect)(Header);
