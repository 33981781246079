import React from 'react';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';
import { ITransferProduct } from 'services/BackendApi';
import { isNil } from 'lodash-es';

export const Transfer = ({
  transfer,
  onUpdate,
  onCta,
  ctaLabel,
}: {
  transfer: Partial<ITransferProduct>;
  onUpdate: (newData: Partial<ITransferProduct>) => void;
  onCta: () => void;
  ctaLabel: string;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input
        label={'Name'}
        onChange={val => {
          onUpdate({
            name: val,
          });
        }}
        value={transfer.name || ''}
      />

      <Inputs.TextArea
        label={'Description'}
        onChange={val => {
          onUpdate({
            meta: {
              ...transfer.meta,
              description: val,
            },
          });
        }}
        value={transfer.meta.description}
        rows={5}
      />

      <Inputs.RadioCollection
        label="Category"
        collection={[...Inputs.transferCategoryTypes].map(mt => {
          return {
            isChecked: transfer.category === mt.value,
            value: mt.value,
            label: mt.label,
          };
        })}
        onChange={val => {
          onUpdate({
            category: val || undefined,
            options: {
              ...(transfer.options || {}),
              isOneWay: transfer.options?.isOneWay || false,
              capacity: val === 'perPerson' ? undefined : transfer.options?.capacity,
            },
          });
        }}
      />

      <Inputs.Checkbox
        label={'One way'}
        value={transfer.options?.isOneWay || false}
        onChange={val => {
          onUpdate({
            options: {
              ...(transfer.options || {}),
              isOneWay: val,
            },
          });
        }}
      />

      {(transfer.category === 'perPerson' || transfer.category === 'perPersonPerNight') && (
        <Inputs.AgeGroups
          label={'Age Groups'}
          collection={transfer.options?.ages || []}
          onChange={newList => {
            onUpdate({
              options: {
                ...(transfer.options || {}),
                isOneWay: transfer.options?.isOneWay || false,
                ages: newList,
              },
            });
          }}
        />
      )}

      {transfer.category === 'perBooking' && (
        <Inputs.Input
          label={'Capacity'}
          onChange={val => {
            onUpdate({
              options: {
                ...(transfer.options || {}),
                isOneWay: transfer.options?.isOneWay || false,
                capacity: val === '' ? undefined : parseInt(val),
              },
            });
          }}
          value={isNil(transfer.options?.capacity) ? '' : (transfer.options?.capacity || 0).toString()}
        />
      )}

      <FluidButton
        type="primary"
        className="w-[200px] self-end"
        onClick={() => {
          onCta();
        }}
      >
        {ctaLabel}
      </FluidButton>
    </div>
  );
};
