import { HotelPhotosGallery } from 'components/HotelPhotosGallery/HotelPhotosGallery';
import React, { useCallback } from 'react';
import { IHotel } from 'services/BackendApi';

import { GeneralModal } from 'ui/GeneralModal';

interface IBasketHotelPhotoGalleryModalProps {
  photos: { displayName: string; url: string }[];
  title: string;
  onClose: () => void;
}

export const BasketHotelPhotoGalleryModal: React.FC<IBasketHotelPhotoGalleryModalProps> = React.memo(({ photos, title, onClose }) => {
  const handleCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <GeneralModal
      modalWindowClassName="basket-photo-gallery px-[35px] text-black font-pt-sans overflow-y-hidden rounded w-[587px] mx-5 md:mx-0"
      shouldCloseByClickingOutside={false}
      onClose={handleCloseClick}
    >
      <h3 className="font-noe-display font-normal text-21px leading-29px ml-[-15px] mt-[23px] mb-[10px] h-[37px]">{title}</h3>

      <div className="mx-[-35px]">
        <HotelPhotosGallery photos={photos} />
      </div>

    </GeneralModal>
  );
});
