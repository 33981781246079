import { all, spawn, call } from 'redux-saga/effects';
import { watchOffersSearchRequest } from 'store/modules/fastSearch/sagas/offersSearchSaga';
import { watchOptionsRequest } from 'store/modules/fastSearch/sagas/searchOptionsSaga';
import { watchBookingActions } from 'store/modules/bookingBuilder/sagas/updateBookingBuilderResponse';
import { watchBookingOperations } from 'store/modules/bookingBuilder/sagas/latestBookingOperationSaga';
import { watchBackToSearchResults } from 'store/modules/bookingBuilder/sagas/backToSearchResultsSaga';
import { watchDestinationChange } from 'store/modules/fastSearch/sagas/nameSearchSaga';
import { watchInitializeQuery } from 'store/modules/fastSearch/sagas/initializeQuerySaga';
import { watchInitializeBookingBuilder } from './modules/bookingBuilder/sagas/initializeBookingBuilder';
import { watchGetProposalsList } from './modules/proposalsList/sagas/proposalsListSaga';
import { watchGetPendingProposalsInfo } from './modules/proposalsList/sagas/pendingProposalsInfoSaga';
import { watchGetBookingsList } from './modules/bookingsList/sagas/bookingsListSaga';
import { watchGetTravelAgentsRequest } from './modules/agents/sagas/saga';
import { watchGetCompaniesRequest } from './modules/agents/sagas/getCompaniesSaga';
import { watchGetTravelAgentBycompanyIdRequest } from './modules/agents/sagas/getTravelAgentsForCompanySaga';
import { watchLoadState } from './modules/agents/sagas/loadStateSaga';
import { watchGetDestinationList } from './modules/bookingsList/sagas/destinationListSaga';
import { watchGetSalesRepList } from './modules/bookingsList/sagas/salesRepresentativeListSaga';
import { watchUpdateQueryString } from './modules/fastSearch/sagas/updateQueryStringSaga';
import { watchGetOffersList } from './modules/offersList/sagas/offersListSaga';
import { watchConfirmRequestToDeleteOffersSaga } from './modules/offersList/sagas/offersListDeleteSaga';
import { watchUpdateCompanyLogo } from './modules/companyInfo/sagas/watchUpdateCompanyLogoSaga';
import { watchFetchCompanyLogo } from './modules/companyInfo/sagas/watchFetchCompanyLogoSaga';
import { watchRemoveCompanyLogo } from './modules/companyInfo/sagas/watchRemoveCompanyLogoSaga';
import { watchUpdateCompanyLogoPosition } from './modules/companyInfo/sagas/watchUpdateCompanyLogoPositionSaga';
import { watchHotelListSaga } from './modules/companyInfo/sagas/hotelListSaga';
import { watchGetOfferRequest } from './modules/offer/sagas/getOfferSaga';
import { watchBootstrapAppRequest } from './modules/bootstrap/sagas/bootstrapAppSaga';
import { watchPutOfferRequest } from './modules/offer/sagas/putOfferSaga';
import { watchPostOfferRequest } from './modules/offer/sagas/postOfferSaga';
import { watchPostOffersOrderRequestSaga } from './modules/offer/sagas/postOffersOrderSaga';
import { watchOfferHotelUuidChangeAction } from './modules/offer/sagas/getHotelData';
import importRatesSaga from './modules/ratesImport/sagas';
import importAncillarySuppliersSaga from './modules/ancillarySuppliersImport/saga';
import importAncillaryTransfersSaga from './modules/ancillaryTransfersImport/saga';
import importAncillaryTransfersRatesSaga from './modules/ancillaryTransfersRatesImport/saga';
import importAncillaryActivitiesSaga from './modules/ancillaryActivitiesImport/saga';
import importAncillaryActivitiesRatesSaga from './modules/ancillaryActivitiesRatesImport/saga';
import importAncillaryGroundServicesSaga from './modules/ancillaryGroundServicesImport/saga';
import importAncillaryGroundServicesRatesSaga from './modules/ancillaryGroundServicesRatesImport/saga';
import importProductsSaga from './modules/productsImport/sagas';
import driftSaga from './modules/drift/sagas';
import { watchVersionChangeSaga } from './modules/app/sagas/appVersionSaga';
import { watchVisibilityChange } from './modules/app/sagas/visibilityChangeSaga';
import { keepAliveJWTSaga } from './modules/app/sagas/JWTKeepAliveSaga';
import { watchGetBookingManager } from './modules/bookingManager/sagas/bookingManagerSaga';
import { watchGetAvailableProposals } from './modules/bookingManager/sagas/proposalSaga';
import watchChecklistManager from './modules/bookingManager/subdomains/checklist/saga';

import { watchBookingManagerComments } from './modules/bookingManager/subdomains/comments/saga';
import { watchBookingManagerDownloads } from './modules/bookingManager/subdomains/downloads/saga';
import { watchBookingManagerUploads } from './modules/bookingManager/subdomains/uploads/saga';
import { watchGuestInformationSaga } from './modules/bookingManager/subdomains/guestInformation/saga';

import { watchBookingManagerBreakdown } from './modules/bookingManager/subdomains/breakdown/sagas/saga';
import { watchBookingManagerPaymentTerms } from './modules/bookingManager/subdomains/breakdown/sagas/getPaymentTerms';
import { watchBookingManagerCancellationPolicy } from './modules/bookingManager/subdomains/breakdown/sagas/getCancellationPolicy';
import { watchBookingManagerDashboardSpecialRequests } from './modules/bookingManager/subdomains/dashboard/sagas/getSpecialRequests';
import { watchBookingManagerDashboardSpecialRequestsUpdate } from './modules/bookingManager/subdomains/dashboard/sagas/updateSpecialRequests';
import { watchBookingManagerDashboardTopNavigationData } from './modules/bookingManager/subdomains/dashboard/sagas/getTopNavigationData';
import { watchBookingStatusOptionsSaga } from './modules/bookingManager/subdomains/bookingStatusOptions/saga';
import { watchVoucherSaga } from './modules/bookingManager/subdomains/voucher/saga';
import { watchBookingManagerOwnership } from './modules/bookingManager/subdomains/ownership/saga';
import { watchBookingManagerPurchaseCostReview } from './modules/bookingManager/subdomains/purchaseCostReview/saga';
import { watchBookingManagerFinance } from './modules/bookingManager/subdomains/finance/saga';
import { watchBookingManagerTaLogoSaga } from './modules/bookingManager/subdomains/taLogo/saga';
import { watchBookingManagerPoliciesAndRestrictions } from './modules/bookingManager/subdomains/breakdown/sagas/getPoliciesAndRestrictions';
import { watchBookingManagerOfferTerms } from './modules/bookingManager/subdomains/breakdown/sagas/getOfferTerms';
import { watchBookingManagerMembership } from './modules/bookingManager/subdomains/breakdown/sagas/getMembership';
import { watchBookingManagerHotelDetails } from './modules/bookingManager/subdomains/hotelDetails/saga';
import { watchExportBookingListSaga } from './modules/bookingsList/sagas/exportBookingListSaga';
import { watchRateBreakSaga } from './modules/rateBreak/saga';
import { watchInvoiceAddresseeSaga } from './modules/bookingManager/subdomains/breakdown/sagas/invoiceAddresseeSaga';
import { watchBookingManagerGetPaymentMethods } from './modules/bookingManager/subdomains/breakdown/sagas/getPaymentMethods';
import { watchFetchCompanyData } from './modules/companyInfo/sagas/watchFetchCompanyDataSaga';
import { watchFetchCHMarkupListSaga } from './modules/companyHotelMarkup/sagas/watchFetchCHMarkupListSaga';
import { watchPostCHMarkupSaga } from './modules/companyHotelMarkup/sagas/watchPostCHMarkupSaga';
import { watchPutCHMarkupSaga } from './modules/companyHotelMarkup/sagas/watchPutCHMarkupSaga';
import { watchBookingManagerSettings } from './modules/bookingManager/subdomains/bookingSettings/saga';
import { watchDeleteCHMarkupSaga } from './modules/companyHotelMarkup/sagas/watchDeleteCHMarkupSaga';
import { watchCompanyInvoiceAddresseeSaga } from './modules/companyInvoiceAddressee/saga';
import { watchCompanyListSaga } from './modules/companyList/saga';
import { watchTravelAgentListSaga } from './modules/travelAgentList/saga';
import { watchBookingLedgerSaga } from './modules/ledger/saga';
import { watchTCBookingStatementSaga } from './modules/tcBookingStatement/saga';
import { watchGlobalStatementSaga } from './modules/globalStatement/saga';
import { watchLiveAccommodationCancellation } from './modules/bookingManager/subdomains/breakdown/sagas/liveAccommodationCancellationSaga';
import { watchGetBookingTypeSaga } from './modules/bookingManager/subdomains/dashboard/sagas/getBookingType';
import { watchSetBookingTypeSaga } from './modules/bookingManager/subdomains/dashboard/sagas/setBookingType';
import { watchBookingManagerBespokeNegotiation } from './modules/bookingManager/subdomains/bespokeNegotiation/saga';
import { watchBookingManagerExternalIds } from './modules/bookingManager/subdomains/externalIds/saga';
import { watchCompanyDocumentsSaga } from './modules/companyDocuments/sagas';
import { watchHotelSaga } from './modules/hotel/saga';
import { watchCompanyManagers } from './modules/companyManagers/saga';
import { watchHotelAccommodationProductsSaga } from './modules/hotelAccommodationProducts/saga';
import { watchCompanyIncentives } from './modules/companyIncentives/saga';
import { watchStaticRatesAvailabilitySaga } from './modules/staticRatesAvailability/saga';
import importStaticRatesAvailabilitySaga from './modules/staticRatesAvailabilityImport/sagas';
import { watchGetProposalDetails } from './modules/proposalDetails/sagas/proposalDetailsSaga';
import { watchProposalGenerateSaga } from './modules/proposalGenerate/saga';
import { watchProposalEditorSaga } from './modules/proposalEditor/saga';
import { watchCredentialsInfoSaga } from './modules/credentialsInfo/saga';
import { watchUpdateCompanySaga } from './modules/companyInfo/sagas/watchUpdateCompanySaga';
import { watchPatchCompanySaga } from './modules/companyInfo/sagas/watchPatchCompanySaga';
import { watchCreateCompanySaga } from './modules/companyInfo/sagas/watchCreateCompanySaga';
import { watchTravelAgentSaga } from './modules/travelAgent/saga';
import { watchInternalUserSaga } from './modules/internalUser/saga';
import { watchGetSrsSaga } from './modules/sr/saga';
import { watchNotificationsSaga } from './modules/notifications/saga';
import { getSelectedSRTAs, watchInternalUsersListSaga } from './modules/internalUsersList/saga';
import { watchBookingBuilderSearch } from './modules/bookingBuilder/subdomains/search/sagas';
import { watchInventoryStaysSaga } from './modules/inventoryStays/saga';
import { watchInventoryBootstrapSaga } from './modules/inventoryBootstrap/saga';
import { watchFetchDepartmentsSaga } from './modules/companyDepartments/sagas/watchFetchDepartmentsSaga';
import { watchCreateDepartmentSaga } from './modules/companyDepartments/sagas/watchCreateDepartmentSaga';
import { watchEditDepartmentSaga } from './modules/companyDepartments/sagas/watchEditDepartmentSaga';
import { watchDeleteDepartmentSaga } from './modules/companyDepartments/sagas/watchDeleteDepartmentSaga';
import { watchCompanyMembershipsSaga } from './modules/companyMemberships/saga';
import { watchInventorySuppliersSaga } from './modules/inventorySuppliers/saga';
import { watchLiveRatesSaga } from './modules/liveRates/saga';
import { watchFetchProductsWithCHMarkupSaga } from './modules/companyHotelMarkup/sagas/watchFetchProductsWithCHMarkupSaga';
import { watchCognitoSaga } from './modules/cognito/cognitoSaga';
import { watchAuthenticationSaga } from './modules/auth/authenticationSaga';
import { watchFinancesCompanySettings } from './modules/finances/saga';
import { watchGetFilterInfoSaga } from './modules/taskManagement/sagas/getFilterInfoSaga';
import { watchGetTaskListSaga } from './modules/taskManagement/sagas/getTasksSaga';
import { watchGetBookingTasksSaga } from './modules/bookingManager/subdomains/dashboard/sagas/getBookingTasks';
import { watchDeleteTaskSaga } from './modules/taskManagement/sagas/deleteTaskSaga';
import { watchCreateTaskSaga } from './modules/taskManagement/sagas/createTaskSaga';
import { watchGetTaskSaga } from './modules/taskManagement/sagas/getTaskSaga';
import { watchEditTaskSaga } from './modules/taskManagement/sagas/editTaskSaga';
import { watchCreateTaskCommentSaga } from './modules/taskManagement/sagas/createTaskCommentSaga';
import { watchListTaskCommentsSaga } from './modules/taskManagement/sagas/listTaskCommentSaga';
import { watchDeleteCommentSaga } from './modules/taskManagement/sagas/deleteCommentSaga';
import { watchGetMentionListSaga } from './modules/taskManagement/sagas/getMentionListSaga';
import { watchUploadTaskAttachmentSaga } from './modules/taskManagement/sagas/uploadTaskAttachmentSaga';
import { watchListTaskAttachmentSaga } from './modules/taskManagement/sagas/listTaskAttachmentSaga';
import { watchDeleteAttachmentSaga } from './modules/taskManagement/sagas/deleteAttachmentSaga';
import { watchHotelsByCurrency } from './modules/bookingManager/subdomains/breakdown/sagas/getHotelsByCurrencySaga';
import { watchInventoryHeaderSaga } from './modules/inventoryHeader/saga';
import { watchSearchHotelRoomsSaga } from './modules/bookingManager/subdomains/breakdown/sagas/searchHotelRoomsSaga';
import { watchGetExchangeRateSaga } from './modules/bookingManager/subdomains/breakdown/sagas/getExchangeRate';
import { watchBookingBuildSaga } from './modules/bookingManager/subdomains/breakdown/sagas/bookingBuildSaga';
import { watchSearchBookingRefRequestSaga } from './modules/taskManagement/sagas/searchBookingRefSaga';
import { watchEditAccommodationBookingBuildSaga } from './modules/bookingManager/subdomains/breakdown/sagas/editAccommodationBookingBuildSaga';

export default function* allSagas() {
  const sagas = [
    watchOffersSearchRequest,
    watchOptionsRequest,
    watchDestinationChange,
    watchBookingActions,
    watchBookingOperations,
    watchBackToSearchResults,
    watchInitializeQuery,
    watchInitializeBookingBuilder,
    watchGetProposalsList,
    watchGetPendingProposalsInfo,
    watchGetBookingsList,
    watchGetTravelAgentsRequest,
    watchGetCompaniesRequest,
    watchGetTravelAgentBycompanyIdRequest,
    watchLoadState,
    watchGetDestinationList,
    watchGetSalesRepList,
    watchUpdateQueryString,
    watchGetOffersList,
    watchConfirmRequestToDeleteOffersSaga,
    watchGetOfferRequest,
    watchBootstrapAppRequest,
    watchPutOfferRequest,
    watchPostOfferRequest,
    watchPostOffersOrderRequestSaga,
    watchOfferHotelUuidChangeAction,
    watchVersionChangeSaga,
    importRatesSaga,
    importAncillarySuppliersSaga,
    importAncillaryTransfersSaga,
    importAncillaryTransfersRatesSaga,
    importAncillaryActivitiesSaga,
    importAncillaryActivitiesRatesSaga,
    importAncillaryGroundServicesSaga,
    importAncillaryGroundServicesRatesSaga,
    importProductsSaga,
    watchGetBookingManager,
    watchUpdateCompanyLogo,
    watchFetchCompanyLogo,
    watchRemoveCompanyLogo,
    watchUpdateCompanyLogoPosition,
    driftSaga,
    watchChecklistManager,
    watchBookingManagerComments,
    watchBookingManagerDownloads,
    watchBookingManagerUploads,
    watchGetAvailableProposals,
    watchGuestInformationSaga,
    watchBookingManagerBreakdown,
    watchBookingManagerPaymentTerms,
    watchBookingManagerCancellationPolicy,
    watchBookingManagerOfferTerms,
    watchBookingManagerMembership,
    watchBookingManagerDashboardSpecialRequests,
    watchBookingManagerDashboardSpecialRequestsUpdate,
    watchBookingManagerDashboardTopNavigationData,
    watchBookingStatusOptionsSaga,
    watchVoucherSaga,
    watchBookingManagerTaLogoSaga,
    keepAliveJWTSaga,
    watchVisibilityChange,
    watchBookingManagerFinance,
    watchBookingManagerPoliciesAndRestrictions,
    watchBookingManagerOwnership,
    watchBookingManagerPurchaseCostReview,
    watchBookingManagerGetPaymentMethods,
    watchBookingManagerHotelDetails,
    watchExportBookingListSaga,
    watchLiveRatesSaga,
    watchRateBreakSaga,
    watchInvoiceAddresseeSaga,
    watchFetchCompanyData,
    watchFetchCHMarkupListSaga,
    watchPostCHMarkupSaga,
    watchPutCHMarkupSaga,
    watchFetchProductsWithCHMarkupSaga,
    watchBookingManagerSettings,
    watchDeleteCHMarkupSaga,
    watchCompanyInvoiceAddresseeSaga,
    watchCompanyListSaga,
    watchCognitoSaga,
    watchTravelAgentListSaga,
    watchTravelAgentSaga,
    watchInternalUserSaga,
    watchBookingLedgerSaga,
    watchTCBookingStatementSaga,
    watchGlobalStatementSaga,
    watchLiveAccommodationCancellation,
    watchGetBookingTypeSaga,
    watchSetBookingTypeSaga,
    watchHotelListSaga,
    watchBookingManagerBespokeNegotiation,
    watchBookingManagerExternalIds,
    watchCompanyDocumentsSaga,
    watchHotelSaga,
    watchCompanyManagers,
    watchHotelAccommodationProductsSaga,
    watchCompanyIncentives,
    watchStaticRatesAvailabilitySaga,
    importStaticRatesAvailabilitySaga,
    watchGetProposalDetails,
    watchProposalGenerateSaga,
    watchProposalEditorSaga,
    watchCredentialsInfoSaga,
    watchUpdateCompanySaga,
    watchPatchCompanySaga,
    watchCreateCompanySaga,
    watchGetSrsSaga,
    watchNotificationsSaga,
    watchFetchDepartmentsSaga,
    watchCreateDepartmentSaga,
    watchEditDepartmentSaga,
    watchDeleteDepartmentSaga,
    watchInternalUsersListSaga,
    getSelectedSRTAs,
    watchBookingBuilderSearch,
    watchInventorySuppliersSaga,
    watchInventoryStaysSaga,
    watchGetFilterInfoSaga,
    watchGetTaskListSaga,
    watchDeleteTaskSaga,
    watchGetBookingTasksSaga,
    watchInventoryBootstrapSaga,
    watchCompanyMembershipsSaga,
    watchAuthenticationSaga,
    watchListTaskCommentsSaga,
    watchCreateTaskCommentSaga,
    watchFinancesCompanySettings,
    watchCreateTaskSaga,
    watchGetTaskSaga,
    watchEditTaskSaga,
    watchDeleteCommentSaga,
    watchGetMentionListSaga,
    watchUploadTaskAttachmentSaga,
    watchListTaskAttachmentSaga,
    watchSearchHotelRoomsSaga,
    watchHotelsByCurrency,
    watchDeleteAttachmentSaga,
    watchInventoryHeaderSaga,
    watchGetExchangeRateSaga,
    watchBookingBuildSaga,
    watchSearchBookingRefRequestSaga,
    watchEditAccommodationBookingBuildSaga,
  ];

  yield all(
    sagas.map(saga =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Error ${e}`);
          }
        }
      })
    )
  );
}
