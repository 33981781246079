import React, { useCallback, useLayoutEffect, useState } from 'react';
import FluidButton from 'ui/FluidButton';
import classnames from 'classnames';
import { useCurrentWidth } from 'effects';
import { IIconProps } from 'ui/CircleIconButton';
import { RIGHT_COLUMN_BREAKPOINT } from '../const';
import { theme } from '../../../../tailwind.config';

interface IButtonWithIconProps {
  className: string;
  wrapperClassName?: string;
  iconClassName?: string;
  caption: string;
  Icon: React.FC<IIconProps>;
  isLoading?: boolean;
  onClick: () => void;
  disabled: boolean;
}

export const ButtonWithIcon: React.FC<IButtonWithIconProps> = ({
  caption, Icon, isLoading = false, className, onClick, disabled, iconClassName='relative top-[4px]', wrapperClassName
}) => {
  const { currentWidth } = useCurrentWidth();
  const buttonClassName = classnames(
    'flex-1 w-full font-normal font-hurmegeometric-sans rounded-none uppercase',
    className, 
    { 'hover:bg-green-prime hover:border-green-prime hover:text-white-true': !disabled }
  );
  const [iconColor, setIconColor] = useState(disabled ? theme.colors['brown-40'] : theme.colors['brown-100']);

  const handleMouseEnter = useCallback(() => {
    setIconColor(disabled ? theme.colors['brown-40'] : theme.colors['white-true']);
  }, [disabled]);

  const handleMouseLeave = useCallback(() => {
    setIconColor(disabled ? theme.colors['brown-40'] : theme.colors['brown-100']);
  }, [disabled]);

  useLayoutEffect(() => {
    setIconColor(disabled ? theme.colors['brown-40'] : theme.colors['brown-100']);
  }, [disabled]);

  return (
    <FluidButton
      type="secondary"
      className={buttonClassName}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isLoading={isLoading}
      disabled={disabled}
    >
      <span className={wrapperClassName}>
        {currentWidth >= RIGHT_COLUMN_BREAKPOINT ? (
          <span className={iconClassName}>
            <Icon fill={iconColor}/>
          </span>
        ) : null} {caption}
      </span>
    </FluidButton>
  )
}
