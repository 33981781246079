import { EUserType } from 'services/BackendApi';

export const userTypesOptions = [
  {
    label: 'Finance',
    value: EUserType.FINANCE,
  },
  {
    label: 'Sales Representative',
    value: EUserType.SR,
  },
  {
    label: 'Admin',
    value: EUserType.ADMIN,
  },
  {
    label: 'Rate Loader',
    value: EUserType.RL,
  },
  {
    label: 'Sales',
    value: EUserType.SALES,
  },
];
