import { Loader } from 'components/Loader/Loader';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { logoutRequestAction } from 'store/modules/auth/authenticationActions';

export const Logout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(logoutRequestAction());
  }, [dispatch]);

  return <Loader text={t('messages.loggingOut')} />;
};

export default Logout;
