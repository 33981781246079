import React, { useState, Fragment, useEffect, ReactElement } from 'react';

import { StyledLoader, LoaderImage, LoaderText } from './Loader.styles';
import defaultImage from '../../../public/img/logo.png';

// @TODO rewrite with Tailwind
interface ILoaderProps {
  isLoading?: boolean;
  text?: string;
  showSpinner?: boolean;
  showPrev?: boolean;
  image?: string;
  children?: ReactElement;
}

export const Loader: React.FC<ILoaderProps> = ({ isLoading = true, text, showSpinner = true, children = null, showPrev = false, image = defaultImage }) => {
  const [prevChildren, setPrevChildren] = useState(children);

  useEffect(() => {
    setPrevChildren(children);
  }, [isLoading, children]);

  if (!isLoading) {
    !prevChildren && setPrevChildren(children);
    return children;
  }

  return (
    <Fragment>
      {showPrev && prevChildren}
      <StyledLoader data-prev={showPrev}>
        {showSpinner && <LoaderImage src={image} alt="" />}
        <LoaderText>{text || 'Loading...'}</LoaderText>
      </StyledLoader>
    </Fragment>
  );
};
