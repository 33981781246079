import React, { useState } from 'react';
import { Price } from 'ui/Price';
import classnames from 'classnames';
import { TCurrencyCode } from 'interfaces';
import AnimateHeight from 'react-animate-height';
import { Upload } from 'services/BackendApi';
import { SvgIcon } from 'ui/SvgIcon';
import ArticleIcon from 'ui/Icons/components/Article.component';
import { theme } from '../../../tailwind.config';
import { openLink } from './helpers';

interface IProductLineWithPriceProps {
  name: string;
  total?: number | string | null;
  totalBeforeDiscount?: number | string | null;
  details?: {
    uploads: Upload[];
    title?: string;
    description?: string;
  };
  currencyCode: TCurrencyCode;
  isOnRequest?: boolean;
  className?: string;
  showDescription?: boolean;
}

export const ProductLineWithPrice: React.FC<IProductLineWithPriceProps> = React.memo(
  ({ name, total, totalBeforeDiscount, currencyCode, isOnRequest, className, details, showDescription = true }) => {
    const priceClassName = 'font-hurmegeometric-sans text-15px leading-18px text-right mt-5px m-0';
    const titleClassName = classnames(
      'product-title font-hurmegeometric-sans text-13px leading-16px  uppercase mb-0 mt-5px max-w-670px text-flint'
    );
    const [areDetailsExpanded, setDetailsExpanded] = useState(false);

    return (
      <div className={classnames(className, 'product-line')}>
        <div className="first-line flex items-start justify-between">
          <div className="product-text-container">
            <p className={titleClassName}>{name}</p>
          </div>
          <div className="product-price">
            <Price
              total={total}
              totalBeforeDiscount={totalBeforeDiscount}
              currencyCode={currencyCode}
              isOnRequest={isOnRequest}
              totalClassName={`${priceClassName} text-flint`}
              totalBeforeDiscountClassName={`${priceClassName} text-gray-80 line-through`}
              totalAfterDiscountClassName={`${priceClassName} text-red-15`}
              isOnRequestClassName={`${priceClassName} text-flint`}
            />
          </div>
        </div>
        {showDescription && (details?.description || !!details?.uploads?.length) && (
          <div className="details pb-[10px]">
            <div
              className="details-heading flex items-center cursor-pointer gap-[10px] select-none"
              onClick={() => setDetailsExpanded(value => !value)}
            >
              <span className="details-heading-icon font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">
                {areDetailsExpanded ? '-' : '+'}
              </span>
              <span className="details-heading-text font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">
                {details.title}
              </span>
            </div>
            <AnimateHeight
              duration={300}
              height={areDetailsExpanded ? 'auto' : 0}
              className={classnames('accordion-area', {})}
            >
              {(details?.description || '')
                .split('\n')
                .filter(Boolean)
                .map(item =>
                  item ? (
                    <p key={item} className="font-hurmegeometric-sans text-flint text-[13px] leading-[20px] m-[5px]">
                      {item}
                    </p>
                  ) : null
                )}
              {!!details?.uploads?.length && (
                <div className="flex flex-col uploads">
                  {details?.uploads.map(item => (
                    <div
                      key={item.uuid}
                      className="extra-detail-item flex items-start gap-[3px] cursor-pointer mt-[8px]"
                      onClick={() => openLink(item.url)}
                    >
                      <span className="flex justify-center items-center cursor-pointer hover:bg-gray-20 relative top-[2px]">
                        <SvgIcon
                          IconComponent={ArticleIcon}
                          defaultFill={theme.colors['brown-100']}
                          hoverFill={theme.colors['brown-hard']}
                          defaultBackground={theme.colors['transparent']}
                          hoverBackground={theme.colors['gray-20']}
                          width="16px"
                          height="16px"
                        />
                      </span>
                      <span className="font-hurmegeometric-sans text-[13px] leading-[20px] text-brown-100 underline ml-[3px]">
                        {item.displayName}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </AnimateHeight>
          </div>
        )}
      </div>
    );
  }
);
