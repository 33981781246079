import { normalize } from 'normalizr';
import { makeBackendApi } from 'services/BackendApi';
import { hotel as hotelSchema } from 'api/schema';
import { errorFromResponse } from 'store/common';
import { selectedTaSelector } from '../agents';

export const HOTEL = 'HOTEL';
export const SET_HOTEL_FROM_SEARCH = 'hotel/SET_HOTEL_FROM_SEARCH';
export const SET_HOTEL_UUID = 'hotel/SET_HOTEL_UUID';
export const CLEAR_HOTEL = 'hotel/CLEAR_HOTEL';

export const FETCH_HOTEL_REQUEST = 'hotel/FETCH_HOTEL_REQUEST';
export const FETCH_HOTEL_SUCCESS = 'hotel/FETCH_HOTEL_SUCCESS';
export const FETCH_HOTEL_FAILURE = 'hotel/FETCH_HOTEL_FAILURE';

/**
 * Fetch hotel action
 *
 * @returns {object}
 */
export const fetchHotelAction = () => ({
  type: FETCH_HOTEL_REQUEST,
});

/**
 * Fetch hotel
 *
 * @param {string} id
 */
// TODO dont think this is ever called anymore
export const fetchHotel = id => async (dispatch, getState) => {
  const state = getState();

  const selectedTa = selectedTaSelector(state);
  const backendApi = makeBackendApi(selectedTa?.uuid);

  dispatch(fetchHotelAction());

  try {
    const {
      // @ts-ignore legacy
      data: { data },
    } = await backendApi.getHotel(id, ['uploads']);

    const normalized = normalize(data, hotelSchema);
    dispatch(fetchHotelSuccessAction(normalized));
  } catch (e) {
    dispatch(errorFromResponse(HOTEL, e, 'There was a problem fetching this hotel. Please try again.'));
  }
};

/**
 * new non legacy stuff
 */

export const setHotelFromSearchAction = (hotelData: any) => ({
  type: SET_HOTEL_FROM_SEARCH,
  hotelData,
});

export const setHotelUuidAction = (uuid: string) => ({
  type: SET_HOTEL_UUID,
  uuid,
});

export const clearHotelAction = () => ({
  type: CLEAR_HOTEL,
});

export const fetchHotelRequestAction = (hotelUuid: string) => ({
  type: FETCH_HOTEL_REQUEST,
  hotelUuid,
});

export const fetchHotelSuccessAction = (normalizedData: any) => ({
  type: FETCH_HOTEL_SUCCESS,
  normalizedData,
});

export const fetchHotelFailureAction = (error: any) => ({
  type: FETCH_HOTEL_FAILURE,
  error,
});
