import React from 'react';

import { IIconProps } from 'ui/CircleIconButton';
import { theme } from '../../../../tailwind.config';
export const ArticleIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['black'], background = 'transparent' }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: background, minWidth: '16px', minHeight: '16px' }}
    >
      <g clipPath="url(#clip0_4101_4644)">
        <path d="M15 16.5H3C2.80109 16.5 2.61032 16.421 2.46967 16.2803C2.32902 16.1397 2.25 15.9489 2.25 15.75V2.25C2.25 2.05109 2.32902 1.86032 2.46967 1.71967C2.61032 1.57902 2.80109 1.5 3 1.5H15C15.1989 1.5 15.3897 1.57902 15.5303 1.71967C15.671 1.86032 15.75 2.05109 15.75 2.25V15.75C15.75 15.9489 15.671 16.1397 15.5303 16.2803C15.3897 16.421 15.1989 16.5 15 16.5ZM14.25 15V3H3.75V15H14.25ZM6 5.25H12V6.75H6V5.25ZM6 8.25H12V9.75H6V8.25ZM6 11.25H9.75V12.75H6V11.25Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_4101_4644">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
);

export default ArticleIcon;
