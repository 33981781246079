import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import ClockIcon from 'ui/Icons/clock.component.svg';
import UserInvertIcon from 'ui/Icons/user-invert.component.svg';
import { useRouteMatch } from 'react-router';
import { formatDateDisplay } from 'utils';
import { getTravelAgentRequestAction, resetTravelAgentAction } from 'store/modules/travelAgent/actions';
import { travelAgentGetRequestSelector, travelAgentSelector } from 'store/modules/travelAgent/selectors';

import * as CompanyDepartmentsActions from 'store/modules/companyDepartments/actions';

import { BackLink } from 'ui/BackLink';
import { TravelAgentForm } from '../TravelAgentForm';
import { renderFullName } from 'utils/guestInformation';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus, ERequesterType, EUserType, ITravelAgent } from 'services/BackendApi';
import { theme } from '../../../../tailwind.config';
import { getCurrentUserType } from 'store/modules/auth';
import { PageTitle } from 'ui/PageTitle';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';

interface ITravelCompanySettingsRouteParams {
  travelAgentUuid: string;
}

const getRequesterType = (travelAgent: ITravelAgent) => {
  if (!travelAgent.createdBy) {
    return ERequesterType.EXTERNAL;
  }

  if (travelAgent.createdBy?.type?.toLowerCase() === EUserType.SR) {
    return ERequesterType.SR;
  }

  return ERequesterType.INTERNAL;
};

interface IRequesterTypeProps {
  requesterType: ERequesterType;
  email?: string;
}

export const RequesterType: React.FC<IRequesterTypeProps> = React.memo(({ email, requesterType }) => {
  const requesterTypeMapping = {
    [ERequesterType.INTERNAL]: 'INTERNAL',
    [ERequesterType.EXTERNAL]: 'EXTERNAL',
    [ERequesterType.SR]: 'Sales Representative',
  };
  const text = requesterTypeMapping[requesterType] ?? '';
  const content = (
    <span
      className={classnames(
        'requester-type inline-flex py-2px px-5px bg-gray-10 rounded-1px text-black font-pt-sans text-xs leading-16px tracking-2xs uppercase',
        { underline: true }
      )}
    >
      {text}
    </span>
  );

  if (email) {
    return (
      <HidingTextTooltip
        showShadow={false}
        tooltipContent={email}
        position="bottom-left"
        tooltipWrapperClassname="inline-block"
      >
        {content}
      </HidingTextTooltip>
    );
  } else {
    return content;
  }
});

export const Edit: React.FC = React.memo(() => {
  const match = useRouteMatch<ITravelCompanySettingsRouteParams>();
  const travelAgentUuid = match.params.travelAgentUuid;
  const dispatch = useDispatch();

  const travelAgent = useSelector(travelAgentSelector);
  const travelAgentGetRequest = useSelector(travelAgentGetRequestSelector);
  const userType = useSelector(getCurrentUserType) as EUserType;
  const isLoading = travelAgentGetRequest === ENetworkRequestStatus.PENDING;
  const isError = travelAgentGetRequest === ENetworkRequestStatus.ERROR;

  const { companyUuid } = travelAgent || {};

  useLayoutEffect(() => {
    dispatch(getTravelAgentRequestAction(travelAgentUuid, userType));
    return () => {
      dispatch(resetTravelAgentAction());
    };
  }, [travelAgentUuid, dispatch]);

  useEffect(() => {
    if (companyUuid) {
      dispatch(CompanyDepartmentsActions.fetchCompanyDepartmentsRequestAction(companyUuid));
    }
  }, [companyUuid]);

  return (
    <div className="travel-agent-edit w-1280px mx-auto">
      <BackLink type="internal" href="/travel-agents">
        Back to travel agents
      </BackLink>
      <div className="flex justify-between items-center">
        <PageTitle
          primaryText="Travel Agent"
          secondaryCondition={!!travelAgent}
          secondaryText={`-  ${renderFullName(travelAgent?.title, travelAgent?.firstName!, travelAgent?.lastName!)}`}
          loadingCondition={isLoading}
          loadingText="Loading ..."
        />
        {!!travelAgent && (
          <div className="flex">
            <div className="flex items-center">
              <ClockIcon className="w-14px" fill={theme.colors['gray-80']} />
              <span className="ml-5px font-pt-sans text-15px leading-19px text-gray-100">
                Created {formatDateDisplay(travelAgent.createdAt)}
              </span>
            </div>
            <div className="w-2px h-19px rounded bg-gray-20 ml-2 mr-1 relative top-1px"></div>
            <div className="flex items-center">
              <span className="flex relative -top-1px">
                <UserInvertIcon fill={theme.colors['gray-80']} />
              </span>
              <p className="requester font-pt-sans text-15px leading-19px text-gray-100 ml-2px my-0">
                Requester{' '}
                <RequesterType email={travelAgent?.createdBy?.email} requesterType={getRequesterType(travelAgent)} />
              </p>
            </div>
          </div>
        )}
      </div>
      {isError && (
        <div className="mt-5">
          <ErrorBar />
        </div>
      )}
      {isLoading && (
        <div className="mt-5">
          <LoadingBar label="Loading..." />
        </div>
      )}

      {travelAgentGetRequest === ENetworkRequestStatus.SUCCESS && !!travelAgent && (
        <TravelAgentForm travelAgent={travelAgent} />
      )}
    </div>
  );
});
