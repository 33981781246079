import * as React from 'react';
import DateHelper from './DateHelper';
import Calendar from './subComponents/Calendar';
import styled from 'styled-components';
import { IconButton } from 'pureUi/Buttons';

export interface IDatePickerOwnProps {
  calendarCount?: number;
  currentDate?: string; // An ISO8601 Date string
  selectedDates?: string[]; // An array of ISO8601 Date strings
  onDayClick?: React.EventHandler<any>;
  onDayMouseOver?: React.EventHandler<any>;
  onNextClick?: React.EventHandler<any>;
  onPrevClick?: React.EventHandler<any>;
  locale?: string;
  localeDates?: string;
  firstDayOfWeek?: number; // 0 = sunday
  minDate?: string; // An ISO8601 Date string
  maxDate?: string; // An ISO8601 Date string
  enablePastDates?: boolean;
  disableDates?: string[]; // An array of ISO8601 Date strings
  enabledDates?: string[]; // An array of ISO8601 Date strings
  readonly?: boolean;
}
export interface IDatePickerProps extends IDatePickerOwnProps, React.HTMLProps<HTMLDivElement> {
  availableDates?: string[];
  unavailableDates?: string[];
  requestDates?: string[];
}

const DatePicker = ({ 
  currentDate = new Date().toUTCString(),
  selectedDates = [],
  calendarCount = 1,
  locale = 'en-US',
  localeDates = 'en-US',
  firstDayOfWeek = 1,
   ...props
}: IDatePickerProps) => {
  return (
    <div className={props.className}>
      <IconButton className="button prevButton" onClick={props.onPrevClick} type="button">
        {/* chevron left */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path
            fillRule="evenodd"
            d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
            clipRule="evenodd"
          />
        </svg>
      </IconButton>

      <IconButton className="button nextButton" onClick={props.onNextClick} type="button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path
            fillRule="evenodd"
            d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
            clipRule="evenodd"
          />
        </svg>
      </IconButton>

      <div className="calendars">
        {Array(Math.abs(calendarCount))
          .fill(0)
          .map((i, index) => (
            <Calendar
              className="calendar"
              key={`calendar-${index}`}
              currentDate={DateHelper.addMonths(currentDate, index)}
              selectedDates={selectedDates}
              locale={locale}
              localeDates={localeDates}
              firstDayOfWeek={firstDayOfWeek}
              onDayClick={props.onDayClick}
              onDayMouseOver={props.onDayMouseOver}
              minDate={props.minDate}
              maxDate={props.maxDate}
              disablePastDates={!props.enablePastDates}
              disableDates={props.disableDates || []}
              availableDates={props.availableDates}
              unavailableDates={props.unavailableDates}
              requestDates={props.requestDates}
              readonly={props.readonly}
              enabledDates={props.enabledDates}
            />
          ))}
      </div>
    </div>
  );
};

export default styled(DatePicker)`
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  user-select: none;
  z-index: 10;

  .calendars {
    display: flex;
  }

  .calendars .calendar:not(:first-child):not(:last-child) .grid {
    margin-left: 0;
    margin-right: 0;
  }

  .button {
    position: absolute;
    top: 0;
    height: 45px;
    width: 53px;
    padding-top: 8px;
    border: none;
    background-color: transparent;
  }

  .nextButton {
    right: 0;
  }
`;

export { DateHelper };
