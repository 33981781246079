import React from 'react';
import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const ArrowPrevIcon: React.FC<IIconProps> = React.memo(({ className = '', icon = 'brown-100', width = '8px', height = '8px' }) => (
  <svg width={width} height={height} className={className} viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.52857 6.36718C1.66923 6.36718 1.80989 6.42887 1.91538 6.52141L7.85825 12.2279C8.06924 12.413 8.03407 12.7214 7.82308 12.8757C7.61209 13.0607 7.26044 13.0299 7.08462 12.8448L1.14175 7.13833C0.930764 6.95325 0.965929 6.64479 1.17692 6.49056C1.28241 6.39803 1.42307 6.36718 1.52857 6.36718Z"
      fill={theme.colors[icon]}
      stroke={theme.colors[icon]}
    />
    <path
      d="M7.47131 1.00003C7.61197 1.00003 7.75263 1.03087 7.82296 1.12341C8.03395 1.30849 8.03395 1.5861 7.82296 1.77117L1.91526 7.13835C1.70427 7.32343 1.38779 7.32343 1.1768 7.13835C0.965807 6.95328 0.965807 6.67567 1.1768 6.49059L7.0845 1.12341C7.18999 1.03087 7.33065 1.00003 7.47131 1.00003Z"
      fill={theme.colors[icon]}
      stroke={theme.colors[icon]}
    />
  </svg>
));

export default ArrowPrevIcon;
