import { useSelector } from 'react-redux';
import { handleSaveBooking } from '../helpers';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { ModalModes } from 'containers/SummaryForm/const';
import { useCallback } from 'react';
import { BackendApiService, BookingBuilder, ITravelAgent } from 'services/BackendApi';
import { AxiosInstance } from 'axios';
import { AxiosWrapper } from 'services/AxiosWrapper';
import { TCountryCode } from 'interfaces';
import { iActingOnBehalfOfUser } from 'store/modules/actingOnBehalfOf/model';

export const useSaveBooking = ({
  bookingBuild,
  setRequestInProgress,
  backendApi,
  selectedCompanyMembership,
  clientCountryCode,
  closeModalRatesWarning,
  containsLiveRates,
  setShouldShowLeaveAlert,
  setRedirectLocation,
  openModalRatesWarning,
  taMarginAmountOverride,
  actingOnBehalfOfUser,
  selectedTa,
  basketBuildUuid,
}: {
  bookingBuild: BookingBuilder | null;
  setRequestInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  backendApi: BackendApiService<AxiosWrapper<AxiosInstance>>;
  selectedCompanyMembership: {
    uuid: string | null;
  };
  clientCountryCode: TCountryCode | null;
  closeModalRatesWarning: () => void;
  containsLiveRates: boolean;
  setShouldShowLeaveAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  openModalRatesWarning: (mode: typeof ModalModes[keyof typeof ModalModes]) => void;
  taMarginAmountOverride?: string;
  actingOnBehalfOfUser: iActingOnBehalfOfUser | null;
  selectedTa: Partial<ITravelAgent> | null;
  basketBuildUuid: string;
}) => {
  const bookingDomain = useSelector(BookingBuilderSelectors.bookingSelector);

  const handleSaveBookingButton = useCallback(async () => {
    setRequestInProgress(true);
    setShouldShowLeaveAlert(false);
    try {
      await handleSaveBooking({
        bookingBuild,
        backendApi,
        bookingDomain,
        clientCountryCode,
        selectedCompanyMembership,
        setShouldShowLeaveAlert,
        setRedirectLocation,
        taMarginAmountOverride,
        actingOnBehalfOfUser: actingOnBehalfOfUser!,
        selectedTa,
        basketBuildUuid,
      });
    } catch (e) {
      setRequestInProgress(false);
      setShouldShowLeaveAlert(true);
    }
    closeModalRatesWarning();
  }, [
    bookingBuild,
    backendApi,
    bookingDomain,
    clientCountryCode,
    closeModalRatesWarning,
    selectedCompanyMembership,
    setRedirectLocation,
    setRequestInProgress,
    setShouldShowLeaveAlert,
    taMarginAmountOverride,
    actingOnBehalfOfUser,
    selectedTa,
    basketBuildUuid,
  ]);

  const handleSaveBookingButtonTrigger = useCallback(() => {
    if (containsLiveRates) {
      openModalRatesWarning(ModalModes.SAVE);
    } else {
      handleSaveBookingButton();
    }
  }, [containsLiveRates, handleSaveBookingButton, openModalRatesWarning]);

  return { handleSaveBookingButton, handleSaveBookingButtonTrigger };
};
