import { AxiosResponse } from 'axios';
import { call, takeLatest, select, put } from 'redux-saga/effects';
import { taMarginTypeSelector, taMarginAmountSelector, travelAgentUserUuidSelector } from '../selectors';
import cloneDeep from 'lodash/cloneDeep';
import {
  updateBookingRequestAction,
  updateBookingSuccessAction,
  updateBookingFailureAction,
  UPDATE_TRANSFER,
  UPDATE_LODGING_GUEST_AGES_ACTION,
  UPDATE_LODGING_MEAL_PLAN_ACTION,
  UPDATE_LODGING_OCCASIONS_ACTION,
  ADD_LODGING_ACTION,
  REMOVE_LODGING_ACTION,
  UPDATE_GROUND_SERVICE_ACTION,
  UPDATE_SUPPLEMENT_ACTION,
  UPDATE_FINE_ACTION,
  UPDATE_LODGING_DATES_ACTION,
  UPDATE_TA_MARGIN_AMOUNT_ACTION,
  UPDATE_TA_MARGIN_TYPE_ACTION,
  UPDATE_IS_TA_MARGIN_APPLIED_ACTION,
  UPDATE_TRAVEL_AGENT_USER_ID,
  SAVE_CUSTOM_ITEM,
  REMOVE_CUSTOM_ITEM,
  UPDATE_LODGING_REPEAT_GUEST_ACTION,
} from '../actions';

import { makeBackendApi, BookingBuilderEndpointSuccess, BookingBuilderRequest, EUserType } from 'services/BackendApi';
import { bookingRequestSelector } from '../selectors';
import { enqueueNotification } from 'store/modules/ui/actions';

import { backwardCompatBookingBuilderAction } from 'store/modules/bookings';
import { selectedTaSelector } from '../../agents';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import * as ActingOnBehalfOfSelectors from 'store/modules/actingOnBehalfOf/selectors';
import * as BasketSelectors from 'store/modules/basket/selectors';
import * as BasketActions from 'store/modules/basket/actions';
import { isNil } from 'lodash-es';
import { iActingOnBehalfOfUser } from 'store/modules/actingOnBehalfOf/model';

export const sanitizeBBv1Request = (request: BookingBuilderRequest, forBasket?: boolean) => {
  const clonedRequest = cloneDeep(request);
  clonedRequest.Accommodation.forEach(accom => {
    accom.liveRate && delete accom.liveRate.externalMealPlanDescription;
    forBasket && delete accom.availableToInstantBook;
  });

  return clonedRequest;
};

export function* bookingBuilderResponseSaga(action: any) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const origRequest: BookingBuilderRequest = yield select(bookingRequestSelector);
    const request = sanitizeBBv1Request(origRequest);
    if (!request || !request.Accommodation || request.Accommodation.length <= 0) {
      return;
    }

    const searchQuery = yield select(FastSearchSelectors.offersQuerySelector);
    const basketBuild = yield select(BasketSelectors.basketBuildSelector);

    // get the acting on behalf of data here and send it up if need be
    const actingOnBehalfOfUser: iActingOnBehalfOfUser = yield select(
      ActingOnBehalfOfSelectors.actingOnBehalfOfUserSelector
    );

    yield put(updateBookingRequestAction(action.hotelUuid));

    // if we have a basket build here, need to mark setShouldShowLeaveWarningInBookingBuilderAction
    if (!isNil(basketBuild)) {
      yield put(BasketActions.setShouldShowLeaveWarningInBookingBuilderAction(true));
    }

    const bookingBuilderEndpointResponse: AxiosResponse<BookingBuilderEndpointSuccess> = yield call(
      backendApi.postBookingBuilderRequest,
      {
        bookingBuilderRequest: request,
        clientCountryCode: searchQuery.clientCountryCode,
        travelAgentUuid: undefined,
        validateStatusFunc: undefined,
        actingOnBehalfOfUser,
      }
    );

    const marginType = yield select(taMarginTypeSelector);
    const marginAmount = yield select(taMarginAmountSelector);
    const travelAgentUserUuid = yield select(travelAgentUserUuidSelector);

    yield put(updateBookingSuccessAction(request, bookingBuilderEndpointResponse.data.data, action.hotelUuid));

    // this action ensures that every single time we update our new booking builder response, we keep the old `bookings` domain in sync
    // this is done because there is a lot of code that relies on the old `bookings` domain data
    // @see https://pureescapes.atlassian.net/browse/OWA-1030
    yield put(
      backwardCompatBookingBuilderAction(
        action.hotelUuid,
        request,
        bookingBuilderEndpointResponse.data.data,
        marginType,
        marginAmount,
        travelAgentUserUuid
      )
    );
  } catch (e) {
    yield put(updateBookingFailureAction(e.message, action.hotelUuid));
    yield put(
      enqueueNotification({
        message: 'There was a problem during updating your Booking. Please try again.',
        options: { variant: 'error' },
      })
    );
    console.error(e);
  }
}

export function* watchBookingActions() {
  yield takeLatest(
    [
      UPDATE_TRANSFER,
      UPDATE_LODGING_GUEST_AGES_ACTION,
      UPDATE_LODGING_MEAL_PLAN_ACTION,
      ADD_LODGING_ACTION,
      UPDATE_LODGING_OCCASIONS_ACTION,
      REMOVE_LODGING_ACTION,
      UPDATE_GROUND_SERVICE_ACTION,
      UPDATE_SUPPLEMENT_ACTION,
      UPDATE_FINE_ACTION,
      UPDATE_LODGING_DATES_ACTION,
      UPDATE_TA_MARGIN_AMOUNT_ACTION,
      UPDATE_TA_MARGIN_TYPE_ACTION,
      UPDATE_IS_TA_MARGIN_APPLIED_ACTION,
      UPDATE_TRAVEL_AGENT_USER_ID,
      SAVE_CUSTOM_ITEM,
      REMOVE_CUSTOM_ITEM,
      UPDATE_LODGING_REPEAT_GUEST_ACTION,
    ],
    bookingBuilderResponseSaga
  );
}
