import React, { Fragment, useCallback, useState } from 'react';

import { SignupModal } from 'ui/SignupModal';
import Home from 'pages/Home';
import { ENVIRONMENT } from 'config';
import { LoadingOverlay } from 'ui/LoadingOverlay';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

export const CreateAccount = ({ history, ...props }) => {
  const { dynamicParameters } = useDynamicParameters();
  const homeUrl = ENVIRONMENT !== 'local' ? dynamicParameters.MARKETING_APP_URL : '/';
  const [isModalOpen, setModalOpen] = useState(true);
  const [isLoadingOverlayOpen, setLoadingOverlayOpen] = useState(false);

  const handleClose = useCallback(() => {
    setModalOpen(false);
    setLoadingOverlayOpen(true);
    setTimeout(() => {
      window.location.href = homeUrl;
    }, 100);
  }, [homeUrl]);

  return (
    <Fragment>
      <Home history={history} {...props} />
      <SignupModal isOpen={isModalOpen} onClose={handleClose} />
      <LoadingOverlay isOpen={isLoadingOverlayOpen} />
    </Fragment>
  );
};

export default CreateAccount;
