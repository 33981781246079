import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const EmailLinkIcon: React.FC<IIconProps> = React.memo(({ fill = theme.colors['brown-100'] }) => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      fillRule="evenodd" clipRule="evenodd"
      d="M13.5 0.5L1.5 0.5C0.675 0.5 0.0075 1.175 0.0075 2L0 11C0 11.825 0.675 12.5 1.5 12.5L13.5 12.5C14.325 12.5 15 11.825 15 11L15 2C15 1.175 14.325 0.5 13.5 0.5ZM1.5 3.5L7.5 7.25L13.5 3.5L13.5 11L1.5 11L1.5 3.5ZM1.5 2L7.5 5.75L13.5 2L1.5 2Z" 
      fill={fill}
    />
  </svg>
));

export default EmailLinkIcon;
