import styled from 'styled-components';
import { Link } from 'components/LegacyLink/Link';
import ArrowPrevIcon from 'ui/Icons/components/ArrowPrev.component';

export const Navigation = styled(Link)`
  font-size: ${props => props.theme.fonts.sizes.default}px;
  letter-spacing: ${props => props.theme.fonts.letterSpacing.medium}px;
  text-transform: uppercase;
  color: ${props => props.theme.palette.primary} !important;
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: ${props => props.theme.fonts.sizes.normal}px;
`;

export const BackWrapper = styled.div`
  background: ${props => props.theme.palette.navigation};
  padding ${props => props.theme.spacing.gutter / 4}px ${props => props.theme.spacing.gutter / 2}px;
  margin-right: ${props => props.theme.spacing.gutter}px;
`;

export const Button = styled(ArrowPrevIcon)`
  font-size: ${props => props.theme.fonts.sizes.normal}px !important;
`;
