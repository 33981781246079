import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { EUserType, ITravelAgent } from 'services/BackendApi';
import { ESortOrder } from 'store/common/types';
import { CircleIconButton } from 'ui/CircleIconButton';
import { edit } from 'ui/Icons';
import { Table as BaseTable } from 'ui/Table';
import { formatDateDisplay } from 'utils';
import { ISR } from 'store/modules/user/model';
import { UserStatus } from 'ui/UserStatus';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';

export interface ITableProps {
  className?: string;
  travelAgents: ITravelAgent[];
  loading: boolean;
  onDelete: (ITravelAgent) => void;
  selectSR: (sr: ISR | ITravelAgent) => void;
}

const userTypeToLabelMap = {
  [EUserType.SR]: 'Sales Representative',
  [EUserType.RL]: 'Rate Loader',
  [EUserType.TA]: 'Travel Agent',
  [EUserType.FINANCE]: 'Finance',
  [EUserType.ADMIN]: 'Admin',
  [EUserType.SALES]: 'Sales',
};

export const Table: React.FC<ITableProps> = props => {
  const columns = useMemo(
    () => [
      {
        id: 'created',
        label: 'Created At',
        width: '100px',
        className: 'pl-4',
      },
      {
        id: 'type',
        label: 'Role',
        width: '150px',
        className: 'pl-4',
      },
      {
        id: 'name',
        label: 'Name',
        width: '170px',
        className: 'pl-4',
      },
      {
        id: 'country',
        label: 'Country',
        width: '80px',
        className: 'pl-4',
      },
      {
        id: 'email',
        label: 'Email',
        width: '200px',
      },
      {
        id: 'assignments',
        label: 'Assignments',
        width: '90px',
        className: 'pl-4'
      },
      {
        id: 'status',
        label: 'Status',
        width: '80px',
      },
      {
        id: 'placeholder',
        label: '',
        width: '50px',
      },
      {
        id: 'actions',
        label: '',
        width: '90px',
      },
    ],
    []
  );
    
  const rows = useMemo(() => {
    return props.travelAgents.map(x => {
      const rowItemClassName = 'text-ellipsis overflow-hidden whitespace-nowrap';
      const fullName = [x.firstName, x.lastName].join(' ');

      const actions = (
        <div className="item-actions flex items-center relative">
          <div className="w-4px h-34px rounded bg-brown-15 mr-10px" />
          {/* TODO not implemented yet */}
          <HidingTextTooltip tooltipContent="Edit" position="bottom" tooltipContentClassname="px-[5px] py-[5px]">
            <Link to={`/users/${x.uuid}/edit`}>
              <CircleIconButton
                type="secondary"
                className="edit-action mx-1"
                iconClass="inline relative left-0 top-1px w-12px h-12px"
                iconSrc={edit}
              />
            </Link>
          </HidingTextTooltip>
        </div>
      );

      return {
        id: x.uuid,
        cells: [
          {
            id: 'created',
            value: formatDateDisplay(x.createdAt),
            title: formatDateDisplay(x.createdAt),
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'type',
            value: userTypeToLabelMap[x.type],
            title: userTypeToLabelMap[x.type],
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'name',
            value: fullName,
            title: fullName,
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'country',
            value: x.countryCode,
            className: `${rowItemClassName} text-center`,
          },
          {
            id: 'email',
            value: x.email,
            title: x.email,
            className: rowItemClassName,
          },
          {
            id: 'assignments',
            value: <span className='hover:cursor-pointer' onClick={()=>{props.selectSR(x)}}>{x.type === 'sr' ? (x.assignedTravelAgents?.length > 0 ? x.assignedTravelAgents?.length : 0) : null}</span>,
            className: `${rowItemClassName} underline text-gray-100 text-center`,
          },
          {
            id: 'status',
            value: <UserStatus value={x.status} />,
            className: rowItemClassName,
          },
          {
            id: 'placeholder',
            value: null,
            className: rowItemClassName,
          },
          {
            id: 'actions',
            value: actions,
          },
        ],
      };
    });
  }, [props.travelAgents]);

  return (
    <BaseTable
      columns={columns}
      rows={rows}
      sortBy="name"
      sortOrder={ESortOrder.ASC}
      onSort={() => {}}
      messageWhenEmpty="No results"
      loading={props.loading}
    />
  );
};
