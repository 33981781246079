import React, { useCallback, useState, useMemo } from 'react';
import ShareIcon from 'ui/Icons/share.component.svg';
import { enqueueNotification } from 'store/modules/ui';
import { SvgIcon } from 'ui/SvgIcon';
import { IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { MenuButton } from 'ui/MenuButton/stateful';
import { makeBackendApi, EShareType } from 'services/BackendApi';
import { BasketShareLinkModal } from '../BasketShareLinkModal';
import { useDispatch } from 'react-redux';

interface IShareLinkProps {
  builds: IBasketBuildL4[];
  isTa: boolean;
  selectedTaUserUuid: string | null;
  actingOnBehalfOfUuid: string | null;
}

export const ShareLinkButton: React.FC<IShareLinkProps> = React.memo((props) => {
  const { builds, isTa, selectedTaUserUuid, actingOnBehalfOfUuid } = props;
  const dispatch = useDispatch();
  const backendApi = makeBackendApi();
  const [shareLinkUuid, setShareLinkUuid] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBasketShareLinkModalOpen, setIsBasketShareLinkModalOpen] = useState<boolean>(false);
  const shareLink = `${window.location.origin}/shared/${shareLinkUuid}`;

  
  const handleShare = useCallback(async (shareType: EShareType) => {
    setIsLoading(true);
    try {
      const res = await backendApi
        .getShareLink({
            travelAgentUuid: isTa ? undefined : selectedTaUserUuid ?? undefined,
            actingOnBehalfOfUserUuid: isTa ? undefined : actingOnBehalfOfUuid ?? undefined,
          },
          builds.map(build => build.uuid), 
          shareType,
        );
      setIsLoading(false);
      setShareLinkUuid(res.data.link);
      setIsBasketShareLinkModalOpen(true);
    } catch(e) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log('e', e);
      setIsBasketShareLinkModalOpen(false);
      dispatch(
        enqueueNotification({
          message: `Error getting Share Link`,
          options: { variant: 'error' },
        })
      );
    };
  }, [actingOnBehalfOfUuid, backendApi, builds, dispatch, isTa, selectedTaUserUuid]);
  
  const handleClose = useCallback(async () => {
    setIsBasketShareLinkModalOpen(false);
  }, []);

  const handleSelect = useCallback(async (option) => {
    option.handler()
  }, []);

  const options = useMemo(() => ([
    {
      key: 'to-travel-partner',
      label: 'To Travel Partner',
      handler: () => handleShare(EShareType.TA),
    },
    {
      key: 'to-final-client',
      label: 'To Final Client',
      handler: () => handleShare(EShareType.CLIENT),
    }
  ]), [handleShare]);

  return (
    <MenuButton
      className={`${`copy-all-builds flex`} rounded-none mr-3 ${builds.length === 0 ? 'text-brown-20 border-brown-20' : 'text-brown-80 border-brown-80'}`}
      innerClassName={'font-light'}
      fixedWidth="150px"
      options={options}
      onSelect={handleSelect}
      disabled={builds.length === 0}
      rounded={false}
    >
      { isBasketShareLinkModalOpen && (
        <BasketShareLinkModal
          shareLink={shareLink}
          isLoading={isLoading}
          onClose={handleClose}
        />
      )}
      <i className="far ml-2 mr-2">
        <SvgIcon IconComponent={ShareIcon} width="18px" height="18px" className={`${builds.length === 0 ? 'fill-brown-20' : 'fill-brown-80'}`} />
      </i>
      <span className='text-13px'>{`SHARE (${builds.length})`}</span>
    </MenuButton>
  );
});
