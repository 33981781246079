import { useEffect, useRef, useState } from 'react';
import { ENetworkRequestStatus } from 'services/BackendApi';
import * as _ from 'lodash-es';
import axios, { CancelTokenSource } from 'axios';

/**
 * Define all the API calls that the component needs at the top of the component file
 * Each hook return then has the following properties
 * - execute - the function that will be called to execute the API call
 * - cancel - the function that will be called to cancel the API call
 * - response - the response from the API call
 * - requestStatus - the status of the API call
 * - status - the status of the API call
 *
 * See src/containers/SharedLinkPage/index.tsx for an example of in-app usage
 * and src/hooks/useApiRequest.stories.tsx for a more clear breakdown
 *
 * `execute`
 * This function takes arguments that are passed to the API function
 *
 * It _ALSO_ passes an extra argument called `config` which is an object that contains
 * `cancelToken` and `signal` properties.
 *
 * The `cancelToken` property is used to cancel the API call.
 * The `signal` property is used to abort the API call.
 *
 * You still need to update your API function to use these.
 */
export const useApiRequest = <TFuncDef extends (...args: any[]) => any>(apiClientFunction: TFuncDef) => {
  type TResponseType = Awaited<ReturnType<TFuncDef>>;

  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const [response, setResponse] = useState<TResponseType>();
  const [status, setStatus] = useState<number | undefined>();
  const [requestStatus, setRequestStatus] = useState(ENetworkRequestStatus.IDLE);

  const execute = async (...args: Parameters<TFuncDef>) => {
    await cancel();

    abortControllerRef.current = new AbortController();
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenRef.current = cancelTokenSource;

    try {
      setRequestStatus(ENetworkRequestStatus.PENDING);
      const returnValue = ((await apiClientFunction(...(args || []), {
        cancelToken: cancelTokenSource.token,
        signal: abortControllerRef.current.signal,
      })) as unknown) as TResponseType;

      setResponse(returnValue);
      setRequestStatus(ENetworkRequestStatus.SUCCESS);

      // behind an if in case a non-API client function is passed in
      if ((returnValue as any).status) {
        setStatus((returnValue as any).status);
      }
      return returnValue;
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      }
      if (error.name === 'CancelToken') {
        return;
      }
      if (axios.isCancel(error)) {
        return;
      }

      setRequestStatus(ENetworkRequestStatus.ERROR);

      // behind an if in case a non-API client function is passed in
      if (error.response) {
        setResponse(error.response);
        if (error.response.status) {
          setStatus(error.response.status);
        }
      }
    }
  };

  const cancel = async () => {
    if (cancelTokenRef.current) {
      setRequestStatus(ENetworkRequestStatus.CANCELLED);
      cancelTokenRef.current.cancel();
    }
    if (abortControllerRef.current) {
      setRequestStatus(ENetworkRequestStatus.CANCELLED);
      abortControllerRef.current?.abort();
    }
  };

  useEffect(() => {
    return () => {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel('Component unmounted');
      }
      if (abortControllerRef.current) {
        abortControllerRef.current?.abort();
      }
    };
  }, []);

  return { execute, cancel, response, requestStatus, status };
};
