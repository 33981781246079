import { ENetworkRequestStatus } from 'services/BackendApi';
import { ERateAppliesToTypes, IRateBreakDomain } from './types';

export const initialState: IRateBreakDomain = {
  rateBreakName: '',
  rateBreakPercentage: 0,
  selectedHotelUuid: null,
  selectedRateApplication: null,
  availableHotelAccommodations: [],
  selectedHotelAccommodationUuids: [],
  stayBetweenDateRanges: [],
  selectedTravelAgentCountryCodes: [],
  rateAppliesTo: ERateAppliesToTypes.ALL,
  isPreDiscount: false,
  discountExtraPersonSupplement: false,
  excludeServiceAndValueTaxes: false,
  rateBreakHasChanges: false,
  networkRequests: {
    getHotelAccommodations: ENetworkRequestStatus.IDLE,
    saveRateBreak: ENetworkRequestStatus.IDLE,
    getRateBreakList: ENetworkRequestStatus.IDLE,
    deleteRateBreakList: ENetworkRequestStatus.IDLE,
    getRateBreak: ENetworkRequestStatus.IDLE,
  },
  rateBreakListingPage: {
    rateBreakList: [],
    uuidsForDeletion: [],
    paginationCurrentPage: 1,
    paginationItemsPerPage: 10,
    totalResultsCount: 0,
    filterHotelUuids: [],
  },
};
