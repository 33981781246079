import * as Actions from './actions';
import * as PackageActions from './actions.packages';
import { produce } from 'immer';
import { IOfferUI, EOfferUiPackageDiscountStacking, ESteppingApproach } from 'services/BackendApi';
import { IDateRange } from 'interfaces';
import {
  OfferDomainAction,
  GET_OFFER_SUCCESS,
  PUT_OFFER_SUCCESS,
  POST_OFFER_SUCCESS,
  RESET_OFFER_CHANGES,
} from '../../actions';
import { initialState } from '../../model';
import * as R from 'ramda';
import { productDiscountsReducer } from './offerProductDiscountsReducer';
import { subProductDiscountsReducer } from './offerSubProductDiscountsReducer';
import { clearAllProductsFromDiscounts } from '../../utils';
import { packageDiscountsReducer } from './reducer.packages';

export const offerReducer = (state: IOfferUI = initialState.offer, action: OfferDomainAction): IOfferUI => {
  switch (action.type) {
    case GET_OFFER_SUCCESS:
    case RESET_OFFER_CHANGES:
      return action.offer;
    case PUT_OFFER_SUCCESS:
    case POST_OFFER_SUCCESS:
      return {
        // Offer may contain associations which are not on the put response
        ...state,
        ...action.offer,
      };
    case Actions.OFFER_HOTEL_UUID_CHANGE:
      return offerHotelUuidChangeReducer(state, action);
    case Actions.OFFER_HOTEL_UUID_CHANGE_SUCCESS:
      return offerHotelUuidChangeSuccessReducer(state, action);
    case Actions.OFFER_NAME_CHANGE:
      return offerNameChangeReducer(state, action);
    case Actions.OFFER_TERMS_CHANGE:
      return offerTermsChangeReducer(state, action);
    case Actions.OFFER_FURTHER_INFORMATION_CHANGE:
      return offerFurtherInformationChangeReducer(state, action);
    case Actions.OFFER_ADD_STAY_BETWEEN_PREREQUISITE:
      return offerAddStayBetweenPrerequisiteReducer(state, action);
    case Actions.OFFER_CHANGE_STAY_BETWEEN_PREREQUISITE:
      return offerChangeStayBetweenPrerequisiteReducer(state, action);
    case Actions.OFFER_REMOVE_STAY_BETWEEN_PREREQUISITE:
      return offerRemoveStayBetweenPrerequisiteReducer(state, action);
    case Actions.OFFER_SET_BOOLEAN_PREREQUISITE:
      return offerSetBooleanPrerequisitesReducer(state, action);
    case Actions.OFFER_SET_PRE_DISCOUNT:
      return offerSetPreDiscountReducer(state, action);
    case Actions.OFFER_SET_COUNTRY_CODE_PREREQUISITE:
      return offerSetCountryCodeReducer(state, action);
    case Actions.OFFER_CLEAR_ALL_COUNTRY_CODE_PREREQUISITE:
      return offerClearAllCountryCodeReducer(state, action);
    case Actions.OFFER_SET_ACCOMMODATION_PRODUCT_PREREQUISITE:
      return offerSetAccommodationProductPrerequisiteReducer(state, action);
    case Actions.OFFER_CLEAR_ALL_ACCOMMODATION_PRODUCT_PREREQUISITE:
      return offerClearAllAccommodationProductPrerequisiteReducer(state, action);
    case Actions.OFFER_SET_ADVANCE_BOOK_BY_PREREQUISITE:
      return offerSetAdvanceBookByPrerequisiteReducer(state, action);
    case Actions.OFFER_SET_ADVANCE_MINIMUM_PREREQUISITE:
      return offerSetAdvanceMinimumPrerequisiteReducer(state, action);
    case Actions.OFFER_SET_ADVANCE_MAXIMUM_PREREQUISITE:
      return offerSetAdvanceMaximumPrerequisiteReducer(state, action);
    case Actions.OFFER_CLEAR_ALL_ADVANCE_PREREQUISITE:
      return offerClearAllAdvancePrerequisiteReducer(state, action);
    case Actions.OFFER_SET_MAX_LODGINGS_PREREQUISITE:
      return offerSetMaxLodgingsPrerequisiteReducer(state, action);
    case Actions.OFFER_SET_STAY_LENGTH_MINIMUM_PREREQUISITE:
      return offerSetStayLengthMinimumPrerequisiteReducer(state, action);
    case Actions.OFFER_SET_STAY_LENGTH_MAXIMUM_PREREQUISITE:
      return offerSetStayLengthMaximumPrerequisiteReducer(state, action);
    case Actions.OFFER_SET_STAY_LENGTH_STRICT_PREREQUISITE:
      return offerSetStayLengthStrictPrerequisiteReducer(state, action);
    case Actions.OFFER_CLEAR_ALL_STAY_LENGTH_PREREQUISITE:
      return offerClearAllStayLengthPrerequisiteReducer(state, action);
    case Actions.OFFER_SET_CHECK_OUT_BEFORE_OR_ON_PREREQUISITE:
      return offerSetCheckOutBeforeOrOnPrerequisiteReducer(state, action);
    case Actions.OFFER_SET_CHECK_IN_ON_OR_AFTER_PREREQUISITE:
      return offerSetCheckInOnOrAfterPrerequisiteReducer(state, action);
    case Actions.OFFER_CLEAR_CHECK_IN_CHECK_OUT_PREREQUISITE:
      return offerClearCheckInCheckOutPrerequisiteReducer(state);

    case Actions.OFFER_SET_STEPPING_EVERY_X_NIGHTS_APPLICATION:
      return offerSetSteppingEveryXNightsApplicationReducer(state, action);
    case Actions.OFFER_SET_STEPPING_APPLY_TO_APPLICATION:
      return offerSetSteppingApplyToApplicationReducer(state, action);
    case Actions.OFFER_SET_STEPPING_MAXIMUM_NIGHTS_APPLICATION:
      return offerSetSteppingMaximumNightsApplicationReducer(state, action);
    case Actions.OFFER_SET_STEPPING_DISCOUNT_APPROACH_APPLICATION:
      return offerSetSteppingDiscountApproachApplicationReducer(state, action);
    case Actions.OFFER_CLEAR_ALL_STEPPING_APPLICATION:
      return offerClearAllSteppingApplicationReducer(state, action);

    case Actions.OFFER_SET_ACCOMMODATION_DISCOUNT_DISCOUNT_PERCENTAGE_APPLICATION:
      return offerSetAccommodationDiscountDiscountPercentageReducer(state, action);
    case Actions.OFFER_SET_ACCOMMODATION_DISCOUNT_GREEN_TAX_APPROACH_APPLICATION:
      return offerSetAccommodationDiscountGreenTaxApproachReducer(state, action);
    case Actions.OFFER_CLEAR_ALL_ACCOMMODATION_DISCOUNT_APPLICATION:
      return offerClearAllAccommodationDiscountReducer(state, action);
    case Actions.OFFER_ADD_ACCOMMODATION_DISCOUNT_DISCOUNT_APPLICATION:
      return offerAddAccomodationDiscountReducer(state, action);

    case Actions.OFFER_ADD_PRODUCT_DISCOUNT:
    case Actions.OFFER_REMOVE_PRODUCT_DISCOUNT:
    case Actions.OFFER_ADD_PRODUCT_TO_PRODUCT_DISCOUNT:
    case Actions.OFFER_REMOVE_PRODUCT_FROM_PRODUCT_DISCOUNT:
    case Actions.OFFER_UPDATE_PRODUCT_DISCOUNT:
    case Actions.OFFER_TOGGLE_PRODUCT_DISCOUNT_AGENAME:
    case Actions.OFFER_TOGGLE_PRODUCT_ON_PRODUCT_DISCOUNT:
    case Actions.OFFER_TOGGLE_AGE_NAME_ON_PRODUCT:
      return {
        ...state,
        productDiscounts: productDiscountsReducer(state.productDiscounts, action),
      };

    case Actions.OFFER_ADD_SUB_PRODUCT_DISCOUNT:
    case Actions.OFFER_REMOVE_SUB_PRODUCT_DISCOUNT:
    case Actions.OFFER_ADD_PRODUCT_TO_SUB_PRODUCT_DISCOUNT:
    case Actions.OFFER_REMOVE_PRODUCT_FROM_SUB_PRODUCT_DISCOUNT:
    case Actions.OFFER_UPDATE_SUB_PRODUCT_DISCOUNT:
    case Actions.OFFER_TOGGLE_SUB_PRODUCT_DISCOUNT_AGENAME:
    case Actions.OFFER_TOGGLE_PRODUCT_ON_SUB_PRODUCT_DISCOUNT:
    case Actions.OFFER_TOGGLE_AGE_NAME_ON_SUB_PRODUCT:
      return {
        ...state,
        subProductDiscounts: subProductDiscountsReducer(state.subProductDiscounts || {}, action),
      };

    case Actions.OFFER_ADD_STEPPING_APPLICATION:
      return {
        ...state,
        stepping: {
          // by default ‘last’ is selected if adding stepping
          discountApproach: ESteppingApproach.LAST,
        },
      };

    case Actions.OFFER_SET_PACKAGE_DISCOUNT_STACKING:
      return {
        ...state,
        packageDiscountStacking: action.packageDiscountStacking,
      };

    case PackageActions.OFFER_REMOVE_PACKAGE:
      // this is BEFORE we actually remove the package offer
      // so if we're doing a remove at this point, and we only have 1,
      // that means we're about to go to 0. in THAT case, we need to reset
      // packageDiscountStacking back to null
      if (state.packageDiscounts.length === 1) {
        state = {
          ...state,
          packageDiscountStacking: null,
        };
      }
    case PackageActions.OFFER_ADD_PACKAGE:
      // this is BEFORE we actually add the package offer
      // so if we're doing an add at this point, and we only have 0,
      // that means we're about to go to 1. that means we need to set packageDiscountStacking
      // to its default value = 'none'
      if (state.packageDiscounts.length === 0) {
        state = {
          ...state,
          packageDiscountStacking: EOfferUiPackageDiscountStacking.NONE,
        };
      }
    // note: we DON'T do a `return` or `break` here, on either of these, deliberately.
    // this is hacky, but we're stuck with this crazy reducer unless we do
    // loads of work to replace it

    case PackageActions.OFFER_ADD_PACKAGE:
    case PackageActions.OFFER_REMOVE_PACKAGE:
    case PackageActions.OFFER_SET_PACKAGE_DATE_RANGE:
    case PackageActions.OFFER_ADD_PACKAGE_ACCOMMODATION_PRODUCT:
    case PackageActions.OFFER_REMOVE_ACCOMMODATION_PRODUCT:
    case PackageActions.OFFER_SET_PACKAGE_ACCOMMODATION_PRODUCT:
    case PackageActions.OFFER_SET_PACKAGE_NIGHTS:
    case PackageActions.OFFER_SET_PACKAGE_RATE:
    case PackageActions.OFFER_SET_PACKAGE_EXTRA_NIGHT_RATE:
    case PackageActions.OFFER_ADD_PACKAGE_EXTRA_PERSON_RATE:
    case PackageActions.OFFER_REMOVE_PACKAGE_EXTRA_PERSON_RATE:
    case PackageActions.OFFER_SET_PACKAGE_EXTRA_PERSON_RATE:
    case PackageActions.OFFER_SET_PACKAGE_EXTRA_PERSON_RATE_AGENAME:
    case PackageActions.OFFER_ADD_ACCOMMODATON_PRODUCT_PACKAGE:
    case PackageActions.OFFER_REMOVE_ACCOMMODATON_PRODUCT_PACKAGE:
    case PackageActions.OFFER_SET_PACKAGE_EXTRA_PERSON_MAX:
      return {
        ...state,
        stepping: undefined,
        accommodationProductDiscount: undefined,
        prerequisites: {
          ...state.prerequisites,
          accommodationProducts: [],
          dates: [],
          stayLength: undefined,
        },
        packageDiscounts: packageDiscountsReducer(state.packageDiscounts, action),
      };
    case Actions.OFFER_SET_MEAL_PLAN_CATEGORY_PRE_REQ:
      let mealPlanReqs = state?.prerequisites?.requiredMealPlanCategories || [];
      if (mealPlanReqs.includes(action.mealPlanReq)) {
        mealPlanReqs.splice(
          mealPlanReqs.findIndex(m => m === action.mealPlanReq),
          1
        );
      } else {
        mealPlanReqs.push(action.mealPlanReq);
      }
      return {
        ...state,
        prerequisites: {
          ...state.prerequisites,
          requiredMealPlanCategories: [...mealPlanReqs],
        },
      };
    case Actions.OFFER_CLEAR_MEAL_PLAN_CATEGORY_PRE_REQS:
      return {
        ...state,
        prerequisites: {
          ...state.prerequisites,
          requiredMealPlanCategories: [],
        },
      };

    default:
      return state;
  }
};

export const offerHotelUuidChangeReducer = (state: IOfferUI, action: Actions.OfferHotelUuidChangeAction): IOfferUI => {
  return {
    ...state,
    hotelUuid: action.hotelUuid,
    prerequisites: {
      ...state.prerequisites,
      dates: [],
      accommodationProducts: null,
    },
  };
};

export const offerHotelUuidChangeSuccessReducer = (
  state: IOfferUI,
  action: Actions.OfferHotelUuidChangeSuccessAction
): IOfferUI => {
  return {
    ...state,
    accommodationProductDiscount: action.data.countryCode === 'MV' ? state.accommodationProductDiscount : undefined,
    hotel: {
      name: action.data.name,
      countryCode: action.data.countryCode,
    },
    packageDiscounts: [],
    productDiscounts: clearAllProductsFromDiscounts(state.productDiscounts || {}),
    subProductDiscounts: clearAllProductsFromDiscounts(state.subProductDiscounts || {}),
    prerequisites: {
      ...state.prerequisites,
      accommodationProducts: [],
    },
  };
};

export const offerNameChangeReducer = (state: IOfferUI, action: Actions.OfferNameChangeAction): IOfferUI => {
  return {
    ...state,
    name: action.offerName,
  };
};

export const offerTermsChangeReducer = (state: IOfferUI, action: Actions.OfferTermsChangeAction): IOfferUI => {
  return {
    ...state,
    termsAndConditions: action.offerTerms,
  };
};

export const offerFurtherInformationChangeReducer = (
  state: IOfferUI,
  action: Actions.OfferFurtherInformationChangeAction
): IOfferUI => {
  return {
    ...state,
    furtherInformation: action.offerFurtherInformation,
  };
};

export const offerAddStayBetweenPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferAddStayBetweenPrerequisiteAction
): IOfferUI => {
  return produce<IOfferUI>(state, draftState => {
    draftState.prerequisites.dates?.push({
      startDate: '',
      endDate: '',
    });
    return draftState;
  });
};

export const offerRemoveStayBetweenPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferRemoveStayBetweenPrerequisiteAction
): IOfferUI => {
  return produce<IOfferUI>(state, draftState => {
    draftState.prerequisites.dates?.splice(action.stayBetweenIndex, 1);
    return draftState;
  });
};

export const offerChangeStayBetweenPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferChangeStayBetweenPrerequisiteAction
): IOfferUI => {
  return produce<IOfferUI>(state, draftState => {
    action.datesArray.forEach((dates, index) => {
      const newDateRange = {
        startDate: dates[0],
        endDate: dates[dates.length - 1],
      } as IDateRange;

      if (draftState.prerequisites.dates) {
        draftState.prerequisites.dates[index] = newDateRange;
      }
    });

    return draftState;
  });
};

export const offerSetBooleanPrerequisitesReducer = (
  state: IOfferUI,
  action: Actions.OfferSetBooleanPrerequisiteAction
): IOfferUI => {
  return produce<IOfferUI>(state, draftState => {
    // if value is true or false, make sure we have a payload object, and then set the value inside it
    if (action.value !== null) {
      if (!draftState.prerequisites.payload) {
        draftState.prerequisites.payload = {};
      }

      draftState.prerequisites.payload[action.key] = action.value;
    }

    // if value is null and we HAVE that payload object, delete it from the object
    if (action.value === null && draftState.prerequisites.payload && action.key in draftState.prerequisites.payload) {
      delete draftState.prerequisites.payload[action.key];
    }

    // if we have a payload, but its now empty, remove payload itself
    if (draftState.prerequisites.payload && Object.keys(draftState.prerequisites.payload).length <= 0) {
      delete draftState.prerequisites.payload;
    }

    return draftState;
  });
};

export const offerSetPreDiscountReducer = (state: IOfferUI, action: Actions.OfferSetPreDiscountAction): IOfferUI => {
  return {
    ...state,
    preDiscount: action.value,
  };
};

export const offerSetCountryCodeReducer = (
  state: IOfferUI,
  action: Actions.OfferSetCountryCodePrerequisiteAction
): IOfferUI => {
  return produce(state, draftState => {
    if (draftState.prerequisites.countryCodes === undefined) {
      draftState.prerequisites.countryCodes = [];
    }

    if (action.value === true) {
      draftState.prerequisites.countryCodes.push(action.countryCode);
    } else if (action.value === false) {
      draftState.prerequisites.countryCodes = draftState.prerequisites.countryCodes.filter(
        cc => cc !== action.countryCode
      );
    }

    draftState.prerequisites.countryCodes = R.uniq(draftState.prerequisites.countryCodes);

    return draftState;
  });
};

export const offerClearAllCountryCodeReducer = (
  state: IOfferUI,
  action: Actions.OfferClearAllCountryCodePrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      countryCodes: [],
    },
  };
};

export const offerSetAccommodationProductPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferSetAccommodationProductPrerequisiteAction
): IOfferUI => {
  return produce(state, draftState => {
    if (!draftState.prerequisites.accommodationProducts) {
      draftState.prerequisites.accommodationProducts = [];
    }

    if (action.value === true) {
      draftState.prerequisites.accommodationProducts.push(action.accommodationProductUuid);
    } else if (action.value === false) {
      draftState.prerequisites.accommodationProducts = draftState.prerequisites.accommodationProducts.filter(
        cc => cc !== action.accommodationProductUuid
      );
    }

    draftState.prerequisites.accommodationProducts = R.uniq(draftState.prerequisites.accommodationProducts);

    return draftState;
  });
};

export const offerClearAllAccommodationProductPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferClearAllAccommodationProductPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      accommodationProducts: [],
    },
  };
};

export const offerSetAdvanceBookByPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferSetAdvanceBookByPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      advance: {
        ...state.prerequisites.advance,
        bookBy: action.value,
      },
    },
  };
};

export const offerSetAdvanceMinimumPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferSetAdvanceMinimumPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      advance: {
        ...state.prerequisites.advance,
        minimum: action.value,
      },
    },
  };
};

export const offerSetAdvanceMaximumPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferSetAdvanceMaximumPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      advance: {
        ...state.prerequisites.advance,
        maximum: action.value,
      },
    },
  };
};

export const offerClearAllAdvancePrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferClearAllAdvancePrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      advance: undefined,
    },
  };
};

export const offerSetMaxLodgingsPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferSetMaxLodgingsPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      maximumLodgingsInBooking: action.value,
    },
  };
};

export const offerSetStayLengthMinimumPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferSetStayLengthMinimumPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      stayLength: {
        ...state.prerequisites.stayLength,
        minimum: action.value,
      },
    },
  };
};

export const offerSetStayLengthMaximumPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferSetStayLengthMaximumPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      stayLength: {
        ...state.prerequisites.stayLength,
        maximum: action.value,
      },
    },
  };
};

export const offerSetStayLengthStrictPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferSetStayLengthStrictPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      stayLength: {
        ...state.prerequisites.stayLength,
        strictMinMaxStay: action.value,
      },
    },
  };
};

export const offerClearAllStayLengthPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferClearAllStayLengthPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      stayLength: undefined,
    },
  };
};

export const offerSetCheckOutBeforeOrOnPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferSetCheckOutBeforeOrOnPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      checkOutBeforeOrOn: action.value,
    },
  };
};

export const offerSetCheckInOnOrAfterPrerequisiteReducer = (
  state: IOfferUI,
  action: Actions.OfferSetCheckInOnOrAfterPrerequisiteAction
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      checkInOnOrAfter: action.value,
    },
  };
};

export const offerClearCheckInCheckOutPrerequisiteReducer = (
  state: IOfferUI,
): IOfferUI => {
  return {
    ...state,
    prerequisites: {
      ...state.prerequisites,
      checkInOnOrAfter: undefined,
      checkOutBeforeOrOn: undefined,
    },
  };
};

export const offerSetSteppingEveryXNightsApplicationReducer = (
  state: IOfferUI,
  action: Actions.OfferSetSteppingEveryXNightsApplicationAction
): IOfferUI => {
  return {
    ...state,
    stepping: {
      ...state.stepping,
      everyXNights: action.value != '' ? action.value : undefined,
    },
  };
};

export const offerSetSteppingApplyToApplicationReducer = (
  state: IOfferUI,
  action: Actions.OfferSetSteppingApplyToApplicationAction
): IOfferUI => {
  return {
    ...state,
    stepping: {
      ...state.stepping,
      applyTo: action.value != '' ? action.value : undefined,
    },
  };
};

export const offerSetSteppingMaximumNightsApplicationReducer = (
  state: IOfferUI,
  action: Actions.OfferSetSteppingMaximumNightsApplicationAction
): IOfferUI => {
  return {
    ...state,
    stepping: {
      ...state.stepping,
      maximumNights: action.value != '' ? action.value : undefined,
    },
  };
};

export const offerSetSteppingDiscountApproachApplicationReducer = (
  state: IOfferUI,
  action: Actions.OfferSetSteppingDiscountApproachApplicationAction
): IOfferUI => {
  return {
    ...state,
    stepping: {
      ...state.stepping,
      discountApproach: action.value,
    },
  };
};

export const offerClearAllSteppingApplicationReducer = (
  state: IOfferUI,
  action: Actions.OfferClearAllSteppingApplicationAction
): IOfferUI => {
  return {
    ...state,
    stepping: undefined,
  };
};

export const offerSetAccommodationDiscountDiscountPercentageReducer = (
  state: IOfferUI,
  action: Actions.OfferSetAccommodationDiscountDiscountPercentageAction
): IOfferUI => {
  return {
    ...state,
    accommodationProductDiscount: {
      ...state.accommodationProductDiscount,
      discountPercentage: action.value,
    },
  };
};

export const offerSetAccommodationDiscountGreenTaxApproachReducer = (
  state: IOfferUI,
  action: Actions.OfferSetAccommodationDiscountGreenTaxApproachAction
): IOfferUI => {
  return {
    ...state,
    accommodationProductDiscount: {
      ...state.accommodationProductDiscount,
      greenTaxDiscountApproach: action.value,
    },
  };
};

export const offerAddAccomodationDiscountReducer = (
  state: IOfferUI,
  action?: Actions.OfferAddAccommodationDiscountAction
): IOfferUI => {
  return {
    ...state,
    accommodationProductDiscount: {
      discountPercentage: undefined,
      greenTaxDiscountApproach: undefined,
    },
  };
};

export const offerClearAllAccommodationDiscountReducer = (
  state: IOfferUI,
  action?: Actions.OfferClearAllAccommodationDiscountAction
): IOfferUI => {
  return {
    ...state,
    accommodationProductDiscount: undefined,
  };
};
