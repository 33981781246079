import React, { Component } from 'react';
import * as uuid from 'uuid';

interface IErrorBoundaryState {
  hasError: boolean;
  error: any;
  id?: string;
}

export const ErrorBoundary = (WrappedComponent: Component) => {
  return class ErrorBoundary extends Component {
    state: IErrorBoundaryState = {
      hasError: false,
      error: {},
    };

    static getDerivedStateFromError(error: Error) {
      return { hasError: true, error, id: uuid.v4() };
    }

    renderError() {
      const { error, id } = this.state;

      return (
        <div className="flex items-center justify-center h-full flex-col">
          <h1>Something went wrong</h1>
          <pre className="max-h-[300px] overflow-auto">{error.toString()}</pre>
          <pre className="max-h-[300px] overflow-auto">Ref: {id}</pre>
        </div>
      );
    }

    render() {
      const { hasError } = this.state;

      if (hasError) {
        return this.renderError();
      }

      // @ts-ignore-line
      return <WrappedComponent {...this.props} />;
    }
  };
};

