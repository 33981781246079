import React, { Dispatch, SetStateAction, useLayoutEffect } from 'react';
import FluidButton from 'ui/FluidButton';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { useCurrentWidth } from 'effects';
import { useDispatch, useSelector } from 'react-redux';
import { RIGHT_COLUMN_BREAKPOINT } from './const';
import { ENetworkRequestStatus, ICompany, SelectedAccommodation } from 'services/BackendApi';
import * as ProposalsSelectors from 'store/modules/proposals/selectors';
import * as ProposalsSelectorsV2 from 'store/modules/proposalsList/selectors';
import { theme } from '../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';
import { HorizontalLine } from './components/HorizontalLine';
import { FourBookButtons } from './FourBookButtons';
import { isQuoteDownloadingSelector } from 'store/modules/bookingConfirmation/subdomains/quote/selectors';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { PaymentMethod } from './PaymentMethod';
import { InstantBookButton } from './InstantBookButton';
import { VerticalSpace } from 'ui/VerticalSpace';
import { useDepositAndCreditLimitInfo } from './hooks/useDepositAndCreditLimitInfo';
import { paymentMethodSelector } from 'store/modules/bookingConfirmation/subdomains/payment/selectors';
import { EPaymentMethod } from 'store/modules/bookingBuilder/types';
import { LoadingBar } from 'ui/NetworkStatusBar';
import * as BookingBuilderUtils from 'utils/bookingBuilder';
import { useTranslation } from 'react-i18next';
import { convertBasketBuildToBookingBuilderFormat } from './utils';
import { IBasketBuildL3, IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { getProposalListRequestAction } from 'store/modules/proposalsList/actions';
import { TCountryCode } from 'interfaces';

interface IButtonWithIconProps {
  className: string;
  caption: string;
  Icon: React.FC<IIconProps>;
  isLoading: boolean;
  onClick: () => void;
  disabled: boolean;
  company: ICompany;
}

export const ButtonWithIcon: React.FC<IButtonWithIconProps> = ({
  caption,
  Icon,
  isLoading,
  className,
  onClick,
  disabled,
}) => {
  const { currentWidth } = useCurrentWidth();
  return (
    <FluidButton type="secondary" className={className} onClick={onClick} isLoading={isLoading} disabled={disabled}>
      {currentWidth >= RIGHT_COLUMN_BREAKPOINT ? (
        <span className="relative top-[4px]">
          <Icon fill={disabled ? theme.colors['brown-40'] : theme.colors['brown-100']} />
        </span>
      ) : null}{' '}
      {caption}
    </FluidButton>
  );
};

interface IBookingButtonsProps {
  basketBuild: IBasketBuildL4 | null;
  className?: string;
  canBook: boolean;
  isRequestInProgress: boolean;
  setRequestInProgress: Dispatch<SetStateAction<boolean>>;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  isLiveRatePricesModalOpen: boolean;
  setIsLiveRatePricesModalOpen: Dispatch<SetStateAction<boolean>>;
  liveRatePricesModalData: any;
  setLiveRatePricesModalData: Dispatch<SetStateAction<any>>;
  onPostRemove: () => void;
  company: ICompany;
  clientCountryCode: TCountryCode | null;
}

export const BasketBookingButtons: React.FC<IBookingButtonsProps> = React.memo(
  ({
    basketBuild,
    canBook,
    isRequestInProgress,
    setRequestInProgress,
    setRedirectLocation,
    setShouldShowLeaveAlert,
    isLiveRatePricesModalOpen,
    setIsLiveRatePricesModalOpen,
    liveRatePricesModalData,
    setLiveRatePricesModalData,
    onPostRemove,
    company,
    clientCountryCode,
  }) => {
    if (!basketBuild) {
      return null;
    }

    const { t } = useTranslation();

    const bookingBuilderForBasket = convertBasketBuildToBookingBuilderFormat(basketBuild, true);

    const lodgingSummaries = BookingBuilderUtils.generateLodgingSummariesFromBooking(t, bookingBuilderForBasket);

    const {
      depositAccountBalanceCents,
      cannotGetDepositAccountBalance,
      creditLimitCents,
      isDefinedCreditLimit,
      isEnoughDepositAndCredit,
    } = useDepositAndCreditLimitInfo(bookingBuilderForBasket, company);

    const selectedPaymentMethod = useSelector(paymentMethodSelector);
    const guestInfo = useSelector(BookingBuilderSelectors.guestInfoSelector);
    const guestInfoFilledIn = guestInfo.guestFirstName?.trim() && guestInfo.guestLastName?.trim();
    const proposalStatus = useSelector(ProposalsSelectors.getProposalsStatus);
    const isProposalDownloading = useSelector(ProposalsSelectorsV2.requestPendingSelector);
    const isQuoteDownloading = useSelector(isQuoteDownloadingSelector);
    const isLoading =
      isQuoteDownloading ||
      isProposalDownloading ||
      isRequestInProgress ||
      proposalStatus === ENetworkRequestStatus.PENDING;
    const buttonsDisabled = !guestInfoFilledIn || !canBook || isLoading;

    const isTa: boolean = useSelector(AuthSelectors.isTA);
    const containsInstantBook = basketBuild.latestBookingBuilderResponse?.availableToInstantBook;

    const containsLiveRates =
      bookingBuilderForBasket.response.potentialBooking.Accommodation.some(requestedAccommodation => {
        return requestedAccommodation.isLiveRate;
      }) || false;
    const allStaticRates = !containsLiveRates && !containsInstantBook;
    const waitingForCompanyInfo = isTa && !company;

    if (waitingForCompanyInfo || isLoading) {
      return (
        <div className="mt-[45px] mb-[50px]">
          <LoadingBar />
        </div>
      );
    }

    const shouldShowBookNowOption =
      (containsLiveRates || containsInstantBook) && (!isTa || (isTa && company?.enableInstantBooking));

    const isBookNowButtonDisabled =
      (selectedPaymentMethod === EPaymentMethod.DEPOSIT_ACCOUNT && !isEnoughDepositAndCredit) ||
      (selectedPaymentMethod === EPaymentMethod.BANK_TRANSFER && !bookingBuilderForBasket.response.isRefundable);

    return (
      <div className="booking-buttons-container">
        {shouldShowBookNowOption && <HorizontalLine text="PAY NOW" />}
        {isTa && company?.enableInstantBooking && (containsLiveRates || containsInstantBook) && (
          <PaymentMethod
            bookingBuild={bookingBuilderForBasket}
            depositAccountBalanceCents={depositAccountBalanceCents}
            cannotGetDepositAccountBalance={cannotGetDepositAccountBalance}
            creditLimitCents={creditLimitCents}
            isDefinedCreditLimit={isDefinedCreditLimit}
            isEnoughDepositAndCredit={isEnoughDepositAndCredit}
            selectedPaymentMethod={selectedPaymentMethod}
          />
        )}
        {shouldShowBookNowOption && (
          <InstantBookButton
            bookingBuild={bookingBuilderForBasket}
            lodgingSummaries={lodgingSummaries}
            setShouldShowLeaveAlert={setShouldShowLeaveAlert}
            setRequestInProgress={setRequestInProgress}
            setRedirectLocation={setRedirectLocation}
            isLoading={isLoading}
            buttonsDisabled={isBookNowButtonDisabled || buttonsDisabled}
            cannotGetDepositAccountBalance={cannotGetDepositAccountBalance}
            taMarginAmountOverride={basketBuild.taMarginAmount}
            basketBuild={basketBuild}
            clientCountryCode={clientCountryCode}
          />
        )}
        {shouldShowBookNowOption && !containsLiveRates && <VerticalSpace height="30px" />}
        {(allStaticRates || (isTa && !company?.enableInstantBooking && containsInstantBook)) && (
          <HorizontalLine text="PAY LATER" />
        )}
        {((isTa && company?.enableInstantBooking) || !isTa) && containsInstantBook && (
          <HorizontalLine text="OR PAY LATER" />
        )}
        <FourBookButtons
          bookingBuild={bookingBuilderForBasket}
          lodgingSummaries={lodgingSummaries}
          isRequestInProgress={isRequestInProgress}
          setShouldShowLeaveAlert={setShouldShowLeaveAlert}
          setRequestInProgress={setRequestInProgress}
          setRedirectLocation={setRedirectLocation}
          canBook={canBook}
          isLoading={isLoading}
          isPrimaryButtonDisabled={containsLiveRates && isBookNowButtonDisabled}
          buttonsDisabled={buttonsDisabled}
          isLiveRatePricesModalOpen={isLiveRatePricesModalOpen}
          setIsLiveRatePricesModalOpen={setIsLiveRatePricesModalOpen}
          liveRatePricesModalData={liveRatePricesModalData}
          setLiveRatePricesModalData={setLiveRatePricesModalData}
          taMarginAmountOverride={basketBuild.taMarginAmount}
          basketBuild={basketBuild}
          onPostRemove={onPostRemove}
          company={company}
          clientCountryCode={clientCountryCode}
        />
      </div>
    );
  }
);
