import React from 'react';

import { PageContent } from 'containers';

export const ContentPage = ({
  match: {
    params: { pageId = undefined },
  },
}) => <PageContent pageId={pageId} />;

export default ContentPage;
