import React, { useEffect, useState } from 'react';
import { makeBackendApi } from 'services/BackendApi/BackendApi';
import {
  BookingBuilderResponse,
  ENetworkRequestStatus,
  IHotel,
  PotentialBooking,
  Upload,
} from 'services/BackendApi/types';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useApiRequest } from 'hooks/useApiRequest';
import { Link } from 'ui/Link';
import Helmet from 'react-helmet';
import { useRouteMatch } from 'react-router';
import * as _ from 'lodash-es';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';
import { BasketItem, eBasketItemRenderType } from 'containers/Basket/BasketItem';
import { IBasketBuildL2, IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { buildToBooking, getBookingConfirmationClipboardFormat } from 'containers/Basket/components/CopyButtonMultiple';

const pickHotelImage = (hotel: IHotel, uploads: Upload[]): Upload => {
  const hotelUploadsSet = uploads.filter(upload => upload.ownerUuid === hotel.uuid);
  let hotelPhoto = hotelUploadsSet.find(upload => upload.tag === 'featuredPhoto');
  if (!hotelPhoto) {
    const hotelPhotoSet = hotelUploadsSet.filter(upload => upload.tag === 'photo') || [];
    // Criteria: choose the most recent
    hotelPhotoSet.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    hotelPhoto = hotelPhotoSet[0];
  }
  return hotelPhoto;
};

const extractAndCollatePaymentTerms = (potentialBooking: PotentialBooking) => {
  if (!potentialBooking) {
    return [];
  }

  let allPaymentTerms: string[] = [];
  allPaymentTerms = allPaymentTerms.concat(potentialBooking.Accommodation.map(product => product.paymentTerms));
  allPaymentTerms = allPaymentTerms.concat(potentialBooking.Fine.map(product => product.paymentTerms));
  allPaymentTerms = allPaymentTerms.concat(potentialBooking['Ground Service'].map(product => product.paymentTerms));
  allPaymentTerms = allPaymentTerms.concat(potentialBooking.Supplement.map(product => product.paymentTerms));
  allPaymentTerms = allPaymentTerms.concat(potentialBooking.Transfer.map(product => product.paymentTerms));

  return _.flatten(_.uniq(allPaymentTerms)).filter(Boolean);
};

export const SharedLinkPage = () => {
  const match = useRouteMatch<{ sharedLinkUuid: string }>();
  const sharedLinkUuid = match.params.sharedLinkUuid;
  const backendApi = makeBackendApi();
  const dispatch = useDispatch();
  const getMainCompanyInfo = useApiRequest(backendApi.fetchMainCompanyInfo);
  const getSharedLinkContent = useApiRequest(backendApi.sharedLinkGetContent);
  const [hasAnyShareItemsExpired, setHasAnyShareItemsExpired] = useState(false);
  useEffect(() => {
    getMainCompanyInfo.execute();
    getSharedLinkContent.execute(sharedLinkUuid);
  }, []);

  const [basketBuilds, setBasketBuilds] = useState<IBasketBuildL4[]>([]);

  useEffect(() => {
    if (!getSharedLinkContent.response) {
      return;
    }

    let buildsWithPhotos: IBasketBuildL2[] = [];
    if (getSharedLinkContent.response.data.builds) {
      buildsWithPhotos = getSharedLinkContent.response.data.builds.map(build => {
        const hotelPhoto = pickHotelImage(build.initialBuildResponse.hotel, build.initialBuildResponse.uploads);
        const hotelUploadsSet = build.initialBuildResponse.uploads.filter(
          upload => upload.ownerUuid === build.initialBuildResponse.hotel.uuid
        );
        let hotelPhotos = hotelUploadsSet
          .filter(upload => ['photo', 'featuredPhoto'].includes(upload.tag))
          .filter(Boolean)
          .map(item => ({ displayName: item?.displayName, url: item?.url }));
        return {
          ...build,
          hotelPhotoUrl: hotelPhoto?.url,
          hotelPhotos,
          uploads: build.initialBuildResponse.uploads,
        };
      });
    }

    // for each of the basket builds, we need to do a booking builder request
    // so we have a latest booking builder data
    Promise.all(buildsWithPhotos.map(build => backendApi.sharedLinkGetItemLatestResponse(sharedLinkUuid, build.uuid)))
      .then(responses => {
        // @ts-ignore the type is wrong - there IS a double `data` here
        const bookingBuilderDatas = responses.map(r => r.data?.data) as BookingBuilderResponse[];

        const basketBuildsWithBookingBuilderResponses: IBasketBuildL4[] = buildsWithPhotos.map(
          (build, index): IBasketBuildL4 => {
            const response = bookingBuilderDatas[index];
            return {
              ...build,
              latestBookingBuilderResponse: response!,
            };
          }
        );

        setBasketBuilds(basketBuildsWithBookingBuilderResponses);
      })
      .catch(error => {
        if (error.response.status === 410) {
          setHasAnyShareItemsExpired(true);
        }
      });
  }, [getSharedLinkContent.response]);

  if (_.isNil(sharedLinkUuid)) {
    return (
      <div className="container w-1280px mx-auto pt-10">
        <ErrorBar message="No share link UUID provided" />
      </div>
    );
  }

  if (getMainCompanyInfo.response === undefined || getMainCompanyInfo.requestStatus === ENetworkRequestStatus.PENDING) {
    return (
      <div className="container w-1280px mx-auto">
        <LoadingBar />
      </div>
    );
  }

  if (getMainCompanyInfo.requestStatus === ENetworkRequestStatus.ERROR) {
    return (
      <div className="container w-1280px mx-auto">
        <ErrorBar />
      </div>
    );
  }

  const info = getMainCompanyInfo.response.data.data.info;
  const logo = getMainCompanyInfo.response.data.data.logo;

  return (
    <>
      <Helmet title="Options" />
      <div className="w-full flex flex-col space-y-40px">
        <div className="text-center shadow-pe1 py-4">
          <Link to={`/`}>
            <img
              className="mx-auto max-w-full md:max-w-[400px] max-h-[80px] px-4 md:px-0"
              src={`${logo.url}?isIcon=true`}
              alt={info.name}
            />
          </Link>
        </div>

        {/* a 410 means its expired */}
        {(getSharedLinkContent.status === 410 || hasAnyShareItemsExpired) && (
          <div className="text-black w-full px-4 m-0 md:container md:mx-auto md:w-1280px md:px-0 font-hurmegeometric-sans text-15px leading-28px text-center uppercase children:block">
            <span>This page is no longer available.</span>
            <span>The link has expired.</span>
            <span>Please, contact the sender of this link for further assistance.</span>
          </div>
        )}

        {/* a 200 is all good */}
        {(getSharedLinkContent.status === 200) === !hasAnyShareItemsExpired && (
          <div className="text-black w-full px-4 m-0 md:container md:mx-auto md:w-1280px md:px-0">
            <h1 className="font-noe-display my-0 md:my-[20px]">Proposals for you</h1>
            <span className="block font-hurmegeometric-sans mb-[35px]">
              We are delighted to present these specially selected resorts for your review:
            </span>

            {basketBuilds.length <= 0 && <LoadingBar />}
            {basketBuilds.map((build, index) => (
              <BasketItem
                key={build.uuid}
                build={build}
                isSelected={false}
                onSelectBuild={(uuid, newState) => {}}
                onEdit={() => {}}
                onDeleteBuild={uuid => {}}
                onUpdateBuildCommission={(buildUuid, taMarginAmount) => {}}
                actingOnBehalfOfUser={undefined}
                selectedTaUserUuid={null}
                basketItemRenderType={eBasketItemRenderType.SHARED_LINK}
                basketItemSharedTo={getSharedLinkContent.response?.data.shareType}
                onCopy={() => {
                  const booking = buildToBooking(build);
                  const taMarginAmount = parseFloat(build.taMarginAmount || '0');
                  const text = getBookingConfirmationClipboardFormat(
                    booking,
                    extractAndCollatePaymentTerms(build.initialBuildResponse.potentialBooking),
                    [], // deliberately blank
                    {
                      isFull: ['full-without-commission', 'full-with-commission'].includes('full-with-commission'),
                      withCommission: ['summary-with-commission', 'full-with-commission'].includes(
                        'full-with-commission'
                      ),
                      commissionPercentage: taMarginAmount,
                      humanReadableId: build.humanReadableId,
                    }
                  );

                  navigator.clipboard.writeText(text).then(() => {
                    dispatch(enqueueNotification({ message: 'Copied to clipboard', options: { variant: 'success' } }));
                  });
                }}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};
