import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  EHotelUploadTags,
  ENetworkRequestStatus,
  EUploadTag,
  IHotel,
  IUploadFileInfo,
  makeBackendApi,
} from 'services/BackendApi';
import { Link } from 'ui/Link';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { SimpleTabs } from 'ui/SimpleTabs';
import { produce } from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';
import { HotelDetailsTab } from './components/hotel-edit-tabs/HotelDetails';
import { AmenitiesTab } from './components/hotel-edit-tabs/AmenitiesTab';
import { HighlightsTab } from './components/hotel-edit-tabs/HighlightsTab';
import { OverviewTab } from './components/hotel-edit-tabs/OverviewTab';
import { PoliciesRestrictionsTab } from './components/hotel-edit-tabs/PoliciesAndRestrictions';
import { ContactDetailsTab } from './components/hotel-edit-tabs/ContactDetails';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';
import classNames from 'classnames';
import * as _ from 'lodash-es';
import { UploadModal } from '../HotelAdmin/components/UploadModal';
import { useModal } from 'hooks/useModal';

export const Edit = () => {
  const match = useRouteMatch<{ hotelUuid: string }>();
  const hotelUuid = match.params.hotelUuid;

  const backendApi = makeBackendApi();
  const dispatch = useDispatch();

  const [hotel, setHotel] = useState<IHotel | null>(null);
  const [filtersCategories, setFiltersCategories] = useState<any[]>([]);
  const [starRatings, setStarRatings] = useState<string[]>([]);
  const [draftHotel, setDraftHotel] = useState<IHotel | null>(null);

  const [getHotelRequest, setGetHotelRequest] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);
  const [patchHotelRequest, setPatchHotelRequest] = useState(ENetworkRequestStatus.IDLE);

  const [tabsWithChanges, setTabsWithChanges] = useState<string[]>([]);

  const bootstrapCountries = useSelector(BootstrapSelectors.getBootstrapCountriesSelector);

  const retrieveHotel = async () => {
    setGetHotelRequest(ENetworkRequestStatus.PENDING);
    try {
      const res = await backendApi.hotelAdminGetHotel(hotelUuid, ['uploads']);
      setGetHotelRequest(ENetworkRequestStatus.SUCCESS);
      setHotel(res.data.data);
      setDraftHotel(res.data.data);
    } catch (error) {
      setGetHotelRequest(ENetworkRequestStatus.ERROR);
      dispatch(
        enqueueNotification({
          message: `Hotel failed to load. Please check data and try again`,
          options: { variant: 'error' },
        })
      );
    }
  };

  const imageUploadModalData = useModal();
  const documentUploadModalData = useModal();

  // get the hotel
  useEffect(() => {
    retrieveHotel();
  }, [hotelUuid]);

  // get the options and filters and stuff
  useEffect(() => {
    backendApi
      .hotelAdminGetOptions()
      .then(res => {
        setFiltersCategories(res.data.data.filtersCategories.map(fc => fc.filters).flat());
        setStarRatings(res.data.data.starRatings);
      })
      .catch(error => {
        console.error('error', error);
        dispatch(
          enqueueNotification({
            message: `Failed to load options. Please check and refresh page`,
            options: { variant: 'error' },
          })
        );
      });
  }, []);

  if (getHotelRequest === ENetworkRequestStatus.ERROR) {
    return (
      <div className="container w-1280px mx-auto">
        <ErrorBar />
      </div>
    );
  }

  if (getHotelRequest === ENetworkRequestStatus.PENDING || !hotel || !draftHotel) {
    return (
      <div className="container w-1280px mx-auto">
        <LoadingBar />
      </div>
    );
  }

  const updateDraftHotelValue = (field, value) => {
    const newHotel = produce(draftHotel!, _draftHotel => {
      _draftHotel[field] = value;

      if (field === 'countryCode') {
        if (value !== 'MV') {
          _draftHotel.greenTaxIsStandardOccupancy = false;
        }
      }
    });
    setDraftHotel(newHotel);
  };

  const patchHotel = async (updatedData: Partial<IHotel>) => {
    setPatchHotelRequest(ENetworkRequestStatus.PENDING);
    try {
      // get a hotel with the matching hotelID
      const hotelByIdRes = await backendApi.getHotelByHotelId(draftHotel.hotelId!);
      // @ts-ignore
      const hotelById = hotelByIdRes.data.data?.[0];
      if (hotelById && hotelById.uuid !== hotel.uuid) {
        dispatch(
          enqueueNotification({
            message: 'Error patching hotel: a hotel with this hotelId already exists',
            options: { variant: 'warning' },
          })
        );
        setPatchHotelRequest(ENetworkRequestStatus.ERROR);
        return;
      }

      await backendApi.hotelAdminPatchHotel(hotel.uuid, updatedData);
      setPatchHotelRequest(ENetworkRequestStatus.SUCCESS);
      dispatch(
        enqueueNotification({
          message: `Hotel updated successfully`,
          options: { variant: 'success' },
        })
      );
    } catch (error) {
      console.error('error', error);
      setPatchHotelRequest(ENetworkRequestStatus.ERROR);
      dispatch(
        enqueueNotification({
          message: `Hotel failed to update. Please check data and try again`,
          options: { variant: 'error' },
        })
      );
    }
  };

  const setFeaturedPhoto = async (featuredPhoto: IUploadFileInfo) => {
    setPatchHotelRequest(ENetworkRequestStatus.PENDING);

    try {
      await backendApi.hotelAdminSetFeaturedPhoto(featuredPhoto);
      setPatchHotelRequest(ENetworkRequestStatus.SUCCESS);
      dispatch(
        enqueueNotification({
          message: `Featured Photo updated successfully`,
          options: { variant: 'success' },
        })
      );
      // then update the draft hotel so the right upload is marked as featured
      const updatedHotel = produce(hotel, draftHotel => {
        const originalFeaturedPhotoIndex = draftHotel.uploads?.findIndex(u => u.tag === EUploadTag.FEATURED_PHOTO);
        if (originalFeaturedPhotoIndex !== undefined) {
          draftHotel.uploads![originalFeaturedPhotoIndex].tag = EUploadTag.PHOTO;
        }
        const newFeaturedPhotoIndex = draftHotel.uploads?.findIndex(u => u.uuid === featuredPhoto.uuid);
        if (newFeaturedPhotoIndex !== undefined) {
          draftHotel.uploads![newFeaturedPhotoIndex].tag = EUploadTag.FEATURED_PHOTO;
        }
      });
      setHotel({
        ...updatedHotel,
      });
      setDraftHotel({
        ...updatedHotel,
      });
    } catch (error) {
      console.error('error', error);
      setPatchHotelRequest(ENetworkRequestStatus.ERROR);
      dispatch(
        enqueueNotification({
          message: `Failed to set feature photo. Please check and try again`,
          options: { variant: 'success' },
        })
      );
    }
  };

  const deleteUpload = async (upload: IUploadFileInfo) => {
    setPatchHotelRequest(ENetworkRequestStatus.PENDING);

    try {
      await backendApi.deleteUpload(upload.uuid);
      setPatchHotelRequest(ENetworkRequestStatus.SUCCESS);
      dispatch(
        enqueueNotification({
          message: `Upload deleted successfully`,
          options: { variant: 'success' },
        })
      );

      // remove the upload from the draft hotel
      const updatedHotel = produce(hotel, draftHotel => {
        draftHotel.uploads = draftHotel.uploads?.filter(u => u.uuid !== upload.uuid);
      });
      setHotel({
        ...updatedHotel,
      });
      setDraftHotel({
        ...updatedHotel,
      });
    } catch (error) {
      console.error('error', error);
      setPatchHotelRequest(ENetworkRequestStatus.ERROR);
      dispatch(
        enqueueNotification({
          message: `Failed to delete upload. Please check and try again`,
          options: { variant: 'success' },
        })
      );
    }
  };

  const handleUploadImage = async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('tag', data.tag);
    formData.append('displayName', data.name);

    formData.append('ownerUuid', hotel.uuid);
    formData.append('ownerType', 'Hotel');

    try {
      await backendApi.uploadFile(formData);
      await retrieveHotel();
      dispatch(
        enqueueNotification({
          message: `Upload successful`,
          options: { variant: 'success' },
        })
      );
    } catch (error) {
      console.error('error', error);
      dispatch(
        enqueueNotification({
          message: `Failed to upload image. Please check and try again`,
          options: { variant: 'error' },
        })
      );
    }
  };

  const handleUploadDocument = async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('tag', data.tag);
    formData.append('displayName', data.name);

    formData.append('ownerUuid', hotel.uuid);
    formData.append('ownerType', 'Hotel');

    try {
      await backendApi.uploadFile(formData);
      await retrieveHotel();
      dispatch(
        enqueueNotification({
          message: `Upload successful`,
          options: { variant: 'success' },
        })
      );
    } catch (error) {
      console.error('error', error);
      dispatch(
        enqueueNotification({
          message: `Failed to upload document. Please check and try again`,
          options: { variant: 'error' },
        })
      );
    }
  };

  return (
    <div className="container w-1280px mx-auto">
      <Link className="font-pt-sans underline hover:decoration-brown-prime" to="/hotel-admin">
        Back to Hotels
      </Link>
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">
        Products - <span className="text-[26px]">Hotels - Editing "{hotel.name}"</span>
      </h1>
      <Link
        className="font-pt-sans underline hover:decoration-brown-prime"
        to={`/hotel-admin/${hotel.uuid}/edit-children`}
      >
        Edit Hotel Children
      </Link>

      <SimpleTabs
        className={classNames({
          'pointer-events-none opacity-50':
            patchHotelRequest === ENetworkRequestStatus.PENDING || bootstrapCountries.length <= 0,
        })}
        tabConfig={[
          {
            title: 'Hotel Details',
            name: 'hotel-details',
            styles: 'w-[350px]',
            hasUnsavedChanges: tabsWithChanges.includes('hotel-details'),
            renderContent: () => (
              <HotelDetailsTab
                hotel={draftHotel!}
                onUpdate={(field, val) => {
                  updateDraftHotelValue(field, val);
                  setTabsWithChanges(_.uniq([...tabsWithChanges, 'hotel-details']));
                }}
                filtersCategories={filtersCategories}
                starRatings={starRatings}
                onCta={async data => {
                  await patchHotel(data);
                  setTabsWithChanges(_.uniq(_.without(tabsWithChanges, 'hotel-details')));
                }}
                networkRequest={patchHotelRequest}
                setFeaturedPhoto={setFeaturedPhoto}
                deleteUpload={deleteUpload}
                mode="edit"
                ctaLabel="Update Hotel Details"
                onUploadImage={async () => {
                  const data = await imageUploadModalData.openModal();
                  if (data) {
                    handleUploadImage(data);
                  }
                }}
                onUploadDocument={async () => {
                  const data = await documentUploadModalData.openModal();
                  if (data) {
                    handleUploadDocument(data);
                  }
                }}
              />
            ),
          },
          {
            title: 'Amenities',
            name: 'amenities',
            styles: 'w-[150px]',
            hasUnsavedChanges: tabsWithChanges.includes('amenities'),
            renderContent: () => (
              <AmenitiesTab
                hotel={draftHotel}
                onUpdate={(field, val) => {
                  updateDraftHotelValue(field, val);
                  setTabsWithChanges(_.uniq([...tabsWithChanges, 'amenities']));
                }}
                onPatchHotel={async data => {
                  await patchHotel(data);
                  setTabsWithChanges(_.uniq(_.without(tabsWithChanges, 'amenities')));
                }}
                patchingRequest={patchHotelRequest}
              />
            ),
          },
          {
            title: 'Highlights',
            name: 'highlights',
            styles: 'w-[150px]',
            hasUnsavedChanges: tabsWithChanges.includes('highlights'),
            renderContent: () => (
              <HighlightsTab
                hotel={draftHotel!}
                onUpdate={(field, val) => {
                  updateDraftHotelValue(field, val);
                  setTabsWithChanges(_.uniq([...tabsWithChanges, 'highlights']));
                }}
                onPatchHotel={async data => {
                  await patchHotel(data);
                  setTabsWithChanges(_.uniq(_.without(tabsWithChanges, 'highlights')));
                }}
              />
            ),
          },
          {
            title: 'Overview',
            name: 'overview',
            styles: 'w-[150px]',
            hasUnsavedChanges: tabsWithChanges.includes('overview'),
            renderContent: () => (
              <OverviewTab
                hotel={draftHotel!}
                onUpdate={(field, val) => {
                  updateDraftHotelValue(field, val);
                  setTabsWithChanges(_.uniq([...tabsWithChanges, 'overview']));
                }}
                onPatchHotel={async data => {
                  await patchHotel(data);
                  setTabsWithChanges(_.uniq(_.without(tabsWithChanges, 'overview')));
                }}
              />
            ),
          },
          {
            title: 'Policies & Restrictions',
            name: 'policies-restrictions',
            styles: 'w-[450px]',
            hasUnsavedChanges: tabsWithChanges.includes('policies-restrictions'),
            renderContent: () => (
              <PoliciesRestrictionsTab
                hotel={draftHotel!}
                onUpdate={(field, val) => {
                  updateDraftHotelValue(field, val);
                  setTabsWithChanges(_.uniq([...tabsWithChanges, 'policies-restrictions']));
                }}
                onPatchHotel={async data => {
                  await patchHotel(data);
                  setTabsWithChanges(_.uniq(_.without(tabsWithChanges, 'policies-restrictions')));
                }}
              />
            ),
          },
          {
            title: 'Contact Details',
            name: 'contact-details',
            styles: 'w-[350px]',
            hasUnsavedChanges: tabsWithChanges.includes('contact-details'),
            renderContent: () => (
              <ContactDetailsTab
                hotel={draftHotel!}
                onUpdate={(field, val) => {
                  updateDraftHotelValue(field, val);
                  setTabsWithChanges(_.uniq([...tabsWithChanges, 'contact-details']));
                }}
                onPatchHotel={async data => {
                  await patchHotel(data);
                  setTabsWithChanges(_.uniq(_.without(tabsWithChanges, 'contact-details')));
                }}
              />
            ),
          },
        ]}
      />

      {imageUploadModalData.isOpen && (
        <UploadModal
          onConfirm={imageUploadModalData.handleConfirm}
          onClose={imageUploadModalData.handleCancel}
          tags={[
            { value: EUploadTag.PHOTO, label: 'Photo' },
            { value: EUploadTag.FEATURED_PHOTO, label: 'Featured Photo' },
          ]}
          acceptString="image/png, image/gif, image/jpeg"
        />
      )}

      {documentUploadModalData.isOpen && (
        <UploadModal
          onConfirm={documentUploadModalData.handleConfirm}
          onClose={documentUploadModalData.handleCancel}
          tags={[
            { value: EUploadTag.FLOOR_PLAN, label: 'Floor Plan' },
            { value: EUploadTag.BROCHURE, label: 'Brochure' },
          ]}
        />
      )}
    </div>
  );
};
