import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { PriceAsCentsInput } from '../stateful/PriceAsCentsInput';
import { IHeadlineLineItemBreakdownLineItem } from 'services/BookingManagerApi';
import { toClassName } from './helpers';
import { HidingTooltip } from 'ui/Tooltip';
import InfoIcon from 'ui/Icons/components/Info.component';
import { SvgIcon } from 'ui/SvgIcon';
import { ClosingProductsRow } from './ClosingProductsRow';
import { RemoveHeadlineButton } from './HeadlineActionButtons';
import { EmptyHeaderProducts } from './EmptyHeaderProducts';
import { isNil } from 'lodash-es';

interface IProductBlock {
  pathPrefix?: string;
  className?: string;
  productType: string;
  lineItems: IHeadlineLineItemBreakdownLineItem[];
  bookingCurrencySymbol?: string | null;
  subTotalCents: number;
  includeTableHeaders?: boolean;
  allowNegative?: boolean;
  setHeadlineLineItemFieldAction: (setPath: string, value: string) => void;
  removeHeadlineLineItemAction: (removePath: string, index: number) => void;
  isPurchaseCostEditable: boolean;
  titleJSX: React.ReactElement;
  isEditable: boolean;
  cta?: React.ReactElement;
  isSR: boolean;
  isAdmin: boolean;
  isFinanceUser: boolean;
  isLiveRate?: boolean;
  titlePlaceholder?: string;
  tertiaryTextPlaceholder?: string;
  isAccommodationSubBlock?: boolean; // if this is true, we need to fire the accommodation modified when user changes prices
  isSalesCostEditable?: boolean;
}
import { theme } from '../../../tailwind.config';

// necessary because we dont have placeholder utility classes
const LineItemInput = styled.input`
  &::placeholder {
    font-style: italic;
  }
`;

export const ProductsBlock = (props: IProductBlock) => {
  const {
    titleJSX,
    pathPrefix = '',
    className = '',
    lineItems,
    includeTableHeaders = false,
    productType,
    subTotalCents,
    allowNegative,
  } = props;

  const isEditable = props.isEditable && !props.isLiveRate;
  const isPurchaseCostVisible = props.isSR || props.isFinanceUser;
  const isPurchaseCostDisabled = !props.isPurchaseCostEditable;

  const isSalesCostEditable = isNil(props.isSalesCostEditable) ? props.isEditable : props.isSalesCostEditable;

  const dispatch = useDispatch();

  return (
    <div className={`product ${toClassName(props.productType)} ${className}`} key={props.productType}>
      <table className="w-full">
        <thead>
          <tr>
            <th className="title p-0 w-1/4">{titleJSX}</th>
            {!includeTableHeaders && <EmptyHeaderProducts />}
            {includeTableHeaders && lineItems.length >= 1 && (
              <React.Fragment>
                <th className=""></th>
                {isPurchaseCostVisible && (
                  <th className="cost-before-discount w-14% text-gray-darker text-xs uppercase text-right pr-2 font-pt-sans">
                    Purchase Cost
                  </th>
                )}
                <th
                  colSpan={isPurchaseCostVisible ? 1 : 2}
                  className="cost-to-ta w-14% text-gray-darker text-xs uppercase text-right pr-4 font-pt-sans"
                >
                  Cost to TP
                </th>
              </React.Fragment>
            )}
            {isPurchaseCostVisible && <th></th>}
          </tr>
        </thead>

        <tbody>
          {lineItems.length <= 0 && (
            <tr>
              <td className="p-0 pt-2 pb-4"></td>
            </tr>
          )}
          {lineItems.length >= 1 &&
            lineItems.map((lineItem, index) => {
              const keyPrefix = Math.random();
              return (
                <tr key={index}>
                  <td className="p-0 pr-2 pb-4 pt-2">
                    <LineItemInput
                      disabled={isEditable === false}
                      className={classnames(
                        'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
                        {
                          'bg-gray-10': isEditable === false,
                        }
                      )}
                      value={lineItem.title}
                      placeholder={props.titlePlaceholder || 'Line Item Title'}
                      onChange={event => {
                        props.setHeadlineLineItemFieldAction(
                          `${pathPrefix}${productType}.items[${index}].title`,
                          event.target.value
                        );
                      }}
                    />
                  </td>
                  <td colSpan={isPurchaseCostVisible ? 1 : 2} className="pl-2 pr-2 pb-4 pt-2">
                    <LineItemInput
                      disabled={isEditable === false}
                      className={classnames(
                        'focus:outline-gray-80 text-black details border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
                        {
                          'bg-gray-10': isEditable === false,
                        }
                      )}
                      value={lineItem.tertiaryText}
                      placeholder={props.tertiaryTextPlaceholder || 'Line Item Description'}
                      onChange={event => {
                        props.setHeadlineLineItemFieldAction(
                          `${pathPrefix}${productType}.items[${index}].tertiaryText`,
                          event.target.value
                        );
                      }}
                    />
                  </td>
                  {isPurchaseCostVisible && (
                    <td className="w-14% p-0 pl-2 pr-2 pb-4 pt-2">
                      {/* cost before discount price */}
                      <PriceAsCentsInput
                        disabled={isPurchaseCostDisabled}
                        allowNegative={allowNegative}
                        key={`${keyPrefix}-${index}-purchaseCostCents`}
                        className={classnames(
                          'focus:outline-gray-80 bg-blue-10  text-black cost-before-purchase-cost border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
                          {
                            'bg-gray-10': props.isPurchaseCostEditable === false,
                          }
                        )}
                        cents={lineItem.purchaseCostCents || 0}
                        onBlurUpdate={val => {
                          props.setHeadlineLineItemFieldAction(
                            `${pathPrefix}${productType}.items[${index}].purchaseCostCents`,
                            val
                          );
                        }}
                        onChange={e => {
                          if (props.isAccommodationSubBlock) {
                            dispatch(BreakdownActions.setIsAccommodationModifiedAction(true));
                          }
                        }}
                      />
                    </td>
                  )}
                  <td className="w-14% p-0 pl-2 pb-4 pt-2">
                    <PriceAsCentsInput
                      disabled={!isSalesCostEditable}
                      allowNegative={allowNegative}
                      key={`${keyPrefix}-${index}-saleCostCents`}
                      className={classnames(
                        'focus:outline-gray-80 text-black cost-to-ta border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
                        {
                          'bg-gray-10': !isSalesCostEditable,
                        }
                      )}
                      cents={lineItem.saleCostCents || 0}
                      onBlurUpdate={val => {
                        props.setHeadlineLineItemFieldAction(
                          `${pathPrefix}${productType}.items[${index}].saleCostCents`,
                          val
                        );
                      }}
                      onChange={e => {
                        if (props.isAccommodationSubBlock) {
                          dispatch(BreakdownActions.setIsAccommodationModifiedAction(true));
                        }
                      }}
                    />
                  </td>
                  {isEditable === true && (
                    <td className="w-[65px]">
                      <span className="block -mt-2 float-right">
                        <RemoveHeadlineButton
                          action={props.removeHeadlineLineItemAction}
                          pathPrefix={pathPrefix}
                          productType={productType}
                          index={index}
                        />
                      </span>
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
      <ClosingProductsRow
        productDisplayType={productType}
        subTotalCents={subTotalCents}
        bookingCurrencySymbol={props.bookingCurrencySymbol}
        cta={props.cta}
        hasItems={lineItems.length >= 1}
        isEditable={props.isEditable}
      />
    </div>
  );
};

export const FinanceAdjustmentProductsBlock = (props: IProductBlock) => {
  const {
    titleJSX,
    pathPrefix = '',
    className = '',
    lineItems,
    includeTableHeaders = false,
    productType,
    subTotalCents,
    allowNegative,
  } = props;

  const isEditable = props.isEditable && !props.isLiveRate;
  const isPurchaseCostVisible = props.isSR || props.isFinanceUser;
  const isPurchaseCostDisabled = !props.isPurchaseCostEditable || !isEditable;

  let isSalesCostEditable = props.isEditable;
  if (props.isLiveRate) {
    isSalesCostEditable = isSalesCostEditable && (props.isAdmin || props.isSR);
  }

  return (
    <div className={`product ${toClassName(props.productType)} ${className}`} key={props.productType}>
      <table className="w-full">
        <thead>
          <tr>
            <th className="title p-0 flex space-x-2 items-center">
              <span className="w-[150px]">{titleJSX}</span>
              <HidingTooltip
                renderTooltipContent={() => (
                  <p className="font-normal w-[350px] p-1 text-13px">
                    Only visible for Internal Users.
                    <br />
                    Only affect purchase cost (not included in invoices). <br />
                    Automatically linked to Finance Purchase information.
                  </p>
                )}
                position="bottom-right"
              >
                <span className="flex">
                  <SvgIcon
                    IconComponent={InfoIcon}
                    defaultFill={theme.colors['gray-40']}
                    hoverFill={theme.colors['gray-80']}
                    activeFill={theme.colors['gray-40']}
                    width="18px"
                    height="18px"
                  />
                </span>
              </HidingTooltip>
            </th>
            {!includeTableHeaders && (
              <React.Fragment>
                <th className=""></th>
                <th className="w-14% text-gray-darker text-xs"></th>
                <th className="w-14% text-gray-darker text-xs"></th>
              </React.Fragment>
            )}
            {includeTableHeaders && lineItems.length >= 1 && (
              <React.Fragment>
                <th className=""></th>
                {isPurchaseCostVisible && (
                  <th className="cost-before-discount w-14% text-gray-darker text-xs uppercase text-right pr-2 font-pt-sans">
                    Purchase Cost
                  </th>
                )}
                <th
                  colSpan={isPurchaseCostVisible ? 1 : 2}
                  className="cost-to-ta w-14% text-gray-darker text-xs uppercase text-right pr-4 font-pt-sans"
                >
                  Cost to TP
                </th>
              </React.Fragment>
            )}
            {isPurchaseCostVisible && <th></th>}
          </tr>
        </thead>

        <tbody>
          {lineItems.length <= 0 && (
            <tr>
              <td className="p-0 pt-2 pb-4"></td>
            </tr>
          )}
          {lineItems.length >= 1 &&
            lineItems.map((lineItem, index) => {
              const keyPrefix = Math.random();
              return (
                <tr key={index}>
                  <td className="p-0 pr-2 pb-4 pt-2 w-1/4">
                    <LineItemInput
                      disabled={true}
                      className={classnames(
                        'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
                        {
                          'bg-gray-10': true,
                        }
                      )}
                      value={'Breakdown Finance Adjustment'}
                      placeholder={props.titlePlaceholder || 'Line Item Title'}
                      onChange={event => {
                        props.setHeadlineLineItemFieldAction(
                          `${pathPrefix}${productType}.items[${index}].title`,
                          event.target.value
                        );
                      }}
                    />
                  </td>
                  <td colSpan={1} className="pl-2 pr-2 pb-4 pt-2">
                    <LineItemInput
                      disabled={true}
                      className={classnames(
                        'focus:outline-gray-80 text-black details border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
                        {
                          'bg-gray-10': true,
                        }
                      )}
                      value={lineItem.title}
                      placeholder={'Breakdown Finance Adjustment'}
                      onChange={event => {
                        props.setHeadlineLineItemFieldAction(
                          `${pathPrefix}${productType}.items[${index}].tertiaryText`,
                          event.target.value
                        );
                      }}
                    />
                  </td>

                  <td className="w-14% p-0 pl-2 pr-2 pb-4 pt-2">
                    {/* cost before discount price */}
                    <PriceAsCentsInput
                      disabled={true}
                      allowNegative={allowNegative}
                      key={`${keyPrefix}-${index}-purchaseCostCents`}
                      className={classnames(
                        'focus:outline-gray-80 bg-blue-10  text-black cost-before-purchase-cost border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
                        {
                          'bg-gray-10': props.isPurchaseCostEditable === false,
                        }
                      )}
                      cents={lineItem.purchaseCostCents || 0}
                      onBlurUpdate={val => {
                        props.setHeadlineLineItemFieldAction(
                          `${pathPrefix}${productType}.items[${index}].purchaseCostCents`,
                          val
                        );
                      }}
                    />
                  </td>
                  <td className="w-14% p-0 pl-2 pr-2 pb-4 pt-2">{/* deliberately blank */}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
