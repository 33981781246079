import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { FluidButton } from '../FluidButton';
import { ClickOutside } from '../ClickOutside';

export interface IOption {
  key: string;
  label: string;
  handler: () => void;
}

export interface IMenuButtonProps {
  className?: string;
  innerClassName?: string;
  optionsClassName?: string;
  onClick: () => void;
  isOpen: boolean;
  options: IOption[];
  onSelect: (IOption) => void;
  onClickOutside?: (event) => void;
  fixedWidth?: string;
  isLoading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  rounded?: boolean;
}

export const MenuButton: React.FC<IMenuButtonProps> = React.memo(props => {
  const handleClickOutside = useCallback(event => props.onClickOutside && props.onClickOutside(event), [
    props.onClickOutside,
  ]);

  return (
    <ClickOutside
      className={classNames(props.className, 'menu-button inline-block')}
      onClickOutside={handleClickOutside}
    >
      <div className="relative">
        <ArrowButton
          onClick={props.onClick}
          isLoading={props.isLoading}
          disabled={props.disabled}
          fixedWidth={props.fixedWidth}
          rounded={props.rounded}
          className={props.innerClassName}
        >
          {props.children}
        </ArrowButton>
        {props.isOpen && (
          <div className={classNames("options absolute w-full mt-5px", props.optionsClassName)}>
            <Menu options={props.options} onSelect={props.onSelect} />
          </div>
        )}
      </div>
    </ClickOutside>
  );
});

interface IArrowButtonProps {
  className?: string;
  onClick: () => void;
  fixedWidth?: string;
  isLoading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  rounded?:boolean;
}

const ArrowButton: React.FC<IArrowButtonProps> = React.memo(props => (
  <FluidButton
    className={props.className}
    onClick={props.onClick}
    fixedWidth={props.fixedWidth}
    isLoading={props.isLoading}
    disabled={props.disabled}
    type="secondary"
    rounded={props.rounded}
  >
    <span className='flex'>
      {props.children}
      <span className="mx-10px font-normal text-brown-40">|</span>
      <i className="fas fa-chevron-down relative top-1px" />
    </span>
  </FluidButton>
));

export interface IMenuProps {
  className?: string;
  options: IOption[];
  onSelect: (IOption) => void;
}

const Menu: React.FC<IMenuProps> = React.memo(props => (
  <div
    className={classNames(
      props.className,
      'menu cursor-pointer border border-solid border-light-gray bg-white-hard font-pt-sans text-sm text-black drop-shadow-pe1'
    )}
  >
    {props.options.map(x => (
      <div className="item px-3 py-2 hover:bg-teal-40" key={x.key} onClick={() => props.onSelect(x)}>
        {x.label}
      </div>
    ))}
  </div>
));
