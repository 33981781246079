import React from 'react';
import { useSelector } from 'react-redux';
import { callWelcomeServerErrorMessageSelector } from 'store/modules/cognito/selectors';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { REDIRECT_COGNITO_URI_IF_LOCALHOST } from 'config';

export const CognitoFlowError = () => {
  // Hard coded on purpose, not in PS. If we change, coding must be changed.
  const COGNITO_EXPECTED_CALLBACK_TYPE = 'code';
  const { dynamicParameters, dynamicParametersAreEmpty } = useDynamicParameters();
  const callWelcomeServerErrorMessage = useSelector(callWelcomeServerErrorMessageSelector);
  if (dynamicParametersAreEmpty) {
    return (
      <div className="mt-[100px]">
        <LoadingBar />
      </div>
    );
  }
  return (
    <div>
      <h1>Login error</h1>
      <h2 style={{ color: 'red' }}>{callWelcomeServerErrorMessage}</h2>
      <br></br>
      <a
        href={`${dynamicParameters.COGNITO_DOMAIN}/login?client_id=${
          dynamicParameters.COGNITO_CLIENT_ID
        }&response_type=${COGNITO_EXPECTED_CALLBACK_TYPE}&scope=email+openid&redirect_uri=${
          location.host === 'localhost:8080'
            ? REDIRECT_COGNITO_URI_IF_LOCALHOST
            : dynamicParameters.COGNITO_CALLBACK_FOR_SIGNINUP
        }`}
      >
        Go to Sign in page
      </a>
    </div>
  );
};
