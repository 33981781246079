import DateRangeInput from "pureUi/DateRangeInput";
import { DatePickerStateProvider, IDatePickerSateParams } from "pureUi/providers/DatePickerStateProvider";
import React, { useCallback } from "react";
import { GuestAgesPicker } from "ui/GuestAgesPicker/GuestAgesPicker";
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { useDispatch } from "react-redux";
import { IEditAccommodationErrors } from "store/modules/bookingManager/subdomains/breakdown/model";

interface IPeopleAndDatesInputsProps {
  guestAges: {
    numberOfAdults: number;
    agesOfAllChildren?: number[];
  };
  selectedDates: string[];
  disabled?: boolean;
}

export const PeopleAndDatesInputs: React.FC<IPeopleAndDatesInputsProps> = ({ guestAges, disabled, selectedDates }) => {
  const dispatch = useDispatch();

  const handleCloseGuestAgesPicker = useCallback((visible: boolean) => {
    if (visible) {
      dispatch(BreakdownActions.editAccommodationBookingBuildRequestAction());
    }
  }, [dispatch]);

  const handleSetGuestAges = useCallback((numberOfAdults: number, agesOfAllChildren: number[]) => {
    dispatch(BreakdownActions.editAccommodationSetGuestAgesAction({
      numberOfAdults,
      agesOfAllChildren: agesOfAllChildren || []
    }));
  }, [dispatch])

  const handleEditAccommodationDateRangeChange = useCallback((dates: string[]) => {
    dispatch(BreakdownActions.editAccommodationDateRangeChangeAction(dates));
    dispatch(BreakdownActions.editAccommodationBookingBuildRequestAction());
  }, [dispatch]);

  return (
    <div className="people-and-dates-inputs flex gap-5">
      <label className="text-xs block mb-6 text-gray-500 w-full">
        <span className="block mb-2 font-bold">People *</span>
        <GuestAgesPicker
          numberOfAdults={guestAges.numberOfAdults}
          agesOfAllChildren={guestAges.agesOfAllChildren}
          onUpdate={handleSetGuestAges}
          maxChildAge={16}
          onClose={handleCloseGuestAgesPicker}
          disabled={disabled}
        />
      </label>
      <label className="text-xs block mb-6 text-gray-500 w-full">
        <span className="block mb-2 font-bold">Dates *</span>
        <DatePickerStateProvider
          defaultSelectedDates={selectedDates}
          onDateChange={handleEditAccommodationDateRangeChange}
          render={(params: IDatePickerSateParams) => (
            <DateRangeInput
              displayString={params.displayString}
              currentDate={params.datePickerCurrentDate}
              totalNights={params.totalNights}
              selectedDates={params.selectedDates}
              onDayClick={params.handleDayClick}
              onDayMouseOver={params.handleDateMouseOver}
              showDatePicker={params.showDatePicker}
              onNextClick={params.incrementDate}
              onPrevClick={params.decrementDate}
              onMouseDown={params.toggleDatePicker}
              onClickOutside={params.hideDatePicker}
              placeholder={'Select Dates...'}
              showTotalNights
              shortNightsVariant
              noPortal
              disabled={disabled}
            />
          )}
        />
      </label>
    </div>
  )
};
