import React, { FormEvent } from 'react';
import { Link } from 'ui/Link';

import classNames from 'classnames';
import { Heading2 } from 'styles';
import { bindActionCreators, Dispatch, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import format from 'date-fns/format';
import { formatDateDisplay, formatPrice } from 'utils';
import {
  BookingSearchItem,
  EBookingStatus,
  EPurchaseCostReviewStatus,
  ERateSource,
} from 'services/BookingManagerApi/types';
import { isTA, getCurrentUserUuid, getCurrentUserType, isSr, isCompanyBookingManager } from 'store/modules/auth';

import { Pagination } from 'pureUi/Pagination';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';

import BookingStatus, { IndicatorByStatus as BookingStatusIndicator } from 'pureUi/BookingStatus';
import FluidButton from 'ui/FluidButton';
import Checkbox from 'ui/Checkbox';
import { HidingTooltip } from 'ui/Tooltip';
import { FormLabel } from 'ui/FormLabel';
import { StyledTextInput, StyledDateRangeInput } from './StyledFilters';
import { BookingListProductTypesFilter, DateRangeTypes, ExportType } from 'store/modules/bookingsList/model';
import * as BookingListSelectors from 'store/modules/bookingsList/selectors';
import * as BookingListActions from 'store/modules/bookingsList/actions';
import { getCompaniesRequestAction } from 'store/modules/agents/actions';
import { companySelectOptionsSelector } from 'store/modules/agents/selectors';
import { getBootstrapCountriesSelector } from 'store/modules/bootstrap/selectors';
import { IValueLabelPair } from 'interfaces';
import OwnerIcon from 'ui/Icons/owner.component.svg';

import { BookingListStylesWrapper } from './BookingListStylesWrapper';
import { theme } from '../../../tailwind.config';
import { Poller } from '../Poller';
import {
  hasPermissions,
  FILTER_BY_COMPANY_PERMISSIONS,
  FILTER_BY_CONTACT_PERMISSIONS,
  USE_ADDITIONAL_FILTERS_PERMISSIONS,
  GROUND_TEAM_EXPORT_PERMISSIONS,
  FINANCE_TEAM_EXPORT_PERMISSIONS,
} from './permissions';
import { EUserType } from 'services/BackendApi';
import { Multiselect } from 'ui/Multiselect';
import { Contacts } from './Contacts';
import { bookingTypeOptions } from 'containers/helpers';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { PageTitle } from 'ui/PageTitle';
import { GoToDetails } from 'ui/GoToDetails';
import PredictiveTextInput from 'pureUi/PredictiveTextInput';
import { escapeStringRegexp } from 'utils/string';

const EMPTY_SELECT_VALUE = 'empty';

const renderFullName = (title, firstName, lastName) => {
  let preName = '';
  const nameChunks: string[] = [];
  if (title) {
    preName = `${title}. `;
  }
  if (firstName) {
    nameChunks.push(firstName);
  }
  if (lastName) {
    nameChunks.push(lastName);
  }

  return `${preName}${nameChunks.join(' ')}`;
};

export class BookingListContainer extends React.Component<IBookingListProps, { bookings: BookingSearchItem[] | null, showCompanyDropdown: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      bookings: null,
      showCompanyDropdown: false,
    };
  }

  bookingStatusOptions: IValueLabelPair[] = [
    { value: EMPTY_SELECT_VALUE, label: 'All Statuses' },
    { value: 'enquiry', label: 'Enquiry' },
    { value: 'requested', label: 'Requested' },
    { value: 'confirmed', label: 'Confirmed' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'completed', label: 'Completed' },
    { value: 'discarded', label: 'Discarded' },
  ];

  pcrStatusOptions: ISingleSelectOption[] = [
    { value: '', label: 'All Purchase Cost' },
    { value: EPurchaseCostReviewStatus.APPROVED, label: 'Approved' },
    { value: EPurchaseCostReviewStatus.PENDING, label: 'Reviewing' },
    { value: EPurchaseCostReviewStatus.REJECTED, label: 'Rejected' },
    { value: EPurchaseCostReviewStatus.PENDING_CANCELLATION, label: 'Pending Cancellation' },
    { value: EPurchaseCostReviewStatus.CANCELLED, label: 'Cancelled' },
  ];

  dateRangeTypeOptions: ISingleSelectOption[] = [
    { value: DateRangeTypes.Arrivals, label: DateRangeTypes.Arrivals },
    { value: DateRangeTypes.Departures, label: DateRangeTypes.Departures },
    { value: DateRangeTypes.Proforma, label: DateRangeTypes.Proforma },
    { value: DateRangeTypes.Invoice, label: DateRangeTypes.Invoice },
  ];

  hasPermissions = (permissions: string): boolean => hasPermissions(this.props.userType as EUserType, permissions);

  componentDidMount() {
    if (this.props.isViewOnlyAssignedToMe === true) {
      this.props.setIsViewOnlyAssignedToMeFilter(true);
      this.props.setPageNumber(0);
    } else {
      this.props.setIsViewOnlyAssignedToMeFilter(false);
      this.props.setPageNumber(0);
    }

    this.props.getBookingListRequest();
    this.props.getDestinationList();

    if (this.hasPermissions(FILTER_BY_COMPANY_PERMISSIONS)) {
      this.props.getCompaniesRequest();
    }

    if (this.hasPermissions(FILTER_BY_CONTACT_PERMISSIONS)) {
      this.props.getSalesRepList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if we're an SR, default the Contact filter to ourselves
    // see https://pureescapes.atlassian.net/browse/OWA-3141
    // but only in case current user manages some booking of the list
    // see https://pureescapes.atlassian.net/browse/OWA-3170
    if (
      !this.props.selectedSalesRepresentativeUuid &&
      !this.state.bookings &&
      prevProps.bookings !== this.props.bookings
    ) {
      if (
        this.props.isSrRole &&
        this.props.bookings?.flatMap(x => x.salesReps || []).some(x => x.uuid === this.props.currentUserUuid)
      ) {
        this.props.setSelectedSalesRepresentative(this.props.currentUserUuid as string);
        return;
      }
    }

    if (prevProps.bookings !== this.props.bookings) {
      this.setState(prevState => ({
        ...prevState,
        bookings: this.props.bookings,
      }));
    }
  }

  componentWillUnmount() {
    this.props.cancelBookingListExport();
  }

  handleHumanReadableIdFilterChange = (e: FormEvent<HTMLInputElement>) => {
    this.props.setHumanReadableIdFilter(e.currentTarget.value || null);
    this.props.setPageNumber(0);
  };

  handleGuestNameFilterChange = (e: FormEvent<HTMLInputElement>) => {
    this.props.setGuestNameFilter(e.currentTarget.value || null);
    this.props.setPageNumber(0);
  };

  handleTravelPartnerRefFilterChange = (e: FormEvent<HTMLInputElement>) => {
    this.props.setTravelPartnerFilter(e.currentTarget.value || null);
  };

  handleSort = (sortBy: keyof BookingSearchItem) => () => {
    const newSortOrder = sortBy === this.props.sortBy && this.props.sortOrder === 'asc' ? 'desc' : 'asc';
    this.props.setSort(sortBy, newSortOrder);
  };

  handlePageChange = (pageNumber: number) => {
    this.props.setPageNumber(pageNumber - 1);
  };

  handleItemsPerPageChange = (itemsPerPage: number) => {
    this.props.setItemsPerPage(itemsPerPage);
  };

  getHeadingText = () => {
    let headingText;
    if (Array.isArray(this.state.bookings) && !this.props.requestPending && this.props.totalResults > 0) {
      headingText = `- ${this.props.totalResults} ${this.props.totalResults === 1 ? 'Result' : 'Results'} Found.`;
    }

    if (Array.isArray(this.state.bookings) && !this.props.requestPending && this.props.totalResults === 0) {
      headingText = ' - No Results';
    }

    return headingText;
  };

  handleExport = (exportType: ExportType) => () => {
    this.props.triggerBookingListExportRequest(exportType);
  };

  handleToggleDateRangeType = (dateRangeType: DateRangeTypes) => {
    const { selectedDateRange } = this.props;

    if (selectedDateRange?.type !== dateRangeType) {
      this.props.setSelectedDateRangeType(dateRangeType);
    }
  };

  handleOnlyShowWithProformaCreated = (e: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      this.props.selectedDateRange?.type === DateRangeTypes.Proforma ||
      this.props.selectedDateRange?.type === DateRangeTypes.Invoice
    ) {
      return;
    }

    this.props.setOnlyShowWithProformaCreatedFilter(this.props.onlyShowWithProformaCreatedFilter ? null : true);
  };

  handleOnlyShowWithInvoiceCreated = (e: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      this.props.selectedDateRange?.type === DateRangeTypes.Proforma ||
      this.props.selectedDateRange?.type === DateRangeTypes.Invoice
    ) {
      return;
    }

    this.props.setOnlyShowWithInvoiceCreatedFilter(this.props.onlyShowWithInvoiceCreatedFilter ? null : true);
  };

  handleFullyPaidByTaClick = (e: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.setFullyPaidByTaClickFilter(this.props.showFullyPaidByTaFilter ? null : true);
  };

  handleHasOverduePayments = (e: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.setHasOverduePaymentsFilter(this.props.hasOverduePaymentsFilter ? null : true);
  };

  handleHotelCommissionAfterCheckout = (e: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.setHasHotelCommissionAfterCheckoutFilter(this.props.hasHotelCommissionAfterCheckoutFilter ? null : true);
  };

  handleChangeBookingListTypeToShow = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    this.props.setPageNumber(0);

    this.props.setOnlyShowWithGroundServicesFilter(this.props.onlyShowWithGroundServicesFilter ? null : true);
  };

  handleOnlyShowMyBookings = (e: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.setOnlyShowMyBookingsFilter(this.props.onlyShowMyBookingsFilter ? null : true);
  };

  handleTravelCompanyChange = (companyUuid: string | undefined) => {
    if (companyUuid === undefined) {
      return;
    }
    this.props.setSelectedCompany(companyUuid);
    this.props.setPageNumber(0);
  };

  handlePcrStatusFilterChange = (value: string | undefined) => {
    this.props.setSelectedPurchaseCostReviewStatus((value as EPurchaseCostReviewStatus) ?? null);
    this.props.setPageNumber(0);
  };

  handleSelectedSalesRepresentativeChange = (value: string | undefined) => {
    this.props.setSelectedSalesRepresentative(value as string);
    this.props.setPageNumber(0);
  };

  renderSortIcon = (sortBy: keyof BookingSearchItem) => {
    if (sortBy !== this.props.sortBy) {
      return (
        <span
          style={{ marginRight: '-18px', width: '38px' }}
          className="group-hover:text-gray-40 text-ivory fa-stack fa-lg"
        >
          <i className="fas fa-stack-1x fa-sort-up"></i>
          <i className="fas fa-stack-1x fa-sort-down"></i>
        </span>
      );
    }
    if (this.props.sortOrder === 'asc') {
      return (
        <span style={{ marginRight: '-18px', width: '38px' }} className="fa-stack fa-lg">
          <i className="fas fa-stack-1x fa-sort-up text-gray-100"></i>
          <i className="fas fa-stack-1x fa-sort-down text-gray-40"></i>
        </span>
      );
    } else if (this.props.sortOrder === 'desc') {
      return (
        <span style={{ marginRight: '-18px', width: '38px' }} className="fa-stack fa-lg">
          <i className="fas fa-stack-1x fa-sort-up text-gray-40"></i>
          <i className="fas fa-stack-1x fa-sort-down text-gray-100 "></i>
        </span>
      );
    }
  };

  renderHeaderSpan = (headerLabel: string, headerSortByKey?: keyof BookingSearchItem) => {
    if (headerSortByKey === undefined) {
      return (
        <span className="group flex font-normal items-center px-2 justify-between h-8">
          <span>{headerLabel}</span>
        </span>
      );
    }

    return (
      <span
        className={classNames(
          'group flex font-normal items-center px-2 justify-between h-8 hover:bg-gray-10 cursor-pointer rounded',
          {
            'bg-gray-10': this.props.sortBy === headerSortByKey,
          }
        )}
      >
        <span>{headerLabel}</span>
        {this.renderSortIcon(headerSortByKey)}
      </span>
    );
  };

  renderStatusTooltip = (status: string) => {
    return <span className="capitalize p-1 text-13px">{status}</span>;
  };

  renderOwnerIconTooltip = () => {
    return <span className="owner-icon-tooltip capitalize p-1 text-13px">Owner</span>;
  };

  renderGuestNameFilter = () => {
    return (
      <FormLabel className="guest-name-filter flex-1" text="Client Name">
        <StyledTextInput value={this.props.guestNameFilter || ''} onChange={this.handleGuestNameFilterChange} />
      </FormLabel>
    );
  };

  renderHumanReadableIdFilter = () => {
    return (
      <FormLabel className="human-readable-id-filter w-150px" text="Booking Ref.">
        <StyledTextInput
          value={this.props.humanReadableIdFilter || ''}
          onChange={this.handleHumanReadableIdFilterChange}
        />
      </FormLabel>
    );
  };

  renderTravelPartnerRefFilter = () => {
    return (
      <FormLabel className="travel-partner-ref-filter flex-1 max-w-160px" text="Travel Partner Ref.">
        <StyledTextInput
          value={this.props.travelPartnerRefFilter || ''}
          onChange={this.handleTravelPartnerRefFilterChange}
        />
      </FormLabel>
    );
  };

  renderCompanyFilter = () => {
    const { companySelectOptions, companyNameSearch } = this.props;
    const { showCompanyDropdown } = this.state;
  
    const disabled = companySelectOptions.length > 0 && !!companySelectOptions[0]?.disabled;
    
    const escapedSearch = escapeStringRegexp(companyNameSearch?.toLocaleLowerCase() || '');
    const [allCompaniesOption, ...restCompanies] = companySelectOptions;
  
    const companyOptions = [
      allCompaniesOption.label,
      ...restCompanies
        .map(c => c.label)
        .filter(label => label.toLocaleLowerCase().includes(escapedSearch))
    ];
   
    const handleInputChange = e => {
      const value = e.currentTarget?.value;
      if (value || value === '') {
        this.props.setCompanyName(value);
      }
    };
  
    const handleOptionSelect = name => {
      const selectedCompany = companySelectOptions.find(item => item.label === name);
      this.handleTravelCompanyChange(selectedCompany?.value ?? undefined);
  
      if (selectedCompany) {
        this.props.setCompanyName(selectedCompany.label);
      }
    };
  
    const toggleDropdown = show => this.setState(prevState => ({
      ...prevState,
      showCompanyDropdown: show,
    }));
  
    return (
      <FormLabel className="company-name-filter w-[200px]" text="Travel Company">
        <PredictiveTextInput
          placeholder="Select ..."
          className="travel-company"
          value={companyNameSearch}
          options={[companyOptions]}
          onChange={handleInputChange}
          onOptionSelect={handleOptionSelect}
          showDropDown={showCompanyDropdown}
          onFocus={() => toggleDropdown(true)}
          onBlur={() => toggleDropdown(false)}
          disabled={disabled}
        />
      </FormLabel>
    );
  };

  renderHotelFilter = () => {
    return (
      <div className="destination flex-1 max-w-250px">
        <span className="inline-block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
          Destination or Hotel
        </span>
        <Multiselect
          className="bg-ivory"
          itemsClassname="bg-ivory"
          itemCtaClassName="hover:bg-gray-10"
          options={this.props.hotelNameOptions}
          isCloseOnSelect={true}
          hideCheckboxes={true}
          isSingleSelectMode={true}
          isIncludeClearButton={true}
          onUpdate={sv => {
            this.props.setSelectedDestination(sv[0]);
            this.props.setPageNumber(0);
          }}
          selectedValues={this.props.selectedDestination ? [this.props.selectedDestination] : []}
        />
      </div>
    );
  };

  renderStatusFilter = () => {
    return (
      <div className="status w-250px mt-2px">
        <span className="inline-block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Status</span>
        <Multiselect
          className="multiselect-status bg-ivory text-15px"
          itemCtaClassName="hover:bg-teal-20 text-left"
          itemsClassname="bg-ivory"
          onUpdate={selectedValues => {
            this.props.setSelectedStatuses(selectedValues);
            this.props.setPageNumber(0);
          }}
          options={[
            {
              value: EBookingStatus.ENQUIRY,
              label: 'Enquiry',
              iconHtml: <i className="fas fa-circle text-status-enquiry"></i>,
              iconAlign: 'end',
            },
            {
              value: EBookingStatus.REQUESTED,
              label: 'Requested',
              iconHtml: <i className="fas fa-circle text-status-requested"></i>,
              iconAlign: 'end',
            },
            {
              value: EBookingStatus.DISCARDED,
              label: 'Discarded',
              iconHtml: <i className="fas fa-circle text-status-discarded"></i>,
              iconAlign: 'end',
            },
            {
              value: EBookingStatus.CONFIRMED,
              label: 'Confirmed',
              iconHtml: <i className="fas fa-circle text-status-confirmed"></i>,
              iconAlign: 'end',
            },
            {
              value: EBookingStatus.CANCELLED,
              label: 'Cancelled',
              iconHtml: <i className="fas fa-circle text-status-cancelled"></i>,
              iconAlign: 'end',
            },
            {
              value: EBookingStatus.COMPLETED,
              label: 'Completed',
              iconHtml: <i className="fas fa-circle text-status-completed"></i>,
              iconAlign: 'end',
            },
          ]}
          selectedValues={this.props.selectedStatuses}
        />
      </div>
    );
  };

  renderPcrStatusFilter = () => (
    <SingleSelect
      fieldId="pcr-status"
      label="Hotel Purchase Cost Status"
      className="pcr-status w-220px"
      value={this.props.selectedPurchaseCostReviewStatus || ''}
      options={this.pcrStatusOptions}
      onChange={this.handlePcrStatusFilterChange}
    />
  );

  renderContactFilter = () => {
    const disabled = !!this.props.salesRepresentativesOptions.find(item => item.disabled === true);
    const options: ISingleSelectOption[] = this.props.salesRepresentativesOptions.map(item => {
      return {
        value: item.value ?? undefined,
        label: item.label,
      };
    });

    return (
      <span className="flex-1">
        <SingleSelect
          fieldId="hotel"
          label="Contact"
          className="hotel relative top-2px"
          value={this.props.selectedSalesRepresentativeUuid || ''}
          options={options}
          onChange={this.handleSelectedSalesRepresentativeChange}
          disabled={disabled}
        />
      </span>
    );
  };

  renderWithInvoiceFilter = () => {
    return (
      <label className="with-invoice flex items-center text-black text-xs h-35px mt-22px">
        <Checkbox
          wrapperClassName="mr-1"
          checked={this.props.onlyShowWithInvoiceFilter}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            this.props.setOnlyShowWithInvoiceFilter(e.target.checked);
            this.props.setPageNumber(0);
          }}
        />
        With invoice
      </label>
    );
  };

  renderDatesFilter = () => {
    const isDateRangeSelected = !!(this.props.selectedDateRange?.startDate && this.props.selectedDateRange?.endDate);
    return (
      <div className="flex date-filters">
        <div className="date-range-filter">
          <FormLabel
            text={
              <div className="flex justify-between">
                <span>Date Range Filter</span>
                {isDateRangeSelected && (
                  <span
                    className="cursor-pointer underline text-brown-100 hover:no-decoration hover:text-brown-120"
                    onClick={this.props.clearDateRangeFilter}
                  >
                    Clear Date Range
                  </span>
                )}
              </div>
            }
            textWrapperClasses="w-full"
          >
            <div className="flex">
              <DatePickerStateProvider
                defaultSelectedDates={
                  this.props.selectedDateRange?.startDate && this.props.selectedDateRange?.endDate
                    ? [this.props.selectedDateRange.startDate, this.props.selectedDateRange.endDate]
                    : []
                }
                onDateChange={dates => {
                  const startDate = format(new Date(dates[0]), 'yyyy-MM-dd');
                  const endDate = format(new Date(dates[dates.length - 1]), 'yyyy-MM-dd');
                  this.props.setSelectedDateRangeValue(startDate, endDate);
                }}
                render={(params: IDatePickerSateParams) => {
                  const placeHolder = 'Select Dates...';
                  const dates =
                    !params.dateSelectionInProgress && this.props.selectedDateRange === null
                      ? []
                      : params.selectedDates;
                  const displayString = this.props.selectedDateRange === null ? placeHolder : params.displayString;

                  return (
                    <StyledDateRangeInput
                      displayString={displayString}
                      currentDate={params.datePickerCurrentDate}
                      selectedDates={dates}
                      onDayClick={params.handleDayClick}
                      onDayMouseOver={params.handleDateMouseOver}
                      showDatePicker={params.showDatePicker}
                      onNextClick={params.incrementDate}
                      onPrevClick={params.decrementDate}
                      onMouseDown={params.toggleDatePicker}
                      onClickOutside={params.hideDatePicker}
                      placeholder={placeHolder}
                      showTotalNights={false}
                      showCalendarIcon
                      enablePastDates
                      noPortal
                    />
                  );
                }}
              />

              {isDateRangeSelected && (
                <SingleSelect
                  fieldId="dateRange-type-filter settings"
                  className="dateRange-type-filter settings w-150px bg-ivory font-pt-sans"
                  value={this.props.selectedDateRange?.type || ''}
                  options={this.dateRangeTypeOptions}
                  onChange={this.handleToggleDateRangeType}
                  maxVisibleItems={6}
                />
              )}
            </div>
          </FormLabel>
        </div>
      </div>
    );
  };

  renderTaCountryCodeFilter = () => {
    return (
      <div className="travel-agent-country-filter w-150px">
        <span className="inline-block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
          Travel Company Country
        </span>
        <Multiselect
          className="bg-ivory"
          itemsClassname="bg-ivory"
          itemCtaClassName="hover:bg-gray-10"
          options={this.props.countries.map(country => ({ value: country.code, label: country.name }))}
          isCloseOnSelect={true}
          hideCheckboxes={true}
          isSingleSelectMode={true}
          isIncludeClearButton={true}
          onUpdate={sv => {
            this.props.setSelectedTravelAgentCountryCode(sv[0]);
          }}
          selectedValues={this.props.selectedTravelAgentCountryCode ? [this.props.selectedTravelAgentCountryCode] : []}
        />
      </div>
    );
  };

  renderBookingTypeFilter = () => {
    return (
      <div className="booking-type-filter w-150px">
        <span className="inline-block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
          Booking Type
        </span>
        <Multiselect
          className="bg-ivory"
          itemsClassname="bg-ivory"
          itemCtaClassName="hover:bg-gray-10"
          options={bookingTypeOptions}
          isCloseOnSelect={true}
          hideCheckboxes={true}
          isSingleSelectMode={true}
          isIncludeClearButton={true}
          onUpdate={sv => {
            this.props.setSelectedBookingType(sv[0]);
          }}
          selectedValues={this.props.selectedBookingType ? [this.props.selectedBookingType] : []}
        />
      </div>
    );
  };

  renderRateSourceFilter = () => {
    return (
      <div className="rate-source-filter w-200px">
        <span className="inline-block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Rate Type</span>
        <Multiselect
          className="bg-ivory"
          itemsClassname="bg-ivory"
          itemCtaClassName="hover:bg-gray-10"
          options={[
            {
              label: 'Static Rate',
              value: ERateSource.STATIC,
            },
            {
              label: 'Static Rate - Free Sale',
              value: ERateSource.INSTANT_BOOK,
            },
            {
              label: 'Live Rate',
              value: [ERateSource.SUPPLIER_CONFIRMED, ERateSource.SUPPLIER_UNCONFIRMED].join(','),
            },
          ]}
          isCloseOnSelect={true}
          hideCheckboxes={true}
          isSingleSelectMode={true}
          isIncludeClearButton={true}
          onUpdate={sv => {
            const [v] = sv;
            if (!v) {
              this.props.setRateSourceFilter(null);
              return;
            }
            this.props.setRateSourceFilter(v.split(',') as ERateSource[]);
          }}
          selectedValues={this.props.rateSourceFilter?.length ? [this.props.rateSourceFilter.join(',')] : []}
        />
      </div>
    );
  };

  renderProductConfirmationNumberFilter = () => {
    return (
      <FormLabel className="product-confirmation-number-filter flex-1 max-w-290px" text="Confirmation No.">
        <StyledTextInput
          value={this.props.productConfirmationNumberFilter || ''}
          onChange={e => {
            this.props.setProductConfirmationNumberFilter(e.currentTarget.value);
          }}
        />
      </FormLabel>
    );
  };

  renderProductTypeFilter = () => {
    return (
      <div className="product-types flex-1 max-w-250px mt-2px">
        <span className="inline-block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
          Product Type
        </span>
        <Multiselect
          className="multiselect-product-type bg-ivory text-15px"
          itemCtaClassName="hover:bg-teal-20 text-left"
          itemsClassname="bg-ivory"
          isIncludeClearButton={true}
          onUpdate={selectedValues => {
            this.props.setSelectedProductTypesFilter(selectedValues as BookingListProductTypesFilter[]);
            this.props.setPageNumber(0);
          }}
          options={[
            {
              value: 'hasAccommodations',
              label: 'Accommodations',
            },
            {
              value: 'hasTransfers',
              label: 'Transfers',
            },
            {
              value: 'hasGroundServices',
              label: 'Ground Services',
            },
            {
              value: 'hasFines',
              label: 'Additional Hotel Charges',
            },
            {
              value: 'hasSupplements',
              label: 'Supplements',
            },
            {
              value: 'hasBespokes',
              label: 'Activities',
            },
          ]}
          selectedValues={this.props.selectedProductTypesFilter}
        />
      </div>
    );
  };

  renderTaFilters = () => {
    return (
      <>
        <div className="row flex space-x-15px">
          {this.renderGuestNameFilter()}
          {this.renderHumanReadableIdFilter()}
          {this.renderHotelFilter()}
          {this.renderStatusFilter()}
          {this.renderTravelPartnerRefFilter()}
        </div>
        <div className="row flex mt-3 space-x-15px">
          {this.renderContactFilter()}
          {this.renderDatesFilter()}
        </div>
      </>
    );
  };

  renderNonTaFilters = () => {
    return (
      <>
        <div className="row flex space-x-15px">
          {this.renderGuestNameFilter()}
          {this.renderHumanReadableIdFilter()}
          {this.renderCompanyFilter()}
          {this.renderHotelFilter()}
        </div>
        <div className="row flex mt-3 space-x-15px">
          {this.renderStatusFilter()}
          {this.props.showWithInvoiceToggle ? this.renderWithInvoiceFilter() : this.renderContactFilter()}
          {this.renderDatesFilter()}
          {this.renderTaCountryCodeFilter()}
          {this.renderBookingTypeFilter()}
        </div>
        <div className="row flex mt-3 space-x-15px">
          {this.renderProductTypeFilter()}
          {this.renderProductConfirmationNumberFilter()}
          {this.renderRateSourceFilter()}
        </div>
      </>
    );
  };

  renderMainFilters = () => {
    return this.props.isTA ? this.renderTaFilters() : this.renderNonTaFilters();
  };

  renderAdditionalFilters = () => {
    const { selectedDateRange } = this.props;
    const existsFinanceSalesTypeFilter =
      selectedDateRange?.type === DateRangeTypes.Invoice || selectedDateRange?.type === DateRangeTypes.Proforma;

    return (
      <div className="flex flex-col font-pt-sans">
        <span className="text-13px text-black tracking-2xs ml-[20px]">Additional Finance Filters</span>
        <div className="flex text-base text-black mt-10px">
          <label
            className={classNames('has-proforma-created flex justify-center ml-5 items-end', {
              'cursor-pointer': !existsFinanceSalesTypeFilter,
              'cursor-default': existsFinanceSalesTypeFilter,
            })}
            onClick={this.handleOnlyShowWithProformaCreated}
          >
            <Checkbox
              disabled={existsFinanceSalesTypeFilter}
              checked={this.props.onlyShowWithProformaCreatedFilter === true}
              // onClick={} - handled by the label above
              readOnly={true}
              className={classNames({
                'cursor-pointer': !existsFinanceSalesTypeFilter,
                'cursor-default': existsFinanceSalesTypeFilter,
              })}
            />
            <span className={classNames('text-black ml-2', { 'text-gray-80': existsFinanceSalesTypeFilter })}>
              Proforma
            </span>
          </label>

          <label
            className={classNames('has-invoice-created flex justify-center ml-5 items-end', {
              'cursor-pointer': !existsFinanceSalesTypeFilter,
              'cursor-default': existsFinanceSalesTypeFilter,
            })}
            onClick={this.handleOnlyShowWithInvoiceCreated}
          >
            <Checkbox
              disabled={existsFinanceSalesTypeFilter}
              checked={this.props.onlyShowWithInvoiceCreatedFilter === true}
              // onClick={} - handled by the label above
              readOnly={true}
              className={classNames({
                'cursor-pointer': !existsFinanceSalesTypeFilter,
                'cursor-default': existsFinanceSalesTypeFilter,
              })}
            />
            <span className={classNames('text-black ml-2', { 'text-gray-80': existsFinanceSalesTypeFilter })}>
              Invoice
            </span>
          </label>

          <label
            className="fully-paid-by-ta-filter flex justify-center ml-5 cursor-pointer items-end"
            onClick={this.handleFullyPaidByTaClick}
          >
            <Checkbox 
              checked={this.props.showFullyPaidByTaFilter === true}
              // onClick={} - handled by the label above
              readOnly={true}
            />
            <span className="text-black ml-2">Fully paid by TP</span>
          </label>

          <label
            className="payment-overdue-filter flex justify-center ml-5 cursor-pointer items-end"
            onClick={this.handleHasOverduePayments}
          >
            <Checkbox
              checked={this.props.hasOverduePaymentsFilter === true}
              // onClick={} - handled by the label above
              readOnly={true}
            />
            <span className="ml-2">Payment Overdue</span>
          </label>

          <label
            className="hotel-commission-after-checkout-filter flex justify-center ml-5 cursor-pointer items-end"
            onClick={this.handleHotelCommissionAfterCheckout}
          >
            <Checkbox
              checked={this.props.hasHotelCommissionAfterCheckoutFilter === true}
              // onClick={} - handled by the label above
              readOnly={true}
            />
            <span className="ml-2">Hotel Commission After Checkout</span>
          </label>
        </div>
      </div>
    );
  };

  renderCompanyBookingManagerFilters = () => {
    return (
      <div className="flex flex-col font-pt-sans">
        <span className="text-13px tracking-2xs">Additional Filters</span>
        <div className="flex text-base text-black mt-10px">
          <label
            className="only-show-my-bookings flex justify-center items-end cursor-pointer"
            onClick={this.handleOnlyShowMyBookings}
          >
            <Checkbox
              checked={this.props.onlyShowMyBookingsFilter === true}
              // onClick={} - handled by the label above
              readOnly={true}
              className="cursor-pointer"
            />
            <span className="ml-2">My Bookings</span>
          </label>
        </div>
      </div>
    );
  };

  render() {
    const exportEnabled = this.props.selectedStatuses.length >= 1 || this.props.selectedPurchaseCostReviewStatus;

    const srExportEnabled =
      this.props.selectedSalesRepresentativeUuid === this.props.currentUserUuid &&
      (this.props.selectedStatuses.length >= 1 || this.props.selectedPurchaseCostReviewStatus);

    return (
      <BookingListStylesWrapper className="bookings">
        <Poller
          exportUuid={this.props.exportUuid}
          isWaitingForExport={this.props.isWaitingForExport}
          pollRequestFunction={() => this.props.pollBookingListExportRequest()}
          pollExportLoad={this.props.pollExportLoad}
        />

        <PageTitle
          primaryText="Enquiries and Bookings"
          secondaryCondition={!this.props.requestPending && !this.props.isRefetchingBookings}
          secondaryText={this.getHeadingText()}
          loadingCondition={this.props.isRefetchingBookings}
          loadingText="Loading ..."
        />

        {/* the primary filters */}
        <div className="filters">
          <span className="block font-pt-sans font-bold text-17px mb-2">Filter Results</span>
          <div className="group main">{this.renderMainFilters()}</div>
        </div>

        {/* the finance filters */}
        {!this.props.isTA && (
          <div className="group finance mt-30px">
            <span className="block font-pt-sans font-bold text-17px mb-2">Finance Filters</span>
            <div className="filter-ctas my-4 pr-15px flex">
              <>
                {this.renderPcrStatusFilter()}
                {this.hasPermissions(USE_ADDITIONAL_FILTERS_PERMISSIONS) && this.renderAdditionalFilters()}
                {this.props.isCompanyBookingManager && this.renderCompanyBookingManagerFilters()}
              </>
            </div>
          </div>
        )}

        {/* all the buttons */}
        <div className="group button mt-[30px]">
          <div className="flex justify-between">
            <FluidButton
              type="secondary"
              className="block"
              onClick={() => {
                this.props.resetFilters();
              }}
              disabled={false}
            >
              Reset Filters
            </FluidButton>
            <div className="flex justify-end">
              {this.hasPermissions(GROUND_TEAM_EXPORT_PERMISSIONS) && (
                <FluidButton
                  isLoading={this.props.isWaitingForExport && this.props.exportType === ExportType.DetailsInfo}
                  type="secondary"
                  className="block self-end"
                  onClick={this.handleExport(ExportType.DetailsInfo)}
                  disabled={this.props.isWaitingForExport || !exportEnabled}
                >
                  Ground Team Export
                </FluidButton>
              )}
              {this.hasPermissions(FINANCE_TEAM_EXPORT_PERMISSIONS) && (
                <FluidButton
                  isLoading={this.props.isWaitingForExport && this.props.exportType === ExportType.BookingInfo}
                  type="primary"
                  className="ml-2 block self-end"
                  onClick={this.handleExport(ExportType.BookingInfo)}
                  disabled={this.props.isWaitingForExport || !exportEnabled}
                >
                  Finance Team Export
                </FluidButton>
              )}
              {this.props.isSrRole && (
                <FluidButton
                  className="sr-export-button ml-2 block self-end"
                  disabled={this.props.isWaitingForExport || !srExportEnabled}
                  type="primary"
                  onClick={this.handleExport(ExportType.SrExport)}
                >
                  SR Export
                </FluidButton>
              )}
              {this.props.isWaitingForExport && (
                <span className="text-gray-100 italic inline mt-15px ml-2 text-sm self-center">
                  Exporting, please wait...
                </span>
              )}
            </div>
          </div>
        </div>

        {this.props.requestPending && (
          <div className="mt-5">
            <LoadingBar />
          </div>
        )}
        {Array.isArray(this.state.bookings) && !this.props.requestPending && this.props.totalResults > 0 && (
          <table
            className={classNames(
              'list table table-xs table-fixed w-full mt-[25px] border-collapse text-left font-pt-sans',
              {
                'opacity-30': this.props.isRefetchingBookings,
              }
            )}
          >
            <thead className="headers">
              <tr className="bg-ivory border border-solid border-gray-40 shadow-pe1 h-10 uppercase text-xs text-gray-120">
                <>
                  <th
                    id="id-header"
                    style={{ width: '135px' }}
                    className="pl-4"
                    onClick={this.handleSort('humanReadableId')}
                  >
                    {this.renderHeaderSpan('Ref. Number', 'humanReadableId')}
                  </th>

                  <th id="createdAt-header" style={{ width: '100px' }} onClick={this.handleSort('createdAt')}>
                    {this.renderHeaderSpan('Created', 'createdAt')}
                  </th>

                  <th id="dates-header" style={{ width: '100px' }} onClick={this.handleSort('bookingArrival')}>
                    {this.renderHeaderSpan('Dates', 'bookingArrival')}
                  </th>

                  <th id="hotel-header" style={{ width: '170px' }} onClick={this.handleSort('hotelName')}>
                    {this.renderHeaderSpan('Hotel', 'hotelName')}
                  </th>

                  <th id="client-header" style={{ width: '152px' }} onClick={this.handleSort('guestLastName')}>
                    {this.renderHeaderSpan('Client', 'guestLastName')}
                  </th>

                  {this.hasPermissions(FILTER_BY_COMPANY_PERMISSIONS) && (
                    <th
                      id="ta-company-name-header"
                      style={{ width: '150px' }}
                      onClick={this.handleSort('travelAgentCompanyName')}
                    >
                      {this.renderHeaderSpan('TP Company', 'travelAgentCompanyName')}
                    </th>
                  )}

                  <th style={{ width: '120px' }} id="cost-header" onClick={this.handleSort('costToTaCents')}>
                    {this.renderHeaderSpan('Cost', 'costToTaCents')}
                  </th>

                  <th style={{ width: '120px' }} id="contact-header">
                    {this.renderHeaderSpan('Contact')}
                  </th>

                  <th style={{ width: '120px' }} id="travel-partner-ref-header">
                    {this.renderHeaderSpan('TP Ref.')}
                  </th>

                  {this.props.isCompanyBookingManager && <th style={{ width: '30px' }}>{/* owner icon */}</th>}
                  <th style={{ width: '50px' }}>{/* status */}</th>
                  <th style={{ width: '62px' }}>{/* actions */}</th>
                </>
              </tr>
            </thead>
            <tbody className="content">
              {this.state.bookings &&
                this.state.bookings.map(bookingSearchItem => {
                  return (
                    <tr
                      id={`booking-${bookingSearchItem.bookingUuid}`}
                      key={bookingSearchItem.bookingUuid}
                      className="h-15 even:bg-ivory text-15px"
                    >
                      <>
                        <td className="px-2 pl-6">
                          <span className="inline-block break-words">
                            {bookingSearchItem.humanReadableId || bookingSearchItem.bookingUuid}
                          </span>
                        </td>
                        <td className="px-2">{formatDateDisplay(bookingSearchItem.createdAt)}</td>
                        <td className="px-2">
                          {(bookingSearchItem.bookingArrival && bookingSearchItem.bookingDeparture && (
                            <React.Fragment>
                              <span className="block">{formatDateDisplay(bookingSearchItem.bookingArrival)}</span>
                              <span className="block">{formatDateDisplay(bookingSearchItem.bookingDeparture)}</span>
                            </React.Fragment>
                          )) || <span>N/A</span>}
                        </td>
                        <td className="px-2 text-ellipsis overflow-hidden whitespace-nowrap">
                          {bookingSearchItem.hotelName}
                        </td>

                        <td className="px-2 text-ellipsis overflow-hidden whitespace-nowrap">
                          <span className="break-words">
                            {renderFullName(
                              bookingSearchItem.guestTitle,
                              bookingSearchItem.guestFirstName,
                              bookingSearchItem.guestLastName
                            )}
                          </span>
                        </td>
                        {this.hasPermissions(FILTER_BY_COMPANY_PERMISSIONS) && (
                          <td className="px-2 text-ellipsis overflow-hidden whitespace-nowrap">
                            {bookingSearchItem.travelAgentCompanyName}
                          </td>
                        )}
                        <td className="px-2">
                          <span className="break-words">
                            {bookingSearchItem.currencySymbol}
                            {formatPrice(bookingSearchItem.costToTaCents / 100)}
                          </span>
                        </td>
                        <td className="px-2">
                          <Contacts emails={(bookingSearchItem.salesReps || []).map(x => x.email)} />
                        </td>
                        <td className="px-2 text-ellipsis overflow-hidden whitespace-nowrap">
                          {bookingSearchItem.travelPartnerRef}
                        </td>
                        {this.props.isCompanyBookingManager && (
                          <td className="owner-icon-col px-2 relative">
                            {this.props.currentUserUuid === bookingSearchItem.travelAgentUuid && (
                              <HidingTooltip
                                renderTooltipContent={this.renderOwnerIconTooltip}
                                position="bottom"
                                tooltipClassname="mt-4"
                              >
                                <OwnerIcon className="block mx-auto cursor-pointer" fill={theme.colors['light-gray']} />
                              </HidingTooltip>
                            )}
                          </td>
                        )}
                        <td className="px-2 relative">
                          <HidingTooltip
                            renderTooltipContent={() => this.renderStatusTooltip(bookingSearchItem.status)}
                            position="bottom"
                            tooltipClassname="mt-4"
                          >
                            <BookingStatusIndicator
                              className="block mx-auto cursor-pointer"
                              status={bookingSearchItem.status}
                            />
                          </HidingTooltip>
                        </td>
                        <td className="actions px-2">
                          <GoToDetails className="manage" link={`/booking-manager/${bookingSearchItem.bookingUuid}`} />
                        </td>
                      </>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}

        {(this.state.bookings || []).length >= 1 && (
          <Pagination
            className="pagination"
            onPageSelect={this.handlePageChange}
            pageCount={this.props.pageCount}
            currentPage={this.props.currentPage + 1}
            itemsPerPage={this.props.itemsPerPage}
            onItemsPerPageChange={this.handleItemsPerPageChange}
          />
        )}
      </BookingListStylesWrapper>
    );
  }
}

// -----------------------------------------------------------------------------
// Prop Typings
// -----------------------------------------------------------------------------
export type StateToProps = ReturnType<typeof mapStateToProps>;
export type DispatchToProps = typeof actionCreators;
export interface IBookingListProps extends StateToProps, DispatchToProps {
  className: string;
  selectedSalesRepresentativeUuid: string;
  selectedDestination: string;
  // selectedStatuses: string[];
  isViewOnlyAssignedToMe: boolean;
  showWithInvoiceToggle: boolean;
}

const mapStateToProps = createStructuredSelector({
  requestPending: BookingListSelectors.requestPendingSelector,
  error: BookingListSelectors.errorSelector,
  bookings: BookingListSelectors.bookingsSelector,
  humanReadableIdFilter: BookingListSelectors.humanReadableIdFilterSelector,
  guestNameFilter: BookingListSelectors.guestNameFilterSelector,
  travelPartnerRefFilter: BookingListSelectors.travelPartnerRefFilterSelector,
  totalResults: BookingListSelectors.totalResultsSelector,
  sortBy: BookingListSelectors.sortBySelector,
  sortOrder: BookingListSelectors.sortOrderSelector,
  currentPage: BookingListSelectors.currentPageSelector,
  pageCount: BookingListSelectors.pageCountSelector,
  itemsPerPage: BookingListSelectors.itemsPerPageSelector,
  selectedCompanyUuid: BookingListSelectors.selectedCompanyUuidSelector,
  selectedSalesRepresentativeUuid: BookingListSelectors.selectedSalesRepresentativeUuidSelector,
  selectedDestination: BookingListSelectors.selectedDestinationSelector,
  selectedStatuses: BookingListSelectors.selectedStatusesSelector,
  selectedPurchaseCostReviewStatus: BookingListSelectors.selectedPurchaseCostReviewStatusSelector,
  selectedDateRange: BookingListSelectors.selectedDateRangeSelector,
  selectedTravelAgentCountryCode: BookingListSelectors.selectedTravelAgentCountryCodeSelector,
  selectedBookingType: BookingListSelectors.selectedBookingTypeSelector,
  isTA: isTA,
  isSrRole: isSr,
  hotelNameOptions: BookingListSelectors.destinationOptionsSelector,
  salesRepresentativesOptions: BookingListSelectors.salesRepresentativeOptionsSelector,
  companySelectOptions: companySelectOptionsSelector,
  isWaitingForExport: BookingListSelectors.isWaitingForExportSelector,
  exportUuid: BookingListSelectors.exportUuidSelector,
  exportType: BookingListSelectors.exportTypeSelector,
  pollExportLoad: BookingListSelectors.pollExportLoadSelector,
  onlyShowWithInvoiceFilter: BookingListSelectors.onlyShowWithInvoiceFilterSelector,
  onlyShowWithGroundServicesFilter: BookingListSelectors.onlyShowWithGroundServicesFilterSelector,
  onlyShowWithProformaCreatedFilter: BookingListSelectors.onlyShowWithProformaCreatedFilterSelector,
  onlyShowWithInvoiceCreatedFilter: BookingListSelectors.onlyShowWithInvoiceCreatedFilterSelector,
  onlyShowMyBookingsFilter: BookingListSelectors.onlyShowMyBookingsFilterSelector,
  hasOverduePaymentsFilter: BookingListSelectors.hasOverduePaymentsFilterSelector,
  hasHotelCommissionAfterCheckoutFilter: BookingListSelectors.hasHotelCommissionAfterCheckoutFilterSelector,
  showFullyPaidByTaFilter: BookingListSelectors.showFullyPaidByTaFilterSelector,
  rateSourceFilter: BookingListSelectors.rateSourceFilterSelector,
  isViewOnlyAssignedToMeFilter: BookingListSelectors.isViewOnlyAssignedToMeFilterSelector,
  currentUserUuid: getCurrentUserUuid,
  userType: getCurrentUserType,
  isRefetchingBookings: BookingListSelectors.isRefetchingBookingsSelector,
  countries: getBootstrapCountriesSelector,
  selectedProductTypesFilter: BookingListSelectors.selectedProductTypesFilterSelector,
  isCompanyBookingManager,
  productConfirmationNumberFilter: BookingListSelectors.productConfirmationNumberFilterSelector,
  companyNameSearch: BookingListSelectors.companyNameSearchSelector,
});

const actionCreators = {
  getBookingListRequest: BookingListActions.getBookingListRequestAction,
  getDestinationList: BookingListActions.getDestinationListRequestAction,
  getSalesRepList: BookingListActions.getSalesRepListRequestAction,
  getCompaniesRequest: getCompaniesRequestAction,
  setHumanReadableIdFilter: BookingListActions.setHumanReadableIdFilterAction,
  setGuestNameFilter: BookingListActions.setGuestNameFilterAction,
  setTravelPartnerFilter: BookingListActions.setTravelPartnerRefFilterAction,
  setPageNumber: BookingListActions.setPageNumberAction,
  setItemsPerPage: BookingListActions.setItemsPerPageAction,
  setSort: BookingListActions.setSortAction,
  setSelectedCompany: BookingListActions.setSelectedCompanyAction,
  setCompanyName: BookingListActions.setCompanyNameAction,
  setSelectedSalesRepresentative: BookingListActions.setSelectedSalesRepresentativeAction,
  setSelectedDestination: BookingListActions.setSelectedDestinationAction,
  setSelectedStatuses: BookingListActions.setSelectedStatusesAction,
  setSelectedPurchaseCostReviewStatus: BookingListActions.setSelectedPurchaseCostReviewStatusAction,
  setSelectedDateRangeType: BookingListActions.setDateRangeTypeAction,
  setSelectedDateRangeValue: BookingListActions.setDateRangeValueAction,
  setSelectedTravelAgentCountryCode: BookingListActions.setSelectedTravelAgentCountryCodeAction,
  setSelectedBookingType: BookingListActions.setSelectedBookingTypeAction,
  setIsViewOnlyAssignedToMeFilter: BookingListActions.setIsViewOnlyAssignedToMeFilterAction,
  setOnlyShowWithInvoiceFilter: BookingListActions.setOnlyShowWithInvoiceFilterAction,
  setOnlyShowWithGroundServicesFilter: BookingListActions.setOnlyShowWithGroundServicesFilterAction,
  setOnlyShowWithProformaCreatedFilter: BookingListActions.setHasProformaCreatedFilterAction,
  setOnlyShowWithInvoiceCreatedFilter: BookingListActions.setHasInvoiceCreatedFilterAction,
  setOnlyShowMyBookingsFilter: BookingListActions.setOnlyShowMyBookingsFilterAction,
  setRateSourceFilter: BookingListActions.setRateSourceFilterAction,
  setHasOverduePaymentsFilter: BookingListActions.setHasOverduePaymentsFilterAction,
  setHasHotelCommissionAfterCheckoutFilter: BookingListActions.setHasHotelCommissionAfterCheckoutFilterAction,
  cancelBookingListExport: BookingListActions.cancelBookingListExportAction,
  clearDateRangeFilter: BookingListActions.clearDateRangeFilterAction,
  setFullyPaidByTaClickFilter: BookingListActions.setFullyPaidByTaFilterAction,
  triggerBookingListExportRequest: BookingListActions.triggerBookingListExportRequestAction,
  pollBookingListExportRequest: BookingListActions.pollBookingListExportRequestAction,
  setSelectedProductTypesFilter: BookingListActions.setSelectedProductTypesFilterAction,
  setProductConfirmationNumberFilter: BookingListActions.setProductConfirmationNumberFilterAction,
  resetFilters: BookingListActions.resetFiltersAction,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

// -----------------------------------------------------------------------------
// Connected
// -----------------------------------------------------------------------------
const withConnect = connect<StateToProps, DispatchToProps, IBookingListProps>(mapStateToProps, mapDispatchToProps);

// @ts-ignore
export const BookingListConnected = compose(withConnect)(BookingListContainer);

export default BookingListConnected;
