import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { uniq } from 'lodash-es';
import { makeBackendApi, IHotel, IHotelResponse } from 'services/BackendApi';
import {
  getProposalDetailsFailureAction,
  getProposalDetailsSuccessAction,
  GET_PROPOSAL_DETAILS_REQUEST,
  setProposalDownloadHistoryAction,
  setProposalBookingsAction,
  setProposalDetailsSortByAction,
} from '../actions';
import { proposalDetailsUuidSelector } from '../selectors';
import {
  IGetHotelDetailsResponse,
  IHeadlineLineItemBreakdownResponse,
  ITopNavigationData,
  makeBookingManagerApi,
} from 'services/BookingManagerApi';
import { EProposalSortBy } from '../model';
import { ESortOrder } from 'store/common/types';
import { IProposalBooking } from '../types';
import { IProposalHistory } from 'services/BookingManagerApi';
import { IProposalSelection } from 'services/BookingManagerApi/types/ProposalSelection';
import { compileProposalBooking } from '../helpers';

export function* getProposalDetailsSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const proposalUuid = yield select(proposalDetailsUuidSelector);

    // At the moment BMS does not have a single endpoint that returns all the data about a given proposal.
    // Therefore, proposal data needs to be get from a bunch of different BMS endpoints,
    // and then merged together into a "ProposalBooking" object that can be used by UI components.
    const proposalDetailsResponse: AxiosResponse<IProposalSelection> = yield call(
      bookingManagerApi.getProposalSelection,
      proposalUuid
    );
    const bookingUuids = proposalDetailsResponse.data.bookingUuids;

    const proposalDownloadHistoryResponse: AxiosResponse<IProposalHistory[]> = yield call(
      bookingManagerApi.getProposalHistory,
      proposalUuid
    );
    const proposalDownloadHistory = proposalDownloadHistoryResponse.data;

    yield put(setProposalDownloadHistoryAction(proposalDownloadHistory));

    const hotelDetailsRequests = bookingUuids.map(bookingUuid => call(bookingManagerApi.getHotelDetails, bookingUuid));
    const hotelDetailsResponses: AxiosResponse<IGetHotelDetailsResponse>[] = yield all(hotelDetailsRequests);
    const hotelUuids = hotelDetailsResponses.map(item => item.data.hotelDetails.uuid);

    const breakdownRequests = bookingUuids.map(bookingUuid =>
      call(bookingManagerApi.getHeadlineLineItemBreakdown, bookingUuid)
    );
    const topNavigationRequests = bookingUuids.map(bookingUuid =>
      call(bookingManagerApi.getTopNavigationData, bookingUuid)
    );

    const allResponses = yield all([...breakdownRequests, ...topNavigationRequests]);

    const bookings: IProposalBooking[] = bookingUuids.map((bookingUuid, index) => {
      const bookingBreakdownResponse: IHeadlineLineItemBreakdownResponse = allResponses[index].data;
      const bookingTopNavigation: ITopNavigationData = allResponses[index + bookingUuids.length].data.topNavigationData;
      const hotelUuid = hotelUuids[index];
      const matchingHotelDetails = hotelDetailsResponses.find(item => item.data.hotelDetails.uuid === hotelUuid);

      const { hotelDetails } = matchingHotelDetails!.data;

      return compileProposalBooking(bookingBreakdownResponse, bookingTopNavigation, hotelDetails);
    });

    yield put(setProposalBookingsAction(bookings));
    yield put(setProposalDetailsSortByAction(EProposalSortBy.CREATED, ESortOrder.ASC));
    yield put(getProposalDetailsSuccessAction(proposalDetailsResponse.data));
  } catch (e) {
    yield put(getProposalDetailsFailureAction(e));
  }
}

export function* watchGetProposalDetails() {
  yield takeLatest([GET_PROPOSAL_DETAILS_REQUEST], getProposalDetailsSaga);
}
