import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi, IProposalsListResponse, EUserType } from 'services/BackendApi';
import {
  GET_PENDING_PROPOSALS_INFO_REQUEST,
  getPendingProposalsInfoSuccessAction,
  getPendingProposalsInfoFailureAction,
} from '../subdomains/pendingProposals/actions';
import { BOOTSTRAP_APP_SUCCESS } from '../../bootstrap/actions';
import { AUTH_LOG_IN_SUCCESS } from '../../auth/actions';
import { PROPOSALS_NEW_SUCCESS, PROPOSAL_COMPLETE_SUCCESS } from '../../proposals/actions';
import { getBearerTokenFromLocalStorage } from 'services/tokenLocalStorage';
import { getCurrentUserType } from '../../auth/selectors';
import { selectedTaSelector } from '../../agents';

export function* getPendingProposalsCountSaga(action: any) {
  const userType = yield select(getCurrentUserType);
  if(![EUserType.TA, EUserType.SR, EUserType.ADMIN].includes(userType)) {
    return;
  }

  try {
    const token = getBearerTokenFromLocalStorage();

    if (!token.value || token.expired) {
      return;
    }

    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const { data }: AxiosResponse<IProposalsListResponse> = yield call(backendApi.getPendingProposalsInfo);

    yield put(getPendingProposalsInfoSuccessAction(data.meta.total, data.data[0]));
  } catch (e) {
    yield put(getPendingProposalsInfoFailureAction(e));
  }
}

export function* watchGetPendingProposalsInfo() {
  yield takeLatest(
    [
      GET_PENDING_PROPOSALS_INFO_REQUEST,
      BOOTSTRAP_APP_SUCCESS,
      AUTH_LOG_IN_SUCCESS,
      PROPOSALS_NEW_SUCCESS,
      PROPOSAL_COMPLETE_SUCCESS,
    ],
    getPendingProposalsCountSaga
  );
}
